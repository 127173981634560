export enum ImageTool {
    bgRemoval = 'bg-removal',
    declutter = 'declutter',
    declutterKeepBubbles = 'declutter-keep-bubbles',
    lineart = 'lineart',
    sketch = 'sketch',
    colorize = 'colorize',
    emotion = 'emotion',
    upscale = 'upscale',
}

export enum ImageGenerationType {
    text2Image = 'text2image',
    image2Image = 'image2image',
    enhanced = 'enhanced',
    inpaint = 'inpaint',
    upscale = 'upscale',
    vibeTransfer = 'vibe-transfer',
    palleteSwap = 'pallete-swap',
    palleteSwapDetailed = 'pallete-swap-detailed',
    formLock = 'form-lock',
    scribbler = 'scribbler',
    buildingControl = 'building-control',
    landscaper = 'landscaper',
}

export type ImageToolInput = {
    data: Buffer
    url: string
    height: number
    width: number
    id: string
    filename: string
}

export const toolVariationNames = {
    [ImageTool.bgRemoval]: ['masked', 'generated', 'blend'],
    [ImageTool.declutter]: [],
    [ImageTool.declutterKeepBubbles]: [],
    [ImageTool.lineart]: [],
    [ImageTool.sketch]: [],
    [ImageTool.colorize]: [],
    [ImageTool.emotion]: [],
    [ImageTool.upscale]: [],
}

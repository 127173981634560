/**
 * Fetch with timeout. Rejects if timeout is reached before fetch is resolved.
 * @param url - URL to fetch.
 * @param params - Fetch parameters.
 * @param timeout - Timeout in milliseconds.
 * @param errorMessage - Error message to use if timeout is reached.
 * @returns Promise that resolves to the fetch response.
 * @throws Error if timeout is reached before fetch is resolved or if fetch fails.
 */
export function fetchWithTimeout(
    url: string,
    params: RequestInit = {},
    timeout: number = 60000,
    errorMessage?: string,
    abortRequest = true,
    correlationId?: string
): Promise<Response> {
    const controller = new AbortController()
    return Promise.race([
        fetch(url, {
            ...params,
            signal: params.signal ?? controller.signal,
        }),
        new Promise<Response>((_, reject) => {
            const method = params.method ?? 'UKWN'
            setTimeout(() => {
                reject(
                    new Error(
                        errorMessage ??
                            `${
                                correlationId ? `[${correlationId}] ` : ''
                            }Timeout fetching ${url} with method ${method}`
                    )
                )
                if (abortRequest) {
                    controller.abort()
                }
            }, timeout)
        }),
    ])
}

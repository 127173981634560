const _Environment = process.env.NEXT_PUBLIC_ENVIRONMENT
export const Environment = _Environment || 'debug'
const _CommitHash = process.env.NEXT_PUBLIC_COMMITHASH
export const CommitHash = _CommitHash || 'unknown'

const _SentryDSN = process.env.NEXT_PUBLIC_SENTRY_DSN
export const SentryDSN = _SentryDSN || ''

const _BackendURL = process.env.NEXT_PUBLIC_BACKEND_URL
const _ImageBackendURL = process.env.NEXT_PUBLIC_IMAGE_BACKEND_URL
const _TextBackendURL = process.env.NEXT_PUBLIC_TEXT_BACKEND_URL
const _MockEnv = process.env.NEXT_PUBLIC_MOCK_ENV

export const MockEnv = _MockEnv === 'true'
const _RecaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_KEY
export const RecaptchaKey = _RecaptchaKey || ''
const _PaddleSandbox = process.env.NEXT_PUBLIC_PADDLE_SANDBOX
export const PaddleSandbox = _PaddleSandbox === 'true'
const _PaddleVendorID = process.env.NEXT_PUBLIC_PADDLE_VENDOR_ID
export const PaddleVendorID = Number.parseInt(_PaddleVendorID || '123')
const _PaddleCodexID = process.env.NEXT_PUBLIC_PADDLE_CODEX_ID
export const PaddleCodexID = Number.parseInt(_PaddleCodexID || '123')
const _PaddleOpusID = process.env.NEXT_PUBLIC_PADDLE_OPUS_ID
export const PaddleOpusID = Number.parseInt(_PaddleOpusID || '123')
const _PaddleScrollID = process.env.NEXT_PUBLIC_PADDLE_SCROLL_ID
export const PaddleScrollID = Number.parseInt(_PaddleScrollID || '123')
const _PaddleTabletID = process.env.NEXT_PUBLIC_PADDLE_TABLET_ID
export const PaddleTabletID = Number.parseInt(_PaddleTabletID || '123')

const _PaddleGitfKeyTabletID = process.env.NEXT_PUBLIC_PADDLE_GIFTKEY_TABLET_ID
export const PaddleGitfKeyTabletID = Number.parseInt(_PaddleGitfKeyTabletID || '26963')
const _PaddleGitfKeyScrollID = process.env.NEXT_PUBLIC_PADDLE_GIFTKEY_SCROLL_ID
export const PaddleGitfKeyScrollID = Number.parseInt(_PaddleGitfKeyScrollID || '27468')
const _PaddleGitfKeyOpusID = process.env.NEXT_PUBLIC_PADDLE_GIFTKEY_OPUS_ID
export const PaddleGitfKeyOpusID = Number.parseInt(_PaddleGitfKeyOpusID || '27469')
const _PaddleGitfKeyCodexID = process.env.NEXT_PUBLIC_PADDLE_GIFTKEY_CODEX_ID
export const PaddleGitfKeyCodexID = Number.parseInt(_PaddleGitfKeyCodexID || '27469')
const _PaddleStepBundle2000ID = process.env.NEXT_PUBLIC_PADDLE_STEP_BUNDLE_2000_ID
export const PaddleStepBundle2000ID = Number.parseInt(_PaddleStepBundle2000ID || '36187')
const _PaddleStepBundle5000ID = process.env.NEXT_PUBLIC_PADDLE_STEP_BUNDLE_5000_ID
export const PaddleStepBundle5000ID = Number.parseInt(_PaddleStepBundle5000ID || '36186')
const _PaddleStepBundle10000ID = process.env.NEXT_PUBLIC_PADDLE_STEP_BUNDLE_10000_ID
export const PaddleStepBundle10000ID = Number.parseInt(_PaddleStepBundle10000ID || '36185')

export const PurchasesDisabled = false
export const GiftKeyPurchasesDisabled = true

export const BackendUrl = {
    forceProd: false,
    get BackendUrl(): string {
        return this.forceProd ? 'https://api.novelai.net' : _BackendURL || 'http://example.com'
    },
    get ImageBackendUrl(): string {
        return this.forceProd ? 'https://image.novelai.net' : _ImageBackendURL || this.BackendUrl
    },
    get TinyExploreBackendUrl(): string {
        return 'https://contest.novelai.net'
    },
    get TextBackendUrl(): string {
        return this.forceProd ? 'https://text.novelai.net' : _TextBackendURL || this.BackendUrl
    },
    get Register(): string {
        return this.BackendUrl + '/user/register'
    },
    get Login(): string {
        return this.BackendUrl + '/user/login'
    },
    get UserData(): string {
        return this.BackendUrl + '/user/data'
    },
    get Subscriptions(): string {
        return this.BackendUrl + '/user/subscription'
    },
    get SubscriptionBind(): string {
        return this.BackendUrl + '/user/subscription/bind'
    },
    get SubscriptionsChange(): string {
        return this.BackendUrl + '/user/subscription/change'
    },
    get UserGiftKeys(): string {
        return this.BackendUrl + '/user/giftkeys'
    },
    get Priority(): string {
        return this.BackendUrl + '/user/priority'
    },
    get Keystore(): string {
        return this.BackendUrl + '/user/keystore'
    },
    get Objects(): string {
        return this.BackendUrl + '/user/objects'
    },
    get Stories(): string {
        return this.BackendUrl + '/user/objects/stories'
    },
    get StoryContent(): string {
        return this.BackendUrl + '/user/objects/storycontent'
    },
    get Presets(): string {
        return this.BackendUrl + '/user/objects/presets'
    },
    get AIModules(): string {
        return this.BackendUrl + '/user/objects/aimodules'
    },
    get ClientSettings(): string {
        return this.BackendUrl + '/user/clientsettings'
    },
    get Generate(): string {
        return this.BackendUrl + '/ai/generate'
    },
    get GenerateStream(): string {
        return this.BackendUrl + '/ai/generate-stream'
    },
    get TextGenerate(): string {
        return this.TextBackendUrl + '/ai/generate'
    },
    get TextGenerateStream(): string {
        return this.TextBackendUrl + '/ai/generate-stream'
    },
    get GenerateImage(): string {
        return this.ImageBackendUrl + '/ai/generate-image'
    },
    get GenerateImagePrice(): string {
        return this.BackendUrl + '/ai/generate-image/request-price'
    },
    get AnnotateImage(): string {
        return this.BackendUrl + '/ai/annotate-image'
    },
    get UpscaleImage(): string {
        return this.BackendUrl + '/ai/upscale'
    },
    get RecoveryInitiation(): string {
        return this.BackendUrl + '/user/recovery/request'
    },
    get RecoverySubmit(): string {
        return this.BackendUrl + '/user/recovery/recover'
    },
    get PrefixSubmit(): string {
        return this.BackendUrl + '/ai/module/train'
    },
    get Prefix(): string {
        return this.BackendUrl + '/ai/module'
    },
    get PrefixAll(): string {
        return this.BackendUrl + '/ai/module/all'
    },
    get PrefixDelete(): string {
        return this.BackendUrl + '/ai/module/delete'
    },
    get PurchaseSteps(): string {
        return this.BackendUrl + '/ai/module/buy-training-steps'
    },
    get ChangeAuth(): string {
        return this.BackendUrl + '/user/change-access-key'
    },
    get StoryShelves(): string {
        return this.BackendUrl + '/user/objects/shelf'
    },
    get VerifyEmail(): string {
        return this.BackendUrl + '/user/verify-email'
    },
    get Information(): string {
        return this.BackendUrl + '/user/information'
    },
    get RequestVerifyEmail(): string {
        return this.BackendUrl + '/user/request-email-verification'
    },
    get ResendVerifyEmail(): string {
        return this.BackendUrl + '/user/resend-email-verification'
    },
    get RequestDeleteAccount(): string {
        return this.BackendUrl + '/user/deletion/request'
    },
    get VerifyDeleteAccount(): string {
        return this.BackendUrl + '/user/deletion/delete'
    },
    get SubmitContest(): string {
        return this.BackendUrl + '/user/submission'
    },
    get VoteContest(): string {
        return this.BackendUrl + '/user/vote-submission'
    },
    get TTS(): string {
        return this.BackendUrl + '/ai/generate-voice'
    },
    get TagSearch(): string {
        return this.ImageBackendUrl + '/ai/generate-image/suggest-tags'
    },
    get CreatePersistentToken(): string {
        return this.BackendUrl + '/user/create-persistent-token'
    },
    get QR(): string {
        return this.ImageBackendUrl + '/qr'
    },
    get ChangeMailingListConsent(): string {
        return this.BackendUrl + '/user/change-mailing-list-consent'
    },
    get ImageTool(): string {
        return this.ImageBackendUrl + '/ai/augment-image'
    },
}

export const BreakpointMobile = 1250
export const MaxLoreSearchDistance = 50000
export const MaxTokens = 2048

export const mobileSize = '1250px'
export const smallMobileSize = '900px'
export const smallerMobileSize = '600px'

export const ConfettiEnabled = false

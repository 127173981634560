import { default as sodium } from 'libsodium-wrappers'
import { serialize } from 'serializr'
import { deflate, FlateError, decompress } from 'fflate'

import { StoryMetadata, StoryContent } from '../../story/storycontainer'
import { AIModule } from '../../story/storysettings'
import { deserialize } from '../../../util/serialization'
import { IKeyStore, IStoryDto, KeyStoreError, KeyStorePayload } from './keystore'

class KeyStoreData {
    keys: Record<string, Array<number>> = {}

    constructor(keys?: Record<string, Array<number>>) {
        if (keys) this.keys = keys
    }
    hasKey(key: string): boolean {
        return Object.prototype.hasOwnProperty.call(this.keys, key)
    }
    setKey(key: string, value: Uint8Array): void {
        this.keys[key] = [...value]
    }
    getKey(key: string): Uint8Array {
        return new Uint8Array(this.keys[key])
    }
    replaceKey(prev: string, next: string): void {
        this.keys[next] = this.keys[prev]
        delete this.keys[prev]
    }
    merge(other: KeyStoreData): void {
        for (const [key, value] of Object.entries(other.keys)) {
            this.setKey(key, new Uint8Array(value))
        }
    }
}

export class SodiumKeyStore implements IKeyStore {
    async create(userkey: string): Promise<IKeyStore> {
        await sodium.ready
        this.key = sodium.crypto_generichash(32, userkey)
        this.data = new KeyStoreData()
        return this
    }
    async load(encrypted: KeyStorePayload, userkey: string): Promise<IKeyStore> {
        if (!encrypted.sdata || !encrypted.nonce) {
            return this.create(userkey)
        }
        await sodium.ready

        const key = sodium.crypto_generichash(32, userkey)
        const decrypted = sodium.crypto_secretbox_open_easy(
            new Uint8Array(encrypted.sdata),
            new Uint8Array(encrypted.nonce),
            key
        )
        const data = JSON.parse(new TextDecoder().decode(decrypted))

        this.key = key
        this.data = new KeyStoreData()
        this.data.keys = data.keys

        return this
    }
    async changeKey(userkey: string): Promise<void> {
        this.key = sodium.crypto_generichash(32, userkey)
    }
    async store(): Promise<KeyStorePayload> {
        if (!this.key || !this.data) {
            throw new Error('uninitialized keystore')
        }
        await sodium.ready

        const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)
        const data = new TextEncoder().encode(JSON.stringify(this.data))
        const encrypted = sodium.crypto_secretbox_easy(data, nonce, this.key)
        return {
            version: 2,
            nonce: [...nonce],
            sdata: [...encrypted],
        }
    }
    async updateStoryId(prev: string, next: string): Promise<void> {
        this.data?.replaceKey(prev, next)
    }
    async merge(_otherKeyStore: IKeyStore): Promise<void> {
        if (!(_otherKeyStore instanceof SodiumKeyStore)) return
        const otherKeyStore = _otherKeyStore as SodiumKeyStore
        this.data.merge(otherKeyStore.data)
    }
    async encryptStoryMetadata(
        storyMetadata: StoryMetadata,
        keyStoreChanged: () => void = () => {
            /* nothing by default */
        }
    ): Promise<IStoryDto> {
        if (!this.key || !this.data) {
            throw new Error('uninitialized keystore')
        }
        await sodium.ready

        let hash: Uint8Array
        if (!this.data.hasKey(storyMetadata.id)) {
            hash = sodium.crypto_secretbox_keygen()
            this.data.setKey(storyMetadata.id, hash)
            keyStoreChanged()
        } else {
            hash = this.data.getKey(storyMetadata.id)
        }

        const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)
        const data = new TextEncoder().encode(storyMetadata.serialize())
        const encrypted = sodium.crypto_secretbox_easy(data, nonce, hash)

        const combined = Buffer.from(new Uint8Array([...nonce, ...encrypted])).toString('base64')
        const dto: IStoryDto = {
            id: storyMetadata.id,
            data: combined,
            lastUpdatedAt: storyMetadata.lastUpdatedAt.valueOf(),
            meta: storyMetadata.id,
            type: 'storyMetadata',
            changeIndex: storyMetadata.changeIndex,
        }
        return dto
    }
    async encryptStoryContent(
        storyContent: StoryContent,
        storyMetadata: StoryMetadata,
        keyStoreChanged: () => void = () => {
            /* nothing by default */
        }
    ): Promise<IStoryDto> {
        if (!this.key || !this.data) {
            throw new Error('uninitialized keystore')
        }
        await sodium.ready

        let hash: Uint8Array
        if (!this.data.hasKey(storyMetadata.id)) {
            hash = sodium.crypto_secretbox_keygen()
            this.data.setKey(storyMetadata.id, hash)
            keyStoreChanged()
        } else {
            hash = this.data.getKey(storyMetadata.id)
        }

        const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)

        const data = new TextEncoder().encode(storyContent.serialize())
        const compressed = await new Promise((resolve: (compressed: Uint8Array) => void, reject) => {
            deflate(data, { level: 9 }, (err: FlateError | null, compressed: Uint8Array) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(compressed)
                }
            })
        })

        const encrypted = sodium.crypto_secretbox_easy(compressed, nonce, hash)

        const combined = Buffer.from(
            new Uint8Array([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, ...nonce, ...encrypted])
        ).toString('base64')
        const dto: IStoryDto = {
            id: storyMetadata.id,
            data: combined,
            lastUpdatedAt: storyMetadata.lastUpdatedAt.valueOf(),
            meta: storyMetadata.id,
            type: 'storyContent',
            changeIndex: storyContent.changeIndex,
        }
        return dto
    }
    async decryptStoryMetadata(dto: IStoryDto): Promise<StoryMetadata | null> {
        if (!this.key || !this.data) {
            throw new Error('uninitialized keystore')
        }
        await sodium.ready

        if (!this.data.hasKey(dto.meta)) {
            throw new KeyStoreError('no key for story metadata', dto.meta)
        }
        const hash = this.data.getKey(dto.meta)

        const data = new Uint8Array(Buffer.from(dto.data, 'base64'))
        const nonce = new Uint8Array(data.slice(0, sodium.crypto_secretbox_NONCEBYTES))
        const encrypted = new Uint8Array(data.slice(sodium.crypto_secretbox_NONCEBYTES))

        const decrypted = sodium.crypto_secretbox_open_easy(encrypted, nonce, hash)
        const story = StoryMetadata.deserialize(new TextDecoder().decode(decrypted))

        story.changeIndex = dto.changeIndex

        return story
    }
    async decryptStoryContent(dto: IStoryDto, raw?: boolean): Promise<StoryContent | string | null> {
        if (!this.key || !this.data) {
            throw new Error('uninitialized keystore')
        }
        await sodium.ready

        if (!this.data.hasKey(dto.meta)) {
            throw new KeyStoreError('no key for story content', dto.meta)
        }
        const hash = this.data.getKey(dto.meta)

        let data = new Uint8Array(Buffer.from(dto.data, 'base64'))

        const preamble = data.slice(0, 16)
        if (
            JSON.stringify([...preamble]) === JSON.stringify([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1])
        ) {
            data = data.slice(16)
        }

        const nonce = new Uint8Array(data.slice(0, sodium.crypto_secretbox_NONCEBYTES))
        const encrypted = new Uint8Array(data.slice(sodium.crypto_secretbox_NONCEBYTES))

        let decrypted = sodium.crypto_secretbox_open_easy(encrypted, nonce, hash)

        if (
            JSON.stringify([...preamble]) === JSON.stringify([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1])
        ) {
            decrypted = await new Promise((resolve: (decompressed: Uint8Array) => void, reject) => {
                decompress(decrypted, (err: FlateError | null, decompressed: Uint8Array) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(decompressed)
                    }
                })
            })
        }

        const decoded = new TextDecoder().decode(decrypted)

        if (raw) {
            return decoded
        }

        const story = StoryContent.deserialize(decoded)
        return story
    }

    async encryptModule(
        aimodule: AIModule,
        keyStoreChanged: () => void = () => {
            /* nothing by default */
        }
    ): Promise<IStoryDto> {
        if (!this.key || !this.data) {
            throw new Error('uninitialized keystore')
        }
        await sodium.ready

        const identifierId: string = aimodule.id.split(':')[1]

        let hash: Uint8Array
        if (!this.data.hasKey(identifierId)) {
            hash = sodium.crypto_secretbox_keygen()
            this.data.setKey(identifierId, hash)
            keyStoreChanged()
        } else {
            hash = this.data.getKey(identifierId)
        }

        const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES)
        const data = new TextEncoder().encode(JSON.stringify(serialize(AIModule, aimodule)))
        const encrypted = sodium.crypto_secretbox_easy(data, nonce, hash)

        const combined = Buffer.from(new Uint8Array([...nonce, ...encrypted])).toString('base64')
        const dto: IStoryDto = {
            id: aimodule.id,
            data: combined,
            lastUpdatedAt: Date.now(),
            meta: identifierId,
            type: 'aiModule',
            changeIndex: 0,
        }
        return dto
    }

    async decryptModule(dto: IStoryDto): Promise<AIModule> {
        if (!this.key || !this.data) {
            throw new Error('uninitialized keystore')
        }
        await sodium.ready

        if (!this.data.hasKey(dto.meta)) {
            throw new KeyStoreError('no key for module', dto.meta)
        }
        const hash = this.data.getKey(dto.meta)

        const data = new Uint8Array(Buffer.from(dto.data, 'base64'))
        const nonce = new Uint8Array(data.slice(0, sodium.crypto_secretbox_NONCEBYTES))
        const encrypted = new Uint8Array(data.slice(sodium.crypto_secretbox_NONCEBYTES))

        const decrypted = sodium.crypto_secretbox_open_easy(encrypted, nonce, hash)
        const aimodule = deserialize(AIModule, JSON.parse(new TextDecoder().decode(decrypted)))
        return aimodule
    }

    data: KeyStoreData = new KeyStoreData()
    key: Uint8Array = new Uint8Array()
}

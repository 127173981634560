export const clipUnitrim = {
    '94': -1,
    '95': -1,
    '96': -1,
    '97': -1,
    '98': -1,
    '99': -1,
    '100': -1,
    '101': -1,
    '102': -1,
    '103': -1,
    '104': -1,
    '105': -1,
    '106': -1,
    '107': -1,
    '108': -1,
    '109': -1,
    '110': -1,
    '111': -1,
    '112': -1,
    '113': -1,
    '114': -1,
    '115': -1,
    '116': -1,
    '117': -1,
    '118': -1,
    '119': -1,
    '120': -1,
    '121': -1,
    '122': -1,
    '123': -1,
    '124': 1,
    '125': 1,
    '126': 1,
    '127': 1,
    '128': 1,
    '129': 1,
    '130': 1,
    '131': 1,
    '132': 1,
    '133': 1,
    '134': 1,
    '135': 1,
    '136': 1,
    '137': 1,
    '138': 1,
    '139': 1,
    '140': 1,
    '141': 1,
    '142': 1,
    '143': 1,
    '144': 1,
    '145': 1,
    '146': 1,
    '147': 1,
    '148': 1,
    '149': 1,
    '150': 1,
    '151': 1,
    '152': 1,
    '153': 1,
    '154': 1,
    '155': 1,
    '156': 2,
    '157': 2,
    '158': 2,
    '159': 2,
    '160': 2,
    '161': 2,
    '162': 2,
    '163': 2,
    '164': 2,
    '165': 2,
    '166': 2,
    '167': 2,
    '168': 2,
    '169': 2,
    '170': 2,
    '171': 2,
    '172': 3,
    '173': 3,
    '174': 3,
    '175': 3,
    '176': 3,
    '177': 3,
    '178': 3,
    '179': 3,
    '222': -1,
    '223': -1,
    '224': -1,
    '225': -1,
    '226': -1,
    '227': -1,
    '228': -1,
    '229': -1,
    '230': -1,
    '231': -1,
    '232': -1,
    '233': -1,
    '234': -1,
    '235': -1,
    '236': -1,
    '237': -1,
    '238': -1,
    '239': -1,
    '240': -1,
    '241': -1,
    '242': -1,
    '243': -1,
    '244': -1,
    '245': -1,
    '246': -1,
    '247': -1,
    '248': -1,
    '249': -1,
    '250': -1,
    '251': -1,
    '252': -1,
    '253': -1,
    '254': -1,
    '255': -1,
    '350': -1,
    '351': -1,
    '352': -1,
    '353': -1,
    '354': -1,
    '355': -1,
    '356': -1,
    '357': -1,
    '358': -1,
    '359': -1,
    '360': -1,
    '361': -1,
    '362': -1,
    '363': -1,
    '364': -1,
    '365': -1,
    '366': -1,
    '367': -1,
    '368': -1,
    '369': -1,
    '370': -1,
    '371': -1,
    '372': -1,
    '373': -1,
    '374': -1,
    '375': -1,
    '376': -1,
    '377': -1,
    '378': -1,
    '379': -1,
    '478': -1,
    '479': -1,
    '480': -1,
    '481': -1,
    '482': -1,
    '483': -1,
    '484': -1,
    '485': -1,
    '486': -1,
    '487': -1,
    '488': -1,
    '489': -1,
    '490': -1,
    '491': -1,
    '492': -1,
    '493': -1,
    '494': -1,
    '495': -1,
    '496': -1,
    '497': -1,
    '498': -1,
    '499': -1,
    '500': -1,
    '501': -1,
    '502': -1,
    '503': -1,
    '504': -1,
    '505': -1,
    '506': -1,
    '507': -1,
    '508': -1,
    '509': -1,
    '510': -1,
    '511': -1,
    '558': 2,
    '668': 1,
    '728': 1,
    '837': 1,
    '842': 1,
    '964': 1,
    '1045': 1,
    '1109': 1,
    '1165': 1,
    '1173': 1,
    '1428': 1,
    '1478': 1,
    '1497': 1,
    '1508': 1,
    '1548': 1,
    '1576': 1,
    '1667': 1,
    '1741': 1,
    '1777': 1,
    '1793': 1,
    '2011': 1,
    '2074': 1,
    '2234': 1,
    '2429': 1,
    '2730': 1,
    '2805': 1,
    '3124': 1,
    '3257': 1,
    '3909': 1,
    '4092': 1,
    '4131': 1,
    '4156': 1,
    '4168': 1,
    '4174': -1,
    '4484': 1,
    '4813': 1,
    '5177': 1,
    '5227': 1,
    '5511': -1,
    '5527': 1,
    '5689': 1,
    '5947': 1,
    '6022': 1,
    '6040': 1,
    '6059': 1,
    '6269': 1,
    '6874': 2,
    '7045': 1,
    '7410': 1,
    '7443': -1,
    '7692': 1,
    '7930': 1,
    '7956': 1,
    '7960': 1,
    '8276': 1,
    '8347': 1,
    '8354': 1,
    '8453': 1,
    '8604': 1,
    '8792': 1,
    '8989': 1,
    '9323': 1,
    '9536': 1,
    '9668': -1,
    '9992': 1,
    '11259': 1,
    '11306': 1,
    '11322': 1,
    '11473': 1,
    '12100': 1,
    '12125': 1,
    '12330': 1,
    '12715': -1,
    '13094': 1,
    '13189': 1,
    '13252': 1,
    '13389': 1,
    '13608': 1,
    '13917': 1,
    '14076': 1,
    '14649': 1,
    '15012': 1,
    '15016': 1,
    '15074': 1,
    '15113': -1,
    '15115': 1,
    '15197': 1,
    '15418': 1,
    '15741': 1,
    '15810': 1,
    '15889': 1,
    '15962': 1,
    '16071': 1,
    '16279': 1,
    '16929': 1,
    '17003': 2,
    '17027': 1,
    '17330': 1,
    '17562': 1,
    '17747': 1,
    '17788': 1,
    '17848': 1,
    '18446': -1,
    '18541': 1,
    '18584': 1,
    '18865': 1,
    '19036': 1,
    '19277': 1,
    '19528': 1,
    '19693': 2,
    '19759': 1,
    '20211': 1,
    '20515': 2,
    '20537': 1,
    '20580': 1,
    '20745': 1,
    '20849': 1,
    '20909': 1,
    '20978': 1,
    '21078': 1,
    '21122': 1,
    '21144': 1,
    '21391': 1,
    '21787': -1,
    '22010': 1,
    '22195': -1,
    '22265': 1,
    '22543': 1,
    '22684': 1,
    '22924': 1,
    '22990': 1,
    '23121': 1,
    '23170': 1,
    '23215': 1,
    '23550': 1,
    '23916': 1,
    '23924': 1,
    '24193': 1,
    '24395': -1,
    '24531': 1,
    '24621': 1,
    '24706': -1,
    '24829': 1,
    '25081': 1,
    '25087': 1,
    '25170': 1,
    '25887': 1,
    '25914': 1,
    '26068': 1,
    '26109': 1,
    '26339': 1,
    '26369': 1,
    '26609': 1,
    '27047': 1,
    '27228': 1,
    '27252': 1,
    '27392': 1,
    '27400': 1,
    '27605': -1,
    '27667': 1,
    '27850': 1,
    '27899': -1,
    '27972': 1,
    '28024': 1,
    '28877': -1,
    '29032': 1,
    '29036': 1,
    '29254': 2,
    '29290': 1,
    '29415': 1,
    '29450': 1,
    '29503': 1,
    '29658': 1,
    '29881': 1,
    '30294': 1,
    '30587': 1,
    '30766': 1,
    '30832': 1,
    '30850': 1,
    '31061': 1,
    '31090': 1,
    '31153': 1,
    '31333': -1,
    '31474': 1,
    '31625': 1,
    '31718': 1,
    '31736': 2,
    '31871': 1,
    '31882': 1,
    '31972': 1,
    '32086': 1,
    '32421': 1,
    '32495': 1,
    '32757': 1,
    '32791': 1,
    '33196': 1,
    '33325': 1,
    '33371': 1,
    '33440': 1,
    '33950': -1,
    '34002': 1,
    '34314': 1,
    '34350': 1,
    '34462': 2,
    '34517': 1,
    '34720': 1,
    '34777': -1,
    '35368': 1,
    '35555': 1,
    '35641': 1,
    '35751': 1,
    '36216': 1,
    '36218': 1,
    '36250': 1,
    '36288': 1,
    '36294': 1,
    '36311': 1,
    '36350': 1,
    '37342': 1,
    '37463': -1,
    '37590': 2,
    '37743': 1,
    '37746': 1,
    '37810': 1,
    '37978': 2,
    '38283': 1,
    '38780': 1,
    '39027': 1,
    '39219': 1,
    '39405': 1,
    '39628': 1,
    '39810': 2,
    '39856': 1,
    '40500': 1,
    '40742': 1,
    '40941': 1,
    '41006': 1,
    '41020': -1,
    '41673': 1,
    '41854': 1,
    '42021': 1,
    '42476': 1,
    '42680': 1,
    '42748': 1,
    '42763': 1,
    '42891': 1,
    '43364': -2,
    '43387': 1,
    '43431': 1,
    '43491': 1,
    '43565': 1,
    '43794': 1,
    '43991': -2,
    '44317': 1,
    '44693': 1,
    '44853': -1,
    '44854': 1,
    '45088': 1,
    '45105': 1,
    '45543': 1,
    '45737': 1,
    '45930': 1,
    '45932': 1,
    '45969': 1,
    '46023': -1,
    '46096': 1,
    '46186': 1,
    '47021': -1,
    '47110': 1,
    '47310': 1,
    '47466': 1,
    '47505': 1,
    '47615': -2,
    '48132': 1,
    '48267': 1,
    '48347': 1,
}

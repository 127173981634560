import { subscriptionIsActive } from '../../util/subscription'
import { colorModels, DefaultModel, normalizeModel, TextGenerationModel } from '../request/model'
import { User } from '../user/user'

export const MODEL_KRAKE_V2 = 502
export const MODEL_KRAKE_V1 = 501
export const MODEL_EUTERPE_V2 = 402
export const MODEL_EUTERPE_V0 = 401
export const MODEL_GENJIPYTHON6B = 301
export const MODEL_GENJIJP6B_V2 = 202
export const MODEL_GENJIJP6B = 201
export const MODEL_SIGURD_V4 = 4
export const MODEL_SIGURD_V3 = 3
export const MODEL_SIGURD = 2
export const MODEL_CALLIOPE = 1

export function modelSupportsUserModules(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.j6bv3) return true
    if (model === TextGenerationModel.j6bv4) return true
    if (model === TextGenerationModel.euterpev2) return true
    return false
}

export function modelSupportsModules(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.j6b) return true
    if (model === TextGenerationModel.j6bv3) return true
    if (model === TextGenerationModel.j6bv4) return true
    if (model === TextGenerationModel.euterpev0) return true
    if (model === TextGenerationModel.euterpev2) return true
    if (model === TextGenerationModel.krakev1) return true
    if (model === TextGenerationModel.krakev2) return true
    if (model === TextGenerationModel.clio) return true
    if (model === TextGenerationModel.kayra) return true
    if (colorModels.has(model)) return true
    return false
}

export function modelSupportsPhraseBias(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.neo2b) return false
    if (model === TextGenerationModel.infill) return false
    return true
}

export function modelSupportsRepPenWhitelist(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.clio) return true
    if (model === TextGenerationModel.kayra) return true
    if (model === TextGenerationModel.erato) return true
    if (colorModels.has(model)) return true
    return false
}

export function modelSupportsEosToken(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.clio) return true
    if (model === TextGenerationModel.kayra) return true
    if (model === TextGenerationModel.erato) return true
    if (colorModels.has(model)) return true
    return false
}

export function modelSupportsCfg(model: TextGenerationModel = DefaultModel): boolean {
    // CFG is no longer supported :(
    return false
}

export function modelSupportsPhraseRepPen(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.clio) return true
    if (model === TextGenerationModel.kayra) return true
    if (model === TextGenerationModel.erato) return true
    if (colorModels.has(model)) return true
    return false
}

export function modelSupportsInstruct(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.clio) return true
    if (model === TextGenerationModel.kayra) return true
    if (model === TextGenerationModel.erato) return true
    if (colorModels.has(model)) return true
    return false
}

export function modelSupportsOpenings(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.kayra) return true
    return false
}

export function modelSupportsPediaModule(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.kayra) return true
    return false
}

export function modelSupportsTopG(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.clio) return true
    if (model === TextGenerationModel.kayra) return true
    if (colorModels.has(model)) return true
    return false
}

export function modelSupportsMirostat(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.clio) return true
    if (model === TextGenerationModel.kayra) return true
    if (model === TextGenerationModel.erato) return true
    if (colorModels.has(model)) return true
    return false
}

export function modelSupportsMath1(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.kayra) return true
    if (model === TextGenerationModel.erato) return true
    if (colorModels.has(model)) return true
    return false
}

export function modelSupportMinP(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.kayra) return true
    if (model === TextGenerationModel.erato) return true
    if (colorModels.has(model)) return true
    return false
}

export function modelSupportsCancel(model: TextGenerationModel = DefaultModel): boolean {
    if (model === TextGenerationModel.kayra) return true
    if (model === TextGenerationModel.erato) return true
    if (colorModels.has(model)) return true
    return false
}

export function modelHasScaledRepetitionPenalty(model: TextGenerationModel = DefaultModel): boolean {
    const scaledRepPenModels = new Set([
        TextGenerationModel.j6b,
        TextGenerationModel.j6bv3,
        TextGenerationModel.j6bv4,
        TextGenerationModel.euterpev0,
        TextGenerationModel.euterpev2,
        TextGenerationModel.genjipython6b,
        TextGenerationModel.genjijp6b,
        TextGenerationModel.genjijp6bv2,
    ])

    if (scaledRepPenModels.has(model)) return true
    return false
}

export function modelRepPenMax(model: TextGenerationModel = DefaultModel): number {
    if (normalizeModel(model) === normalizeModel(TextGenerationModel.krakev2)) return 1.08
    return 8
}

export function modelRepPenStepSize(model: TextGenerationModel = DefaultModel): string {
    if (normalizeModel(model) === normalizeModel(TextGenerationModel.krakev2)) return '0.0001'
    return '0.025'
}

export function modelBiasMax(model: TextGenerationModel = DefaultModel): number {
    if (normalizeModel(model) === normalizeModel(TextGenerationModel.krakev2)) return 0.3
    return 2
}

export function modelBiasStrong(model: TextGenerationModel = DefaultModel): number {
    if (normalizeModel(model) === normalizeModel(TextGenerationModel.krakev2)) return 0.1
    return 0.6
}

export function modelBiasStepSize(model: TextGenerationModel = DefaultModel): string {
    if (normalizeModel(model) === normalizeModel(TextGenerationModel.krakev2)) return '0.0001'
    return '0.01'
}

export function modelBiasRoundDigits(model: TextGenerationModel = DefaultModel): number {
    if (normalizeModel(model) === normalizeModel(TextGenerationModel.krakev2)) return 3
    return 2
}

const getModelTokenLimits = (
    model: TextGenerationModel
): { tablet: number; scroll: number; opus: number } => {
    switch (model) {
        case TextGenerationModel.j6b:
        case TextGenerationModel.j6bv3:
        case TextGenerationModel.j6bv4: {
            return { tablet: 1024, scroll: 2048, opus: 2048 }
        }
        case TextGenerationModel.euterpev0:
        case TextGenerationModel.euterpev2: {
            return { tablet: 1024, scroll: 2048, opus: 2048 }
        }
        case TextGenerationModel.krakev1:
        case TextGenerationModel.krakev2: {
            return { tablet: 1024, scroll: 2048, opus: 2048 }
        }
        case TextGenerationModel.neo2b: {
            return { tablet: 1024, scroll: 2048, opus: 2048 }
        }
        case TextGenerationModel.genjipython6b: {
            return { tablet: 1024, scroll: 2048, opus: 2048 }
        }
        case TextGenerationModel.genjijp6b:
        case TextGenerationModel.genjijp6bv2: {
            return { tablet: 1024, scroll: 2048, opus: 2048 }
        }
        case TextGenerationModel.infill: {
            return { tablet: 1024, scroll: 2048, opus: 2048 }
        }
        case TextGenerationModel.cassandra: {
            return { tablet: 1024, scroll: 2048, opus: 4096 }
        }
        case TextGenerationModel.commentBot: {
            return { tablet: 1024, scroll: 2048, opus: 2048 }
        }
        case TextGenerationModel.clio: {
            return { tablet: 8192, scroll: 8192, opus: 8192 }
        }
        case TextGenerationModel.kayra: {
            return { tablet: 4096, scroll: 8192, opus: 8192 }
        }
        case TextGenerationModel.blue: {
            return { tablet: 1024, scroll: 2048, opus: 8192 }
        }
        case TextGenerationModel.red: {
            return { tablet: 1024, scroll: 2048, opus: 8192 }
        }
        case TextGenerationModel.green: {
            return { tablet: 1024, scroll: 2048, opus: 8192 }
        }
        case TextGenerationModel.purple: {
            return { tablet: 1024, scroll: 2048, opus: 8192 }
        }
        case TextGenerationModel.pink: {
            return { tablet: 1024, scroll: 2048, opus: 8192 }
        }
        case TextGenerationModel.yellow: {
            return { tablet: 1024, scroll: 2048, opus: 8192 }
        }
        case TextGenerationModel.white: {
            return { tablet: 1024, scroll: 2048, opus: 8192 }
        }
        case TextGenerationModel.black: {
            return { tablet: 1024, scroll: 2048, opus: 8192 }
        }
        case TextGenerationModel.erato: {
            return { tablet: 0, scroll: 0, opus: 8192 }
        }
        default: {
            // This should never happen, but just in case
            return { tablet: 1024, scroll: 1024, opus: 1024 }
        }
    }
}

export function modelMaxContextSize(model: TextGenerationModel = DefaultModel, user?: User): number {
    const _default = 1024

    // If no user return the maximum (aka opus)
    if (!user) return getModelTokenLimits(model).opus

    const trial =
        user.noAccount || (user.information.trialActivated && !subscriptionIsActive(user.subscription))
    // If user is on trial, return the scroll limit
    if (trial) return getModelTokenLimits(model).scroll

    // If user is on a subscription, return the limit for their tier
    if (subscriptionIsActive(user.subscription)) {
        const tier = user.subscription.tier
        if (tier === 1) return getModelTokenLimits(model).tablet
        if (tier === 2) return getModelTokenLimits(model).scroll
        if (tier === 3) return getModelTokenLimits(model).opus
    }

    return _default
}

export function modelMaxLength(model: TextGenerationModel = DefaultModel, user?: User): number {
    // For Erato and Kayra 250 opus 150 others
    if (model === TextGenerationModel.erato || model === TextGenerationModel.kayra) {
        const tier = user?.subscription?.tier || 1
        return tier === 3 ? 250 : 150
    }

    // Otherwise 150/100
    const tier = user?.subscription?.tier || 1
    return tier === 3 ? 150 : 100
}

export function modelDefaultLength(model: TextGenerationModel = DefaultModel, user?: User): number {
    // For Erato and Kayra 75 otherwise 40
    if (model === TextGenerationModel.erato || model === TextGenerationModel.kayra) {
        return 75
    }
    return 40
}

import { normalizeModel, TextGenerationModel } from '../data/request/model'

export enum EncoderType {
    GPT2,
    PileNAI,
    Genji,
    Pile,
    NAIInline,
    CLIP,
    Nerdstash,
    NerdstashV2,
    Llama3,
}

export function getModelEncoderType(model: TextGenerationModel): EncoderType {
    switch (normalizeModel(model)) {
        case TextGenerationModel.genjijp6bv2: {
            return EncoderType.Genji
        }
        case TextGenerationModel.cassandra:
        case TextGenerationModel.krakev2: {
            return EncoderType.Pile
        }
        case TextGenerationModel.infill: {
            return EncoderType.NAIInline
        }
        case TextGenerationModel.kayra:
        case TextGenerationModel.blue:
        case TextGenerationModel.pink:
        case TextGenerationModel.yellow:
        case TextGenerationModel.red:
        case TextGenerationModel.green:
        case TextGenerationModel.black: {
            return EncoderType.NerdstashV2
        }
        case TextGenerationModel.clio: {
            return EncoderType.Nerdstash
        }
        case TextGenerationModel.purple:
        case TextGenerationModel.white:
        case TextGenerationModel.erato: {
            return EncoderType.Llama3
        }
        default: {
            return EncoderType.GPT2
        }
    }
}

import { createGlobalStyle } from 'styled-components'
import { transparentize, probabilityColor } from '../util/colour'
import { Localization } from '../hooks/useLocalization'

import RunningMan from '../assets/images/running.svg'
import SpeechBubble from '../assets/images/speech-bubble.svg'
import { Dark } from './themes/dark'

export const CustomGlobalStyle = createGlobalStyle<{ global: string }>`
    ${(props) => props.global ?? ''}
`

export const GlobalStyle = createGlobalStyle<{
    theme: typeof Dark
    fontSize: number
    outputFontSize: number
    paragraphIndent: number
    lineSpacing: number
    paragraphSpacing: number
    editorHighlighting: boolean
    buttonScale: number
    focusBorder: boolean
    editorBackground: boolean
    localization: Localization
}>`
    html {
        font-size: ${(props) => `${props.fontSize.toFixed(1)}px`};
        line-height: 1.5;
    }
    body {
        font-family: ${(props) => `${props.theme.fonts.default}`};
        color: ${(props) => props.theme.colors.textMain};
        --loader-color: ${(props) => props.theme.colors.textHeadings};
    }

    * {
        color: inherit;
    }

    input::placeholder,
    textarea::placeholder {
        opacity: 1;
        color: ${(props) => props.theme.colors.textPlaceholder};
    }

    code {
        font-family: ${(props) => props.theme.fonts.code};
        font-size: 0.9rem;
    }

    div {
        word-break: break-word;
    }

    a {
        color: ${(props) => props.theme.colors.textMain};
        text-decoration: none;
        font-weight: 600;

        &:hover {
            color: ${(props) => props.theme.colors.textHeadings};
            text-decoration: none;
        }
    }

    strong {
        font-weight: 600;
    }

    input,
    textarea {
        background: ${(props) => props.theme.colors.bg0};
        border: 0;
        color: ${(props) => props.theme.colors.textMain};
        font-family: ${(props) => props.theme.fonts.field};
        font-size: 0.875rem;
        padding: 10px 0 10px 10px;
        resize: none;
        width: 100%;
        touch-action: pan-y;
        box-sizing: border-box;
    }
    button{
        font: inherit;
        font-weight: 600;
    }
    h1, h2, h3, h4, h5, h6 {
        margin: 0 0 0.5rem;
    }
    p {
        margin: 0 0 1rem;
    }


    input, [role="button"], textarea, button {
        &:focus{
            outline-color: ${(props) => props.theme.colors.textMain};
        }

        &:active{
            outline-width: 0 !important;
        }

        ${(props) =>
            props.focusBorder
                ? `
                `
                : `
                &:focus{
                    outline-width: 0;
                }

                `}

    }

    label {
        font-size: 0.875rem;
    }

    button:disabled {
        color: ${(props) => props.theme.colors.textDisabled};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: ${(props) => props.theme.colors.textHeadings};
        font-family: ${(props) => props.theme.fonts.headings};
        font-weight: 600;
        letter-spacing: 0.5px;
    }

    ::-webkit-scrollbar-track {
        background: none;
    }

    ::-webkit-scrollbar {
        height: 14px;
        width: 14px;
    }

    ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: ${(props) => props.theme.colors.bg3};
        border: 5px solid transparent;
        border-radius: 15px;
    }

    ::-webkit-scrollbar-corner{
        display:none;
    }

    button[disabled] { pointer-events: none; }

    ::-webkit-scrollbar-button {
        display: none;
        height: 0;
        width: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
   

    @-moz-document url-prefix(){
        * {
            scrollbar-color: ${(props) => props.theme.colors.bg3} transparent;
        }
    } }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    .multi-line {
        color: black;
    }
    .select {
        box-shadow: 0 0 1px 0 ${(props) => transparentize(0.4, props.theme.colors.textMain)};;
    }

    .ProseMirror {
        box-sizing: border-box;
        touch-action: pan-y;
        background: transparent;
        border: 0;
        color: rgba(230, 230, 230, 0.863);
        padding: 10px 10px 10px 10px;
        resize: none;
        width: 100%;
        min-height: 100%;
        // fix for stuttering scrollbar with LanguageTool / Grammarly
        height: min-content;
        flex: 1;
        word-break: break-word;
        overflow: visible;
        position: relative;
        text-rendering: optimizeSpeed;

        font-family: ${(props) => props.theme.fonts.field};
        font-size: ${(props) => `${props.outputFontSize.toFixed(1)}px`};
        line-height: ${(props) => `${props.lineSpacing.toFixed(2)}em`};;
        color: ${(props) => props.theme.colors.textMain};
        transition: background-color ${(props) => props.theme.transitions.interactive};

        p {
            margin: 0;
        }
        & > p {
            color: ${(props) =>
                props.editorHighlighting ? props.theme.colors.textPrompt : props.theme.colors.textMain};
        }

        p.empty-node::before {
            float: left;
            color: #aaa;
            pointer-events: none;
            height: 0;
            font-style: italic;
        }
        p.empty-node:first-child::before {
            content: '${(props) => props.localization.stories.storyPlaceholder}';
            color: ${(props) => props.theme.colors.textPlaceholder};
            font-style: normal;
        }
        p:not(.empty-node) {
            text-indent: ${(props) => props.paragraphIndent}px;
            padding-bottom:  ${(props) => props.paragraphSpacing}em;
        }

        .highlight {
            border-radius: 3px;
            background-color: ${(props) => props.theme.colors.textHighlight};
        }
        .link {
            cursor: pointer;
            border-bottom: 2px solid ${(props) => transparentize(0.5, props.theme.colors.textHeadings)};
        }
        .lorekey {
            font-weight: 700;
            border-radius: 3px;
            :hover {
                background: ${(props) => props.theme.colors.textHighlight};
            }
        }

        .aiText {
            color: ${(props) =>
                props.editorHighlighting ? props.theme.colors.textAI : props.theme.colors.textMain};
        }
        .userText {
            color: ${(props) =>
                props.editorHighlighting ? props.theme.colors.textUser : props.theme.colors.textMain};
        }
        .editText {
            color: ${(props) =>
                props.editorHighlighting ? props.theme.colors.textEdit : props.theme.colors.textMain};
        }
        .promptText {
            color: ${(props) =>
                props.editorHighlighting ? props.theme.colors.textPrompt : props.theme.colors.textMain};
        }

        .bold {
            font-weight: 700;
        }
        .italic {
            font-style: italic;
        }
        .underline {
            text-decoration: underline;
        }
        .strikethrough {
            text-decoration: line-through;
        }

        .logprobs-decoration {
            position: relative;
            padding: 0;
            margin: 0;
            ::before {
                content: "";
                position: absolute;
                top:0;
                left:0;
                height: 100%;
                width: 100%;
                opacity: 0.08;
                pointer-events: none;
                z-index: -1;
            }
            :hover {
                ::before {
                    opacity: 0.24;
                }
            }
        }
        ${(props) => {
            const css = Array.from({ length: 31 })
                .map((_, i) => {
                    return `
                .logprobs-color-${i} {
                    ::before {
                        background-color: ${probabilityColor(i / 31, props.theme.colors)};
                    }
                }
                `
                })
                .join('')
            return css
        }}

        .dialogue {
            font-style: italic;
            opacity: 0.88;
        }

    }
    .ProseMirror-focused {
        outline: none !important;
    }
    
    .conversation-main {
        ${(props) =>
            props.editorBackground
                ? `
                background: ${props.theme.colors.bg2};
                `
                : `background: transparent;`}
    }

    .conversation {
        .tts-controls {
        ${(props) =>
            props.editorBackground
                ? `
                background: ${props.theme.colors.bg2};
                `
                : `background: transparent;`}

        }
        .editor2 .ProseMirror {
            >p {
                .find-result {
                    background: ${(props) => props.theme.colors.textHighlight};
                    border-radius: 3px;
                }
                .selected-find-result {
                    background: ${(props) => transparentize(0.6, props.theme.colors.textHeadings)};
                }

                &[source="10"] {
                    padding: 5px 15px 5px 5px;
                    margin: 5px;
                    border-left: 2px solid ${(props) => props.theme.colors.textHeadings};
                    border-radius: 3px;
                    background: ${(props) => transparentize(0.3, props.theme.colors.bg0)};
                    width: auto;
                    position: relative;
                    max-width: max-content;
                    ::before {
                        // Slightly larger box placed behind the instruction box
                        // Gradient from left to right
                        content: '';
                        pointer-events: none;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        mask-repeat: no-repeat;
                        mask-size: contain;
                        mask-position: center;
                        background: linear-gradient(to right, ${(props) =>
                            transparentize(0.7, props.theme.colors.textHeadings)}, ${(props) =>
    transparentize(0.7, props.theme.colors.textHeadings)}
                            , ${(props) => transparentize(0, props.theme.colors.bg0)} 200px
    , ${(props) => props.theme.colors.bg0} 200px);

                        position: absolute;
                        opacity: 0.9;
                        display: block;
                        bottom: -2px;
                        opacity: 0.075;
                        height: calc(100% + 4px);
                        width: calc(100% + 2px);
                        box-sizing: border-box;
                        left: 0;
                        border-radius: 3px;
                    }
                }
            }
        }
    }

    .conversation[data-mode="adventure"] {
        .editor2 .ProseMirror {
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
            >p {
                &[source="1"], &[source="2"] {
                    text-indent: 0;
                    border: 1px solid ${(props) => props.theme.colors.bg3};
                    border-radius: 3px;
                    width: auto;
                    display: block;
                    max-width: max-content;
                    height: min-content;
                    overflow: hidden;
                    font-weight: 600;
                    padding: 10px 20px !important;
                    margin: 20px 30px 10px;
                    position: relative;
                    &:before {
                        content: '';
                        pointer-events: none;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        mask-repeat: no-repeat;
                        mask-size: contain;
                        mask-position: center;
                        background-color: ${(props) => props.theme.colors.textMain};
                        position: absolute;
                        opacity: 0.9;
                        display: block;
                        bottom: 0;
                        transform: translateY(5%);
                        opacity: 0.075;
                    }
                }
                &[source="1"]:not([source="0"]) {
                    &:before {
                        mask-image: url(${RunningMan.src});
                        width: 75px;
                        height: 75px;
                        left: -13px;
                        top: 3px;
                    }
                }
                /* Special styling for same kind of block following another, for reference */
                /* &[source="1"] ~ p[source="1"] {
                    border-top: 0;
                    &:before {
                        content: unset;
                    }
                } */
                &[source="2"]:not([source="0"]) {
                    &:before {
                        mask-image: url(${SpeechBubble.src});
                        width: 65px;
                        height: 55px;
                        left: 5px;
                        top: 6px;
                    }
                }
            }
        }
    }

    .grecaptcha-badge {
        visibility: hidden;
    }

    .conversation-controls, .send-container {
        font-size: ${(props) => props.buttonScale}rem;
    }


    .svg-color-bg0.svg-fill, .svg-color-bg0-fill{
        fill: ${(props) => props.theme.colors.bg0};
    }
    .svg-color-bg1.svg-fill, .svg-color-bg1-fill{
        fill: ${(props) => props.theme.colors.bg1};
    }
    .svg-color-bg2.svg-fill, .svg-color-bg2-fill{
        fill: ${(props) => props.theme.colors.bg2};
    }
    .svg-color-bg3.svg-fill, .svg-color-bg3-fill{
        fill: ${(props) => props.theme.colors.bg3};
    }
    .svg-color-textHeadings.svg-fill, .svg-color-textHeadings-fill{
        fill: ${(props) => props.theme.colors.textHeadings};
    }
    .svg-color-textMain.svg-fill, .svg-color-textMain-fill{
        fill: ${(props) => props.theme.colors.textMain};
    }
    .svg-color-textEdit.svg-fill, .svg-color-textEdit-fill{
        fill: ${(props) => props.theme.colors.textAI};
    }
    .svg-color-bg0.svg-stroke, .svg-color-bg0-stroke{
        stroke: ${(props) => props.theme.colors.bg0};
    }
    .svg-color-bg1.svg-stroke, .svg-color-bg1-stroke{
        stroke: ${(props) => props.theme.colors.bg1};
    }
    .svg-color-bg2.svg-stroke, .svg-color-bg2-stroke{
        stroke: ${(props) => props.theme.colors.bg2};
    }
    .svg-color-bg3.svg-stroke, .svg-color-bg3-stroke{
        stroke: ${(props) => props.theme.colors.bg3};
    }
    .svg-color-textHeadings.svg-stroke, .svg-color-textHeadings-stroke{
        stroke: ${(props) => props.theme.colors.textHeadings};
    }
    .svg-color-textMain.svg-stroke, .svg-color-textMain-stroke{
        stroke: ${(props) => props.theme.colors.textMain};
    }
    .svg-color-textMain-20.svg-stroke, .svg-color-textMain-20-stroke{
        stroke: ${(props) => transparentize(0.85, props.theme.colors.textMain)};
    }

    .svg-color-bg2-stop-color{
        stop-color: ${(props) => props.theme.colors.bg2};
    }

    .comment-box {
        font-size: ${(props) => props.outputFontSize.toFixed(1)}px;
    }
`

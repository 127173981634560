export const pileUnitrim = {
    '96': -1,
    '97': -1,
    '98': -1,
    '99': -1,
    '100': -1,
    '101': -1,
    '102': -1,
    '103': -1,
    '104': -1,
    '105': -1,
    '106': -1,
    '107': -1,
    '108': -1,
    '109': -1,
    '110': -1,
    '111': -1,
    '112': -1,
    '113': -1,
    '114': -1,
    '115': -1,
    '116': -1,
    '117': -1,
    '118': -1,
    '119': -1,
    '120': -1,
    '121': -1,
    '122': -1,
    '123': -1,
    '124': -1,
    '125': -1,
    '126': 1,
    '127': 1,
    '128': 1,
    '129': 1,
    '130': 1,
    '131': 1,
    '132': 1,
    '133': 1,
    '134': 1,
    '135': 1,
    '136': 1,
    '137': 1,
    '138': 1,
    '139': 1,
    '140': 1,
    '141': 1,
    '142': 1,
    '143': 1,
    '144': 1,
    '145': 1,
    '146': 1,
    '147': 1,
    '148': 1,
    '149': 1,
    '150': 1,
    '151': 1,
    '152': 1,
    '153': 1,
    '154': 1,
    '155': 1,
    '156': 2,
    '157': 2,
    '158': 2,
    '159': 2,
    '160': 2,
    '161': 2,
    '162': 2,
    '163': 2,
    '164': 2,
    '165': 2,
    '166': 2,
    '167': 2,
    '168': 2,
    '169': 2,
    '170': 2,
    '171': 2,
    '172': 3,
    '173': 3,
    '174': 3,
    '175': 3,
    '176': 3,
    '211': -1,
    '212': -1,
    '213': -1,
    '214': -1,
    '215': -1,
    '216': -1,
    '217': -1,
    '218': -1,
    '219': -1,
    '220': -1,
    '221': -1,
    '222': -1,
    '223': -1,
    '224': -1,
    '225': -1,
    '226': -1,
    '227': -1,
    '228': -1,
    '229': -1,
    '230': -1,
    '231': -1,
    '232': -1,
    '233': -1,
    '234': -1,
    '235': -1,
    '236': -1,
    '237': -1,
    '238': -1,
    '239': -1,
    '240': -1,
    '241': -1,
    '242': -1,
    '243': -1,
    '244': -1,
    '325': 1,
    '541': 1,
    '765': 1,
    '929': 1,
    '1191': 1,
    '1325': 1,
    '1357': 1,
    '1454': 1,
    '1769': 1,
    '1819': 1,
    '1853': 1,
    '2155': 1,
    '2467': 1,
    '2483': 1,
    '2869': 1,
    '2871': 1,
    '3193': 1,
    '3218': 1,
    '3384': 2,
    '4954': 1,
    '5138': 1,
    '5494': 1,
    '5690': 1,
    '5941': 1,
    '5959': 1,
    '6078': 1,
    '6123': 1,
    '6280': 1,
    '6765': 1,
    '6810': 1,
    '6829': 1,
    '6916': 1,
    '7056': 1,
    '7111': 1,
    '7183': 1,
    '7226': 1,
    '7249': 1,
    '7297': 1,
    '7719': 1,
    '7772': 1,
    '7775': 2,
    '7786': 1,
    '8082': 1,
    '8318': -2,
    '8587': 1,
    '8611': 1,
    '8650': 1,
    '8841': 1,
    '9053': 1,
    '9078': 1,
    '9223': -2,
    '9504': 1,
    '9702': 1,
    '9799': 1,
    '10041': 1,
    '10361': 1,
    '10460': 1,
    '10608': 2,
    '10623': 1,
    '10673': 1,
    '10674': 1,
    '10681': 1,
    '10714': 1,
    '10716': 1,
    '10897': 1,
    '10962': 1,
    '11016': 1,
    '11043': 1,
    '11437': 1,
    '11800': 1,
    '11827': 1,
    '11871': 1,
    '11894': 1,
    '12005': 1,
    '12268': 1,
    '12272': 1,
    '12363': 1,
    '12448': 1,
    '12462': 1,
    '12483': 1,
    '12613': 1,
    '12676': 1,
    '12755': 1,
    '12811': 1,
    '12859': 1,
    '12984': 1,
    '13076': -2,
    '13127': 1,
    '13180': 1,
    '13246': 1,
    '13258': 1,
    '13262': 1,
    '13486': 1,
    '13499': 1,
    '13744': 1,
    '13764': 1,
    '13963': 1,
    '14129': 1,
    '14239': 1,
    '14262': 1,
    '14318': 1,
    '14378': 1,
    '14394': 1,
    '14486': 1,
    '14489': 1,
    '14670': 1,
    '14931': 2,
    '14945': 1,
    '15074': 1,
    '15176': 1,
    '15446': 1,
    '15756': 1,
    '15868': 1,
    '15899': 1,
    '15911': -2,
    '15954': 1,
    '15957': 1,
    '16127': 1,
    '16141': 2,
    '16385': 1,
    '16482': 1,
    '16549': 1,
    '16787': 1,
    '16800': 1,
    '16805': 1,
    '16818': 1,
    '16849': 1,
    '16857': 2,
    '16998': 1,
    '17289': 1,
    '17321': 1,
    '17474': 1,
    '17559': 1,
    '17598': 1,
    '17783': 1,
    '17865': 1,
    '17955': 1,
    '18081': 1,
    '18245': 1,
    '18537': 1,
    '18617': 1,
    '18713': -2,
    '18751': 1,
    '18947': 1,
    '19119': 1,
    '19127': 1,
    '19218': 1,
    '19597': 1,
    '19621': 1,
    '19675': -2,
    '19780': 1,
    '19957': 1,
    '20031': 1,
    '20241': 1,
    '20286': 1,
    '20345': 1,
    '20422': 1,
    '20516': 1,
    '20557': 1,
    '20616': 1,
    '20647': 1,
    '20827': 1,
    '20833': 1,
    '21053': 1,
    '21121': 1,
    '21163': 1,
    '21283': 1,
    '21365': 1,
    '21367': 1,
    '21422': 1,
    '21555': 1,
    '21683': 1,
    '21700': 1,
    '21944': 1,
    '21998': 1,
    '22150': 1,
    '22305': 1,
    '22379': 1,
    '22470': 1,
    '22580': 1,
    '22692': 2,
    '22701': 1,
    '22760': 1,
    '22983': 1,
    '22985': 1,
    '23101': 1,
    '23692': 1,
    '23823': 1,
    '23825': 1,
    '23956': 1,
    '23969': 1,
    '24299': 1,
    '24379': 1,
    '24384': -2,
    '24545': 1,
    '25086': 1,
    '25516': 1,
    '25541': 1,
    '25558': 1,
    '25657': 1,
    '25715': 1,
    '25762': 1,
    '25811': 2,
    '26036': 1,
    '26151': 1,
    '26335': 1,
    '26437': 1,
    '26473': 1,
    '26487': 1,
    '26532': 1,
    '26581': 1,
    '26601': 1,
    '26844': 1,
    '26974': 1,
    '27107': 1,
    '27258': 1,
    '27344': 1,
    '27374': 1,
    '27582': 1,
    '27661': 1,
    '27712': 1,
    '27739': 1,
    '27750': 1,
    '27997': 1,
    '28215': 1,
    '28492': 1,
    '28607': 1,
    '28650': 1,
    '28774': 1,
    '28812': 1,
    '28888': 1,
    '28915': 1,
    '28917': 1,
    '29268': 1,
    '29333': 1,
    '29597': 1,
    '29626': 1,
    '29785': 1,
    '29832': 1,
    '29852': 1,
    '30086': 1,
    '30088': 1,
    '30093': 1,
    '30255': 1,
    '30590': 1,
    '30676': 1,
    '30834': 1,
    '31165': 1,
    '31498': 1,
    '31554': 1,
    '31600': 1,
    '31811': 1,
    '31828': 1,
    '31902': 1,
    '31956': 1,
    '31963': 1,
    '32026': 1,
    '32053': 1,
    '32115': 1,
    '32120': 1,
    '32141': 1,
    '32262': 1,
    '32283': 1,
    '32363': 1,
    '32403': 1,
    '32417': 1,
    '32653': 1,
    '32654': 1,
    '32750': 1,
    '32790': 1,
    '33057': 1,
    '33089': 1,
    '33104': 1,
    '33369': 1,
    '33656': 1,
    '33700': 1,
    '33836': 1,
    '34138': 1,
    '34271': 1,
    '34372': 1,
    '34431': 1,
    '34556': 1,
    '34693': 1,
    '34749': 1,
    '34918': -2,
    '34996': 1,
    '35072': 1,
    '35169': 1,
    '35257': 1,
    '35259': 1,
    '35305': 1,
    '35392': 1,
    '35458': 1,
    '35801': 1,
    '35973': -2,
    '36087': 1,
    '36178': 1,
    '36337': 1,
    '36387': 1,
    '36720': 1,
    '36771': 1,
    '37215': 1,
    '37300': 1,
    '37531': 1,
    '37661': 1,
    '37679': 1,
    '37769': 1,
    '37794': 1,
    '37895': 1,
    '37933': 1,
    '38053': 1,
    '38262': 1,
    '38672': 1,
    '38675': 1,
    '38822': 1,
    '38892': 1,
    '38897': 1,
    '39068': -2,
    '39146': 1,
    '39393': 1,
    '39429': 1,
    '39915': 1,
    '39936': 1,
    '40025': 1,
    '40153': 1,
    '40162': 1,
    '40329': 1,
    '40843': 1,
    '41514': 1,
    '41777': 1,
    '41986': 1,
    '42025': 1,
    '42490': 1,
    '42984': 1,
    '43033': 1,
    '43641': 1,
    '43710': 1,
    '43894': 1,
    '44020': 1,
    '44028': 1,
    '44140': -2,
    '44246': 1,
    '44508': 1,
    '44633': 1,
    '44966': 1,
    '45128': 1,
    '45387': 1,
    '46180': 1,
    '46291': 1,
    '46438': 1,
    '46791': 1,
    '46927': 1,
    '46979': 1,
    '46982': 1,
    '46992': 1,
    '47568': 1,
    '48078': 1,
    '48153': 1,
    '48604': 1,
    '48672': 1,
    '48807': 1,
    '48897': 1,
    '49042': 1,
    '49070': 1,
    '49113': 1,
    '49233': 1,
    '49460': 1,
    '49835': 1,
    '50152': 1,
}

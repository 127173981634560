import { User, UserSubscription } from '../data/user/user'
import { UserType } from '../data/user/usertype'
import { PaddleOpusID, PaddleScrollID, PaddleTabletID } from '../globals/constants'

export function tierNumberToNameCaps(tier: number): string {
    switch (tier) {
        case 1: {
            return 'TABLET'
        }
        case 2: {
            return 'SCROLL'
        }
        case 3: {
            return 'OPUS'
        }
        case 4: {
            return 'CODEX'
        }
        default: {
            return 'Unknown / None'
        }
    }
}

export function tierMatchesSubscriptionId(tier: number, subscriptionId: number): boolean {
    switch (subscriptionId) {
        case PaddleOpusID: {
            return tier === 1
        }
        case PaddleTabletID: {
            return tier === 2
        }
        case PaddleScrollID: {
            return tier === 3
        }
        default: {
            return false
        }
    }
}

export function subscriptionIsActive(subscription: UserSubscription): boolean {
    if (subscription.accountType === UserType.SERVICE || subscription.accountType === UserType.SUPPORT) {
        return true
    }
    return subscription.expiresAt > Date.now() / 1000 && subscription.tier > 0
}

export function hasStreamedTTSAccess(_user: User): boolean {
    return true
}

export const getTrainingSteps = (trainingStepsLeft: number | Record<string, number>): number => {
    if (typeof trainingStepsLeft === 'object') {
        return trainingStepsLeft.fixedTrainingStepsLeft + trainingStepsLeft.purchasedTrainingSteps
    }
    if (typeof trainingStepsLeft === 'number') {
        return trainingStepsLeft
    }
    throw new Error('Wrong trainingStepsLeft in session: ' + typeof trainingStepsLeft)
}

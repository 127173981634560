export const gpt2Unitrim = {
    '94': -1,
    '95': -1,
    '96': -1,
    '97': -1,
    '98': -1,
    '99': -1,
    '100': -1,
    '101': -1,
    '102': -1,
    '103': -1,
    '104': -1,
    '105': -1,
    '106': -1,
    '107': -1,
    '108': -1,
    '109': -1,
    '110': -1,
    '111': -1,
    '112': -1,
    '113': -1,
    '114': -1,
    '115': -1,
    '116': -1,
    '117': -1,
    '118': -1,
    '119': -1,
    '120': -1,
    '121': -1,
    '122': -1,
    '123': -1,
    '124': 1,
    '125': 1,
    '126': 1,
    '127': 1,
    '128': 1,
    '129': 1,
    '130': 1,
    '131': 1,
    '132': 1,
    '133': 1,
    '134': 1,
    '135': 1,
    '136': 1,
    '137': 1,
    '138': 1,
    '139': 1,
    '140': 1,
    '141': 1,
    '142': 1,
    '143': 1,
    '144': 1,
    '145': 1,
    '146': 1,
    '147': 1,
    '148': 1,
    '149': 1,
    '150': 1,
    '151': 1,
    '152': 1,
    '153': 1,
    '154': 1,
    '155': 1,
    '156': 2,
    '157': 2,
    '158': 2,
    '159': 2,
    '160': 2,
    '161': 2,
    '162': 2,
    '163': 2,
    '164': 2,
    '165': 2,
    '166': 2,
    '167': 2,
    '168': 2,
    '169': 2,
    '170': 2,
    '171': 2,
    '172': 3,
    '173': 3,
    '174': 3,
    '175': 3,
    '176': 3,
    '177': 3,
    '178': 3,
    '179': 3,
    '222': -1,
    '223': -1,
    '224': -1,
    '225': -1,
    '226': -1,
    '227': -1,
    '228': -1,
    '229': -1,
    '230': -1,
    '231': -1,
    '232': -1,
    '233': -1,
    '234': -1,
    '235': -1,
    '236': -1,
    '237': -1,
    '238': -1,
    '239': -1,
    '240': -1,
    '241': -1,
    '242': -1,
    '243': -1,
    '244': -1,
    '245': -1,
    '246': -1,
    '247': -1,
    '248': -1,
    '249': -1,
    '250': -1,
    '251': -1,
    '252': -1,
    '253': -1,
    '254': -1,
    '255': -1,
    '447': 1,
    '564': 1,
    '1209': 1,
    '1587': 1,
    '1792': 1,
    '2343': 2,
    '2515': 1,
    '4204': -2,
    '5008': 1,
    '5099': 1,
    '5525': 2,
    '6184': 1,
    '6408': -2,
    '6552': 1,
    '7134': -2,
    '7377': 1,
    '8008': -2,
    '8582': 2,
    '8955': -2,
    '10253': -2,
    '10263': 2,
    '10310': 1,
    '10545': 2,
    '11019': 1,
    '11737': 1,
    '11805': -2,
    '11976': 1,
    '12045': 1,
    '12466': 1,
    '12520': 2,
    '12859': 1,
    '13305': 1,
    '13328': 2,
    '13783': 1,
    '13945': -2,
    '14360': 1,
    '14519': 1,
    '14524': 1,
    '15139': 1,
    '15474': 2,
    '15926': 1,
    '16268': 2,
    '17312': 1,
    '17358': 1,
    '17433': 1,
    '17550': 1,
    '17683': 2,
    '17739': 1,
    '17804': 1,
    '17992': 1,
    '18004': 1,
    '18074': 1,
    '18433': -2,
    '18796': 1,
    '18872': 1,
    '18923': 1,
    '19021': 1,
    '19049': 2,
    '19469': 1,
    '19526': 1,
    '19567': 1,
    '20015': 1,
    '20046': 1,
    '20174': 2,
    '20724': 1,
    '20998': 1,
    '21253': 2,
    '22522': 1,
    '22755': 1,
    '22757': -1,
    '22880': 1,
    '22887': 1,
    '23294': 1,
    '23329': -2,
    '23596': -2,
    '23626': 1,
    '23821': 2,
    '23877': 1,
    '24231': 1,
    '24583': 1,
    '24861': 1,
    '24966': 1,
    '25001': 1,
    '25081': -2,
    '25370': 1,
    '25443': 1,
    '26193': 1,
    '26292': 1,
    '26344': 1,
    '26486': 1,
    '26534': -2,
    '27032': 2,
    '27332': 2,
    '27670': 1,
    '27764': 1,
    '27950': 1,
    '28053': 2,
    '28156': 1,
    '28225': 1,
    '28839': 1,
    '28938': 1,
    '29705': 1,
    '29773': 1,
    '29785': 1,
    '29826': 1,
    '30266': 1,
    '30298': 1,
    '30325': 1,
    '30585': 1,
    '31204': -2,
    '31479': 1,
    '31619': 2,
    '31965': 1,
    '32003': 1,
    '32368': 1,
    '32391': 1,
    '32432': 1,
    '32518': 1,
    '32573': 1,
    '32849': 1,
    '33176': 1,
    '33232': 1,
    '33426': 2,
    '33434': -2,
    '33566': 1,
    '33699': 1,
    '33768': 1,
    '33951': 1,
    '34247': 1,
    '34402': 1,
    '34460': 1,
    '34504': 2,
    '34650': 1,
    '34719': 1,
    '34754': 1,
    '34932': 1,
    '35050': 2,
    '35069': -2,
    '35266': 1,
    '35705': 1,
    '35707': 1,
    '35975': 1,
    '36181': 1,
    '36365': 1,
    '36469': 1,
    '36596': -2,
    '36685': 1,
    '37239': 1,
    '37345': 1,
    '37605': 1,
    '37772': 1,
    '37863': 1,
    '38184': 1,
    '38461': 1,
    '39333': -2,
    '39355': 1,
    '39374': -1,
    '39611': 1,
    '39820': 1,
    '40367': 1,
    '41340': 1,
    '41365': -2,
    '41585': 1,
    '41678': -2,
    '41753': 1,
    '41840': 1,
    '42062': 2,
    '42164': 1,
    '42314': 1,
    '42527': 1,
    '43074': 1,
    '43102': 1,
    '43297': 1,
    '43380': 1,
    '43518': -2,
    '43636': 1,
    '43718': 1,
    '43769': -2,
    '43889': 1,
    '43897': 1,
    '44165': 1,
    '44293': 1,
    '45250': 1,
    '45379': 1,
    '45433': -2,
    '45495': 1,
    '45539': -2,
    '45617': 1,
    '45739': 1,
    '45784': 1,
    '45865': -2,
    '45911': 1,
    '46237': 1,
    '46256': 1,
    '46349': 1,
    '46479': 1,
    '46695': 1,
    '46763': 1,
    '46788': -2,
    '47078': 1,
    '47249': 1,
    '47490': 2,
    '47703': -2,
    '47728': 2,
    '47797': 1,
    '47947': 1,
    '47991': 1,
    '48071': 1,
    '48953': -2,
    '48958': 1,
    '49035': 1,
    '49149': 1,
    '49426': 1,
    '49694': 1,
    '50159': -2,
    '50169': 1,
}

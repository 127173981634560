import ImageVanilla from '../../assets/images/prefixes/vanilla.webp'
import ImageAcademy from '../../assets/images/prefixes/academy.webp'
import ImageAirships from '../../assets/images/prefixes/airships.webp'
import ImageDarkFantasy from '../../assets/images/prefixes/darkfantasy.webp'
import ImageFantasy from '../../assets/images/prefixes/fantasy.webp'
import ImageJulesVerne from '../../assets/images/prefixes/julesverne.webp'
import ImageLovecraft from '../../assets/images/prefixes/lovecraft.webp'
import ImageMars from '../../assets/images/prefixes/mars.webp'
import ImageNaval from '../../assets/images/prefixes/naval.webp'
import ImagePoe from '../../assets/images/prefixes/poe.webp'
import ImageRats from '../../assets/images/prefixes/rats.webp'
import ImageSao from '../../assets/images/prefixes/sao.webp'
import ImageSheridan from '../../assets/images/prefixes/sheridan.webp'
import ImageSherlock from '../../assets/images/prefixes/sherlock.webp'
import ImageThroneWars from '../../assets/images/prefixes/thronewars.webp'
import ImageTombRaiding from '../../assets/images/prefixes/tombraiding.webp'
import ImageAi from '../../assets/images/prefixes/ai.webp'
import ImageDragons from '../../assets/images/prefixes/dragons.webp'
import ImageHunter from '../../assets/images/prefixes/hunter.webp'
import ImageMilitaryScifi from '../../assets/images/prefixes/militaryscifi.webp'
import ImageThreeKingdoms from '../../assets/images/prefixes/threekingdoms.webp'
import ImageTextAdventure from '../../assets/images/prefixes/textadventure.webp'
import ImageSlime from '../../assets/images/prefixes/slime.webp'
import ImageMagicLibrary from '../../assets/images/prefixes/magiclibrary.webp'
import ImagePostApoc from '../../assets/images/prefixes/postapoc.webp'
import ImageMedieval from '../../assets/images/prefixes/medieval.webp'
import ImageRomance from '../../assets/images/prefixes/romance.webp'
import ImageSuperhero from '../../assets/images/prefixes/superhero.webp'
import ImageEgypt from '../../assets/images/prefixes/egypt.webp'
import ImageSliceOfLife from '../../assets/images/prefixes/sliceoflife.webp'
import ImagePirates from '../../assets/images/prefixes/pirates.webp'
import ImageMerchant from '../../assets/images/prefixes/merchant.webp'

import ImageCrossGenre from '../../assets/images/prefixes/crossgenre.webp'
import ImageChristmas from '../../assets/images/prefixes/christmas.webp'
import ImageValentines from '../../assets/images/prefixes/valentines.webp'
import ImageBlackwood from '../../assets/images/prefixes/blackwood.webp'
import ImageChildrens from '../../assets/images/prefixes/childrens.webp'
import ImageHistory from '../../assets/images/prefixes/history.webp'
import ImageHorror from '../../assets/images/prefixes/horror.webp'
import ImageLitrpg from '../../assets/images/prefixes/litrpg.webp'
import ImagePhilosophy from '../../assets/images/prefixes/philosophy.webp'
import ImageRome from '../../assets/images/prefixes/rome.webp'
import ImageVikings from '../../assets/images/prefixes/vikings.webp'
import ImagePoeticFantasy from '../../assets/images/prefixes/poeticfantasy.webp'
import ImageWesternRomance from '../../assets/images/prefixes/westernromance.webp'
import ImageSpaceOpera from '../../assets/images/prefixes/spaceopera.webp'
import ImageFeudalJapan from '../../assets/images/prefixes/feudaljapan.webp'
import ImageScienceFantasy from '../../assets/images/prefixes/sciencefantasy.webp'
import ImageTravel from '../../assets/images/prefixes/travel.webp'
import ImageMystery from '../../assets/images/prefixes/mystery.webp'
import ImageAnimalFiction from '../../assets/images/prefixes/animalfiction.webp'
import ImageCyberpunk from '../../assets/images/prefixes/cyberpunk.webp'
import ImageComedicFantasy from '../../assets/images/prefixes/comedicfantasy.webp'
import ImageUrbanFantasy from '../../assets/images/prefixes/urbanfantasy.webp'
import ImageJaneAusten from '../../assets/images/prefixes/janeausten.webp'
import ImageContemporary from '../../assets/images/prefixes/contemporary.webp'
import ImageProseAugmenter from '../../assets/images/prefixes/proseaugmenter.webp'
import ImageAncientChina from '../../assets/images/prefixes/ancientchina.webp'
import ImageAncientGreece from '../../assets/images/prefixes/ancientgreece.webp'
import ImageAnthropomorphicAnimals from '../../assets/images/prefixes/anthropomorphicanimals.webp'
import ImageGaming from '../../assets/images/prefixes/gaming.webp'
import ImageGoldenAgeScifi from '../../assets/images/prefixes/goldenagesf.webp'
import ImageHardSF from '../../assets/images/prefixes/hardsf.webp'
import ImageAncientIndia from '../../assets/images/prefixes/ancientindia.webp'
import ImageLightNovels from '../../assets/images/prefixes/lightnovel.webp'
import ImageMusic from '../../assets/images/prefixes/music.webp'
import ImageNature from '../../assets/images/prefixes/nature.webp'
import ImageNoir from '../../assets/images/prefixes/noir.webp'
import ImageWeirdWest from '../../assets/images/prefixes/weirdwest.webp'
import ImageWilliamShakespeare from '../../assets/images/prefixes/williamshakespeare.webp'

import { StoryMode } from '../story/story'
import { normalizeModel, TextGenerationModel } from '../request/model'

export const PrefixOptions = new Map([
    ['vanilla', { label: 'No Module', image: ImageVanilla, mode: 0 as StoryMode, description: '' }],
    [
        'special_openings',
        { label: 'Special: Openings', image: ImageVanilla, mode: 0 as StoryMode, description: '' },
    ],
    [
        'special_instruct',
        {
            label: 'General: Instruct (Experimental)',
            image: ImageVanilla,
            mode: 0 as StoryMode,
            description: 'Use Instruct commands to give the AI a specific direction.',
        },
    ],
    [
        'general_crossgenre',
        {
            label: 'General: Cross-Genre',
            image: ImageCrossGenre,
            mode: 0 as StoryMode,
            description: 'A general module for many genres.',
        },
    ],
    [
        'theme_textadventure',
        {
            label: 'Special: Text Adventure',
            image: ImageTextAdventure,
            mode: 1 as StoryMode,
            description: "It's up to you whether to change your course of action. Do you proceed?",
        },
    ],
    [
        'special_proseaugmenter',
        {
            label: 'General: Prose Augmenter',
            image: ImageProseAugmenter,
            mode: 0 as StoryMode,
            description:
                'A general module for verbose prose. If you find the AI struggling with short sentences \
                and other writing quality pitfalls, Prose Augmenter can help.',
        },
    ],
    [
        'style_algernonblackwood',
        {
            label: 'Style: Algernon Blackwood',
            image: ImageBlackwood,
            mode: 0 as StoryMode,
            description: 'The unquestioned master of weird atmosphere, unusual fiction and ghost stories.',
        },
    ],
    [
        'style_arthurconandoyle',
        {
            label: 'Style: Arthur Conan Doyle',
            image: ImageSherlock,
            mode: 0 as StoryMode,
            description:
                "A master detective seeks the truth in every case, solving crimes with logic, wit, \
                and a keen mind. It's elementary, my dear.",
        },
    ],
    [
        'style_edgarallanpoe',
        {
            label: 'Style: Edgar Allan Poe',
            image: ImagePoe,
            mode: 0 as StoryMode,
            description:
                "The darkness in man's soul, the twisted will of a madman. \
                Influenced by the works of one of literature's greatest authors.",
        },
    ],
    [
        'style_hplovecraft',
        {
            label: 'Style: H.P. Lovecraft',
            image: ImageLovecraft,
            mode: 0 as StoryMode,
            description:
                'In a forgotten corner of the universe, beings stretch out ethereal tendrils. \
                Who knows what unknown horrors lurk in the shadows...?',
        },
    ],
    [
        'style_shridanlefanu',
        {
            label: 'Style: Sheridan Le Fanu',
            image: ImageSheridan,
            mode: 0 as StoryMode,
            description:
                "It's a restless and scrupulous passion, curiosity. What whispers slip between the veil of light and shadow?",
        },
    ],

    [
        'style_janeausten',
        {
            label: 'Style: Jane Austen',
            image: ImageJaneAusten,
            mode: 0 as StoryMode,
            description: 'Experience a Regency world of wit, romance, and social politics.',
        },
    ],

    [
        'style_julesverne',
        {
            label: 'Style: Jules Verne',
            image: ImageJulesVerne,
            mode: 0 as StoryMode,
            description:
                'Journey to distant lands to explore strange cultures. \
                Travel to a world of tribal folk, mermaids, and dinosaurs.',
        },
    ],
    [
        'style_williamshakespeare',
        {
            label: 'Style: William Shakespeare',
            image: ImageWilliamShakespeare,
            mode: 0 as StoryMode,
            description:
                'Beguiling language - in script form, tragedy, all-powerful characters, \
                and a world of romantic fantasy and adventure.',
        },
    ],
    [
        'theme_19thcenturyromance',
        {
            label: 'Theme: 19th Century Romance',
            image: ImageRomance,
            mode: 0 as StoryMode,
            description:
                'Take a trip down memory lane to an era when chivalry was king. Witness love affairs bloom, \
                and scandalous trysts be exposed!',
        },
    ],
    [
        'theme_actionarcheology',
        {
            label: 'Theme: Action Archeology',
            image: ImageTombRaiding,
            mode: 0 as StoryMode,
            description:
                'Rumors of great treasures abound throughout history. Follow clues through mystical ruins, \
                avoiding devious traps along the way.',
        },
    ],
    [
        'theme_ai',
        {
            label: 'Theme: Artificial Intelligence',
            image: ImageAi,
            mode: 0 as StoryMode,
            description:
                'In this world of silicon, the line between machine and human blurs. \
                Dream of electric sheep, or starships off a distant world.',
        },
    ],
    [
        'theme_ancientchina',
        {
            label: 'Theme: Ancient China',
            image: ImageAncientChina,
            mode: 0 as StoryMode,
            description:
                "Ancient China's turbulent history comes alive, featuring the \
            rise of an Empire, celestial bureaucracy, and heroes. Claim your own destiny, or serve others' whims!",
        },
    ],
    [
        'theme_ancientgreek',
        {
            label: 'Theme: Ancient Greece',
            image: ImageAncientGreece,
            mode: 0 as StoryMode,
            description:
                'Wander through the streets of Athens, drink wine in tavernas, \
            listen to the songs of the rhapsodists while you befriend the gods—or betray them.',
        },
    ],
    [
        'theme_india',
        {
            label: 'Theme: Ancient India',
            image: ImageAncientIndia,
            mode: 0 as StoryMode,
            description:
                'Find your place in ancient Indian society: Ancient Indian mythology, magic, demons, yadavs, rakshas, \
                and more! A vast universe of myth and legend awaits!',
        },
    ],
    [
        'theme_animalfiction',
        {
            label: 'Theme: Animal Fiction',
            image: ImageAnimalFiction,
            mode: 0 as StoryMode,
            description:
                'Drawing from fiction written from the perspective of animals or about animals, be a fierce \
                lion, cunning crow, or tall giraffe. Be whatever animal you desire! ',
        },
    ],
    [
        'theme_anthropomorphicanimals',
        {
            label: 'Theme: Anthropomorphic Animals',
            image: ImageAnthropomorphicAnimals,
            mode: 0 as StoryMode,
            description:
                "Begone, hands! It's time for paws, tails, wings, and claws! These anthro animals have fur, and a lot of it.",
        },
    ],
    [
        'theme_childrens',
        {
            label: "Theme: Children's Fiction",
            image: ImageChildrens,
            mode: 0 as StoryMode,
            description:
                'Evoke your inner child and engage in an adventure that will take you back to earlier days.',
        },
    ],
    [
        'theme_christmas',
        {
            label: 'Theme: Christmas',
            image: ImageChristmas,
            mode: 0 as StoryMode,
            description:
                'A joyful thematic wildcard to instantly transport you into the merriest season of the year!',
        },
    ],
    [
        'theme_comedicfantasy',
        {
            label: 'Theme: Comedic Fantasy',
            image: ImageComedicFantasy,
            mode: 0 as StoryMode,
            description:
                'Fantasy of a satirical and humorous bent, with witty narrators, bumbling wizards, \
                and heroic but yet inept warriors!',
        },
    ],
    [
        'theme_contemporary',
        {
            label: 'Theme: Contemporary',
            image: ImageContemporary,
            mode: 0 as StoryMode,
            description: 'Relatively relatable: portraying modern everyday life.',
        },
    ],
    [
        'theme_cyberpunk',
        {
            label: 'Theme: Cyberpunk',
            image: ImageCyberpunk,
            mode: 0 as StoryMode,
            description:
                'Jack into the Grid, and conduct cybernetic warfare against other sentients. Enhance your \
                body with various cybernetic enhancements and perform espionage in a dystopia.',
        },
    ],
    [
        'theme_darkfantasy',
        {
            label: 'Theme: Dark Fantasy',
            image: ImageDarkFantasy,
            mode: 0 as StoryMode,
            description:
                'The gifted few harness strange powers. When fortresses are besieged, \
                this ancient world will be pushed to the brink.',
        },
    ],
    [
        'theme_dragons',
        {
            label: 'Theme: Dragons',
            image: ImageDragons,
            mode: 0 as StoryMode,
            description:
                'Crimson skies are dominated by wings like hurricanes and teeth like daggers. \
                This realm is ruled over by fire breathers of legend.',
        },
    ],
    [
        'theme_egypt',
        {
            label: 'Theme: Egypt',
            image: ImageEgypt,
            mode: 0 as StoryMode,
            description:
                'Mummies stir in the desert, seeking freedom from their curse. Explore ancient pyramids, \
                and discover why the pharaohs were revered beyond their time.',
        },
    ],
    [
        'theme_feudaljapan',
        {
            label: 'Theme: Feudal Japan',
            image: ImageFeudalJapan,
            mode: 0 as StoryMode,
            description:
                'Go back to Feudal Japan, where samurai epics abound, along with detective stories, and \
                tales of love conveyed through poetry.',
        },
    ],
    [
        'theme_gaming',
        {
            label: 'Theme: Gaming',
            image: ImageGaming,
            mode: 0 as StoryMode,
            description:
                "Experience the crunch! Take part in a gaming company's office life, or play old-school games with your friends.",
        },
    ],
    [
        'theme_generalfantasy',
        {
            label: 'Theme: General Fantasy',
            image: ImageFantasy,
            mode: 0 as StoryMode,
            description:
                'Behold a land where men wield magic swords, and elves sing songs of valor. \
                Experience epic battles, and legendary adventures.',
        },
    ],
    [
        'theme_goldenagescifi',
        {
            label: 'Theme: Golden Age Scifi',
            image: ImageGoldenAgeScifi,
            mode: 0 as StoryMode,
            description:
                'No computers or long distance communications, just spaceships, robots, lasers, and \
                (mostly) men surviving on their wits against the galaxy.',
        },
    ],
    [
        'theme_hardsf',
        {
            label: 'Theme: Hard SF',
            image: ImageHardSF,
            mode: 0 as StoryMode,
            description: 'Simply put, sci-fi that tries to be realistic. A lot of semi-plausible tech here!',
        },
    ],
    [
        'theme_history',
        {
            label: 'Theme: History',
            image: ImageHistory,
            mode: 0 as StoryMode,
            description: 'A pen is stronger than a sword. Rewrite history however you desire.',
        },
    ],
    [
        'theme_horror',
        {
            label: 'Theme: Horror',
            image: ImageHorror,
            mode: 0 as StoryMode,
            description:
                'Experience nightmares like never before. Indulge in the deepest depths of the human mind, and beyond...',
        },
    ],
    [
        'theme_huntergatherer',
        {
            label: 'Theme: Hunter Gatherer',
            image: ImageHunter,
            mode: 0 as StoryMode,
            description:
                'When man discovered fire, a new age dawned upon the world. Enter the paleolithic, \
                and discover the secrets of survival in our primitive past.',
        },
    ],
    [
        'theme_litrpg',
        {
            label: 'Theme: LitRPG',
            image: ImageLitrpg,
            mode: 0 as StoryMode,
            description:
                'Stat blocks and status conditions, monsters and magic spells; Level up your roleplaying experience!',
        },
    ],
    [
        'theme_magicacademy',
        {
            label: 'Theme: Magic Academy',
            image: ImageAcademy,
            mode: 0 as StoryMode,
            description:
                'Learn spells from wizards old and young. One must choose their path wisely—and never stop learning.',
        },
    ],
    [
        'theme_libraries',
        {
            label: 'Theme: Magic Library',
            image: ImageMagicLibrary,
            mode: 0 as StoryMode,
            description:
                'They say a book is a gateway to another world. In this magical collection, \
                the adage may be more literal than expected. ',
        },
    ],
    [
        'theme_lightnovels',
        {
            label: 'Theme: Light Novels',
            image: ImageLightNovels,
            mode: 0 as StoryMode,
            description:
                'Get your dose of otaku medicine, with a dash of isekai, fantasy, slice of life and some romance!',
        },
    ],
    [
        'theme_mars',
        {
            label: 'Theme: Mars Colonization',
            image: ImageMars,
            mode: 0 as StoryMode,
            description:
                'Brave pioneers set foot on another world. What would it take to tame the red planet?',
        },
    ],
    [
        'theme_medieval',
        {
            label: 'Theme: Medieval',
            image: ImageMedieval,
            mode: 0 as StoryMode,
            description:
                'A new age dawns as knights rise and empires fall. Take up sword and shield, or cloak and dagger \
                in this archaic setting.',
        },
    ],
    [
        'theme_militaryscifi',
        {
            label: 'Theme: Military SciFi',
            image: ImageMilitaryScifi,
            mode: 0 as StoryMode,
            description:
                'Though the weapons changed, war remained the same. From futuristic trenches to intergalactic starfighters, \
                experience life at the front lines.',
        },
    ],
    [
        'theme_music',
        {
            label: 'Theme: Music',
            image: ImageMusic,
            mode: 0 as StoryMode,
            description:
                'The world of touring bands, drugs, and groupies! Can you resist temptation or stay focused on your art?',
        },
    ],
    [
        'theme_mystery',
        {
            label: 'Theme: Mystery',
            image: ImageMystery,
            mode: 0 as StoryMode,
            description:
                "Whodunit? Conduct investigations into mysteries that abound. Poke your nose into places \
                where it doesn't belong.",
        },
    ],
    [
        'theme_nature',
        {
            label: 'Theme: Nature',
            image: ImageNature,
            mode: 0 as StoryMode,
            description:
                'Survive the elements of rugged terrain, climb sheer mountains, and forge a path through the wilderness.',
        },
    ],
    [
        'theme_naval',
        {
            label: 'Theme: Naval Age of Discovery',
            image: ImageNaval,
            mode: 0 as StoryMode,
            description:
                'Sail the seven seas aboard mighty ships, while exploring exotic ports and uncharted waters.',
        },
    ],
    [
        'theme_noir',
        {
            label: 'Theme: Noir',
            image: ImageNoir,
            mode: 0 as StoryMode,
            description: 'Cynical detectives, hard-boiled dialogue, and black and white morality.',
        },
    ],
    [
        'theme_philosophy',
        {
            label: 'Theme: Philosophy',
            image: ImagePhilosophy,
            mode: 0 as StoryMode,
            description: 'Let philosophical and political figures from old and new guide your stories.',
        },
    ],
    [
        'theme_pirates',
        {
            label: 'Theme: Pirates',
            image: ImagePirates,
            mode: 0 as StoryMode,
            description:
                "There be loot to plunder on the high seas! 'Course, ye best be ready when the galleons come calling...",
        },
    ],
    [
        'theme_poeticfantasy',
        {
            label: 'Theme: Poetic Fantasy',
            image: ImagePoeticFantasy,
            mode: 0 as StoryMode,
            description:
                'Craft beautiful poetry and stories, set in a curious land of magic and wonder, with delicate \
                prose. Fairy dust not included.',
        },
    ],
    [
        'theme_postapocalyptic',
        {
            label: 'Theme: Post-Apocalyptic',
            image: ImagePostApoc,
            mode: 0 as StoryMode,
            description:
                'The world has ended, but the story continues. Scavenge for supplies and face fearsome mutated beasts.',
        },
    ],
    [
        'theme_rats',
        {
            label: 'Theme: Rats',
            image: ImageRats,
            mode: 0 as StoryMode,
            description:
                'Preying and stalking through the night, these little squeakers seek food and shelter \
                wherever they find it. Eeek!',
        },
    ],
    [
        'theme_romanempire',
        {
            label: 'Theme: Roman Empire',
            image: ImageRome,
            mode: 0 as StoryMode,
            description: 'When in Rome, do as the Romans do.',
        },
    ],
    [
        'theme_sciencefantasy',
        {
            label: 'Theme: Science Fantasy',
            image: ImageScienceFantasy,
            mode: 0 as StoryMode,
            description:
                'Each step within these storied lands whispers tales of civilizations long \
                forgotten where great technological works were once regarded as witchcraft.',
        },
    ],
    [
        'theme_spaceopera',
        {
            label: 'Theme: Space Opera',
            image: ImageSpaceOpera,
            mode: 0 as StoryMode,
            description:
                'Soar between asteroids, explode planets, find your alien love, or maybe even start \
                an intergalactic war. Infinite universe, infinite possibilities.',
        },
    ],
    [
        'theme_superheroes',
        {
            label: 'Theme: Superheroes',
            image: ImageSuperhero,
            mode: 0 as StoryMode,
            description:
                'When heroes clash with villains, only one can emerge victorious. Stand for justice, or join forces against it.',
        },
    ],
    [
        'theme_airships',
        {
            label: 'Theme: Steampunk',
            image: ImageAirships,
            mode: 0 as StoryMode,
            description:
                'Soar above the clouds and into a world of adventure, clockwork engines and flying machines.',
        },
    ],
    [
        'theme_travel',
        {
            label: 'Theme: Travel',
            image: ImageTravel,
            mode: 0 as StoryMode,
            description:
                'Travel around the world on years-long journeys to the darkest jungles, highest peaks, \
                deepest seas, driest deserts, and see the myriad Wonders of the World!',
        },
    ],

    [
        'theme_urbanfantasy',
        {
            label: 'Theme: Urban Fantasy',
            image: ImageUrbanFantasy,
            mode: 0 as StoryMode,
            description:
                'Interact with elves, fairies and other fantasy creatures in a modern \
                 urban environment where magic exists!',
        },
    ],
    [
        'theme_valentines',
        {
            label: 'Theme: Valentines',
            image: ImageValentines,
            mode: 0 as StoryMode,
            description:
                'This module is sure to make your heart flutter! Keep the sparks flying with this \
                module trained on a collection of romantic literature!',
        },
    ],
    [
        'theme_vikings',
        {
            label: 'Theme: Vikings',
            image: ImageVikings,
            mode: 0 as StoryMode,
            description: 'Raise your swords and shields, screaming on the way to Valhalla!',
        },
    ],
    [
        'theme_weirdwest',
        {
            label: 'Theme: Weird West',
            image: ImageWeirdWest,
            mode: 0 as StoryMode,
            description:
                'Take to the dusty trail and encounter magical natives, werewolf cowboys, and undead creatures of the wild west.',
        },
    ],
    [
        'theme_westernromance',
        {
            label: 'Theme: Western Romance',
            image: ImageWesternRomance,
            mode: 0 as StoryMode,
            description:
                'Blaze the open trails with a big iron on your hip, and maybe a pretty pardner as well.',
        },
    ],
    [
        'inspiration_crabsnailandmonkey',
        {
            label: 'Inspiration: Crab, Snail, and Monkey',
            image: ImageSliceOfLife,
            mode: 0 as StoryMode,
            description:
                'High school gets weird in this unique mixture of slice of life, romance, and supernatural mystery.',
        },
    ],
    [
        'inspiration_mercantilewolfgirlromance',
        {
            label: 'Inspiration: Mercantile Wolfgirl Romance',
            image: ImageMerchant,
            mode: 0 as StoryMode,
            description: 'A merchant and a wolf girl goddess travelling the lands.',
        },
    ],
    [
        'inspiration_nervegear',
        {
            label: 'Inspiration: Nervegear',
            image: ImageSao,
            mode: 0 as StoryMode,
            description: "It's all fun and games, until the users of a VR MMORPG find they can't log out.",
        },
    ],
    [
        'theme_romanceofthreekingdoms',
        {
            label: 'Inspiration: Romance of the Three Kingdoms',
            image: ImageThreeKingdoms,
            mode: 0 as StoryMode,
            description:
                "China's greatest warlords vie for supremacy. Experience the art of war on a grand scale, \
                in one of the most renowned tales ever told.",
        },
    ],
    [
        'inspiration_thronewars',
        {
            label: 'Inspiration: Throne Wars',
            image: ImageThroneWars,
            mode: 0 as StoryMode,
            description:
                'As the kingdom faces invasion from within and without, one must make difficult choices;\
                to fight with honor, or or rule by fear?',
        },
    ],
    [
        'inspiration_witchatlevelcap',
        {
            label: 'Inspiration: Witch at Level Cap',
            image: ImageSlime,
            mode: 0 as StoryMode,
            description:
                'Having gone to another world and maxed out her level cap, a witch lives a laid back life.',
        },
    ],
])

export { default as CustomPrefixImage } from '../../assets/images/prefixes/gold.webp'

export const getModulesForModel = (model: TextGenerationModel): string[] => {
    switch (normalizeModel(model)) {
        case normalizeModel(TextGenerationModel.j6bv4):
        case normalizeModel(TextGenerationModel.euterpev2): {
            return [...PrefixOptions.keys()].filter(
                (key) => key !== 'special_instruct' && key !== 'special_openings'
            )
        }
        case normalizeModel(TextGenerationModel.krakev2): {
            return [
                'vanilla',
                'general_crossgenre',
                'theme_textadventure',
                'style_algernonblackwood',
                'style_arthurconandoyle',
                'style_edgarallanpoe',
                'style_hplovecraft',
                'style_shridanlefanu',
                'style_julesverne',
                'theme_19thcenturyromance',
                'theme_actionarcheology',
                'theme_ai',
                'theme_animalfiction',
                'theme_childrens',
                'theme_christmas',
                'theme_comedicfantasy',
                'theme_cyberpunk',
                'theme_darkfantasy',
                'theme_dragons',
                'theme_egypt',
                'theme_feudaljapan',
                'theme_generalfantasy',
                'theme_history',
                'theme_horror',
                'theme_huntergatherer',
                'theme_litrpg',
                'theme_magicacademy',
                'theme_libraries',
                'theme_mars',
                'theme_medieval',
                'theme_militaryscifi',
                'theme_mystery',
                'theme_naval',
                'theme_philosophy',
                'theme_pirates',
                'theme_poeticfantasy',
                'theme_postapocalyptic',
                'theme_rats',
                'theme_romanempire',
                'theme_sciencefantasy',
                'theme_spaceopera',
                'theme_superheroes',
                'theme_airships',
                'theme_travel',
                'theme_urbanfantasy',
                'theme_valentines',
                'theme_vikings',
                'theme_westernromance',
                'inspiration_crabsnailandmonkey',
                'inspiration_mercantilewolfgirlromance',
                'inspiration_nervegear',
                'theme_romanceofthreekingdoms',
                'inspiration_thronewars',
                'inspiration_witchatlevelcap',
            ]
        }
        case TextGenerationModel.green:
        case TextGenerationModel.red:
        case TextGenerationModel.blue:
        case TextGenerationModel.purple:
        case TextGenerationModel.pink:
        case TextGenerationModel.yellow:
        case TextGenerationModel.white:
        case TextGenerationModel.black:
        case TextGenerationModel.clio: {
            return ['vanilla', 'special_instruct', 'special_proseaugmenter', 'theme_textadventure']
        }
        case TextGenerationModel.kayra: {
            return [
                'vanilla',
                'special_instruct',
                'special_proseaugmenter',
                'theme_textadventure',
                'theme_christmas',
            ]
        }
    }
    return []
}

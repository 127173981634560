/* eslint-disable max-len */
export default {
    lang: 'jp',
    tier: {
        paper: 'Paper',
        tablet: 'Tablet',
        scroll: 'Scroll',
        opus: 'Opus',
        unknown: 'Unknown / None',
    },
    general: {
        tier: 'ティア',
        account: 'アカウント',
        other: 'その他',
        help: 'ヘルプ',
        logout: 'ログアウト',
        login: 'ログイン',
        version: 'バージョン',
        beta: 'ベータ版',
        email: 'メール',
        password: 'パスワード',
        endSession: 'セッションを終了',
        anlas: 'Anlas',
        signIn: 'サインイン',
        signUp: 'サインアップ',
        logIn: 'ログイン',
        note: '注意：',
        welcomeBack: 'おかえりなさい！',
        loading: '読み込み中…',
    },
    cta: {
        manage: '管理',
        change: '変更',
        request: 'リクエスト',
        confirm: '確認',
        cancel: 'キャンセル',
        save: '保存',
        upgrade: 'アップグレード',
        yes: 'はい',
        ok: 'OK',
        gotIt: '了解',
        readMore: '続きを読む',
        manageSubscription: 'サブスクリプションの管理',
        overwrite: '上書き',
        startWriting: '書き始める',
        submit: '送信する',
        subscribe: '登録',
        unsubscribe: '配信停止',
    },
    common: {
        download: 'ダウンロード',
        delete: '削除',
        defaultX: 'デフォルト： [0]',
        applyAndSave: '適用＆保存',
        import: 'インポート',
        export: 'エクスポート',
        anonymouseTrial: '匿名トライアル',
        processing: '処理中',
        notSubscribed: 'サブスクライブされていません',
        somethingWentWrong: '問題が起きました！',
        copy: 'コピー',
        hide: '非表示',
        reveal: '表示',
        unknown: '不明',
        back: '戻る',
        giftKey: 'ギフトキー',
        optional: '(任意)',
        fileTypeNotSupported: 'ファイル形式がサポートされていません。',
        maximumFileSize: 'ファイルサイズは最大 [0]MB までです。',
        expand: '広げる',
        collapse: '折りたたむ',
        dontShowThisAgain: '今後は表示しない。',
        learnMore: 'もっと詳しく',
        skip: 'スキップ',
    },
    authFormGeneral: {
        getStarted: '始めましょう',
        email: 'Eメール',
        repeatEmail: 'Eメールの再確認',
        repeatPassword: 'パスワードの再確認',
        emailEmptyWarning: 'Eメールアドレスが未入力です',
        emailPasswordEmptyWarning: 'Eメールアドレスまたはパスワードが未入力です',
        rememberMe: '設定を記憶',
        loginRemembered: 'ログイン情報は30日間記憶されます',
        loginNotRemembered: 'ログイン情報は記憶されません',
        credentialsSub:
            'ログインに使用する情報を適切に管理してください。ローカル暗号化により、登録されているEメールアドレスやパスワードを忘れた場合、リモートで保管されている物語やAIモジュールへのアクセスが永久に失われます。',
        captchaSub:
            'このサイトはreCAPTCHAによって保護されており、Googleプライバシーポリシーと利用規約が適用されます。',
        naiTermsNote: {
            0: '登録を行うことで、NovelAIの',
            1: 'プライバシーポリシーと利用規約',
            2: 'に同意したことになります。',
        },
        googleTermsNote: {
            0: 'このサイトはreCAPTCHAによって保護されており、Google',
            1: 'プライバシーポリシー',
            2: 'と',
            3: '利用規約',
            4: 'が適用されます。',
        },
        marketingEmails: '当社からの最新情報をメールで受け取りたいですか？',
        emailWhitelistWarning: {
            0: 'NOTE',
            1: 'Your email has been marked as being unable to access the free trial. Please subscribe to use the product.',
        },
    },
    passwordReset: {
        resetPassword: 'パスワードの再設定',
        resetPassWordSub:
            'このフォームを送信すると、パスワードを再設定する処理の手順が書かれたリンクがメールで送られます。',
        encryptionSub:
            'ローカル暗号化により、パスワードの再設定を行うとリモートで保管されている物語やAIモジュールへのアクセスが永久に失われます。',
    },
    storyDelete: {
        deleteIt: '削除します！',
        deleteStory: 'ストーリーを削除しますか？',
        areYouSure: '本当に"[0]"を削除したいですか？',
        cannotBeUndone: '元に戻せません。',
    },
    features: {
        imageGeneration: '画像生成',
        aiModuleTraining: 'AIモジュールトレーニング',
        tokenizer: 'トークンナイザー',
        tutorial: 'チュートリアル',
        tips: '一口ヒント',
        docs: '関連ドキュメント',
    },
    modal: {
        userSettings: 'ユーザー設定',
        aiModules: 'AIモデル',
        lorebook: 'Lorebook（ロアーブック）',
        storySettings: '物語設定',
        contextViewer: 'コンテンツビューワー',
    },
    image: {
        recommended: 'オススメ',
        generateText: '生成',
        paintNewImage: '自分でペイントする',
        uploadImage: '画像をアップロードする',
        modelSettings: 'モデル詳細設定',
        modelSettingsTTSteps: 'ステップ： ノイズを処理する回数です',
        modelSettingsTTScale:
            'スケール：　高スケールではAIはプロンプトに忠実になり、細部までハッキリと描きます。低スケールでは対象物の描画が曖昧になる代わりに、自由でクリエイティブな表現を楽しめます',
        portrait: '縦型',
        landscape: '横型',
        square: '正方形',
        custom: 'カスタム',
        small: '小サイズ',
        normal: '普通サイズ',
        large: '大サイズ',
        largeplus: '大サイズ＋',
        wallpaper: '壁紙',
        tokenCounter: '[1] トークンの内 [0] トークンを使用中',
        undesiredContent: '除外したい要素',
        lowQuality: '低品質',
        badAnatomy: '身体の破綻',
        none: '指定なし',
        addQualityTags: '品質タグを加える',
        addQualityTagsSubOff: 'あなたのプロンプトがそのまま使われます。',
        addQualityTagsSubOn: '品質タグをプロンプトに付け加えることで、生成物の品質が向上します',
        steps: 'ステップ',
        scale: 'スケール',
        seed: 'シード値',
        seedSub: 'シード値を入力',
        advancedSampler: 'サンプラー',
        numberOfImages: '同時生成数',
        history: '履歴',
        historyTT:
            '履歴欄内の画像をCtrl+クリックすると、その画像の生成時に使用した設定をインポートできます（初期画像を除く）。',
        settings: '設定',
        naidFurryDesc: '人外キャラクターや動物キャラクターを作成する為の開発中のAIモデルです。（非推奨）',
        naidFullDesc: '拡張されたデータセットで訓練されたオリジナルモデルの1つ。（非推奨）',
        naidCuratedDesc: 'オリジナルモデルの1つ。（非推奨）',
        naidAnime2Desc: '当社の旧モデル（非推奨）',
        naidAnime3Desc: '当社の最先端のモデル（推奨）',
        naidFurry3Desc: '当社の最先端のモデル（推奨）',
        downloadZIP: '一括で圧縮保存',
        selected: '選択中:',
        draw: 'ペン',
        erase: '消しゴム',
        select: '選択',
        save: '保存',
        cancel: 'キャンセル',
        colorPicker: 'スポイト',
        penSize: 'ペンサイズ',
        pressureSens: '筆圧感知',
        resizeCanvas: 'キャンバスサイズを変更',
        lowQualityPlusBadAnatomy: '低品質 + 人体描写の破綻',
        manageSubscription: 'サブスクリプションを管理',
        autoSmea: '自動nai_smea',
        autoSmeaChecked: '大きな画像サイズでの生成では nai_smea が自動選択されます',
        autoSmeaUnchecked: 'nai_smea は自動で選択されません.',
        imageResolution: '画像サイズ',
        useSmea: 'SMEAを使用',
        useSmeaTooltip: 'Smea版サンプラーは高解像度で高い性能を発揮します。',
        useSmeaTooltipDisabled: 'このサンプラーはSMEAに対応していません。',
        autoSmeaTooltip: '一定のサイズ以上の生成ではSMEAサンプラーが自動選択されます。',
        useDynVariant: 'Dynバリアント',
        useDynVariantTooltip:
            'サンプラーのSMEA Dynバリアントは、特徴的なSMEAのスタイルを取り込むことなく高解像度の画像の統一性を高められるように最適化されています。解像度の有効範囲は通常のSMEAより小さくなります。',
        prompt: 'プロンプト',
        undesiredContentSub: '除外したい要素を入力してください',
        promptSub: 'プロンプトを入力し、理想の画像を生成しましょう',
        disableTagSuggestions: '入力候補予測をやめる',
        promptGuidance: 'プロンプトガイダンス',
        guidance: '正確度',
        sampler: 'サンプラー',
        smea: 'SMEA',
        dyn: 'DYN',
        enhanceButton: '品質向上',
        enhanceImage: '画像の品質を向上させる',
        invalid: '無効',
        enhanceScale: '画像の拡大率',
        strength: '強度',
        noise: 'ノイズ',
        magnitude: 'レベル',
        showAdvanced: '高度な設定を表示',
        hideAdvanced: '表示を隠す',
        aiSettings: 'AI設定',
        addBaseImage: '参照用画像を追加(任意)',
        img2img: 'i2i',
        img2imgSub: '元画像を加工し新しい画像を生成する',
        palleteSwap: 'カラースワップ',
        palleteSwapSub: '構図を保ったままキャラクターのカラーパレットを変更',
        formLock: 'フォームロック',
        formLockSub: '輪郭や形を保ったまま新しいキャラクターを配置',
        scribbler: 'スクリブラー',
        scribblerSub: '落書きや白黒画像を芸術作品に変えましょう',
        buildingControl: 'ビルディングコントロール',
        buildingControlSub: '素敵な家や部屋をデザインしましょう',
        landscaper: 'ランドスケーパー',
        landscaperSub: '地形をデザインし美しい景色を造れます',
        undecided: 'ダミー',
        dontConvert: '変換しない',
        selectATool: 'ツール選択',
        didYouMean: 'もしかして？',
        result: '結果',
        palleteSwapDetailed: '調整メニューを表示',
        lowThreshold: '暗い部分を調整',
        highThreshold: '明るい部分を調整',
        alpha: 'アルファ',
        valueThreshold: '広さを調整',
        distanceThreshold: '奥行きを調整',
        generate1Image: '１枚のみ生成',
        generateXImages: '同時生成数：[0] 枚',
        dynamicThresholding: '緩和',
        cfgDelay: '多様性',
        cfgDelayTooltip:
            '生成画像の多様性と彩度を向上させるために、体型の決定後にガイダンスを有効にします。ただし、関連性が低くなる可能性があります。',
        enhance: '品質向上',
        variations: 'バリエーション',
        setAsInit: 'ベース画像利用',
        setAsInitImage: 'ベース画像として使用する',
        generateVariations: '別バージョンの画像を作成する',
        editImage: '画像を編集',
        undesiredContentPreset: '除外したい要素プリセット',
        maskDraw: 'マスク追加',
        maskErase: 'マスク消しゴム',
        saveAndClose: '保存して閉じる',
        removeInpaintingMask: '描いているマスクを消す',
        addInpaintingMask: '描いてマスクを追加する',
        clear: 'クリア',
        resetAllSettings: '全ての設定をデフォルトに戻しますか？',
        imageWithMetadataFound: '設定データを発見！',
        imageWithMetadataFoundSub: '画像、設定、プロンプトをインポートできます',
        importImage: '画像をインポート',
        importSettings: '設定をインポート',
        importSettingsPlusSeed: '設定＋シード値をインポート',
        toolInfluence: 'ツール影響度',
        upscale: '拡大',
        controlnetUndecidedError: '画像入力モードを選択するか、ベース画像を削除してください',
        dynamicThresholdingTooltip: '高プロンプト従属度で起こるノイズを減らします。',
        copyToClipboard: 'クリップボードにコピー',
        pinImage: '画像をピン留めする',
        downloadImage: '画像をダウンロード',
        copyToSeed: 'シード値をコピー',
        detatchUc: 'このタブを解除',
        reattachUc: 'このウィンドウをタブに戻す',
        promptChunkTooltip: 'プロンプト設定',
        resetSettings: '設定リセット',
        removePinnedImage: 'ピン留めした画像を外す',
        goToImage: '画像へ',
        old: '元画像',
        new: '新しい',
        imageSettings: '画像設定',
        editInpaintingMask: 'マスクを編集する',
        addOriginalImage: '元画像を加える',
        addOriginalImageChecked:
            '元のイメージが変更されるのを防ぎますが、マスクの縁に沿って継ぎ目が発生する可能性があります。',
        addOriginalImageUnchecked:
            'マスクの縁に出来る継ぎ目を防止しますが、元イメージがわずかに変わる可能性があります。',
        changeSize: 'サイズを変更',
        cropClosestValid: '有効生成サイズまでクロップ',
        shiftEdges: '端をシフト',
        resize: 'リサイズ',
        convert: '変換',
        regenerate: '再生成',
        imageTooLargeUpscale: '画像サイズが1024x1024より大きいです',
        compareWithOriginal: '元の画像と比較',
        convertUsingControlTool: 'コントロールツールを使用して変換',
        retryConversion: '変換を再試行',
        useOriginal: '元画像を使用',
        inpaintImage: '画像をインペイントする',
        inpainting: 'インペイント',
        inpaintingSub: '画像の一部を変更',
        mask: 'マスク',
        light: '軽い',
        heavy: '強い',
        negativePromptGuidance: '除外したい要素の強さ',
        negativePromptGuidanceTooltip: '100%以外の値を設定すると、生成時間とAnlasのコストが増加します。',
        image2imageNotSupported: '選択されたモデルでは Image2Image がサポートされていません。',
        controlToolsNotSupported: '選択されたモデルでは Control Tools がサポートされていません。',
        inpaintingNotSupported: '選択されたモデルではインペインティングがサポートされていません。',
        importPrompt: 'プロンプトをインポートする',
        qualityTagsEnabled: '品質タグ有効',
        introducingNaid2: 'NovelAI Diffusion 3.0をご紹介！',
        introducingNaid2Sub:
            '知識、制御性、一貫性、スタイルなど、あらゆる面で以前のモデルより何倍も優れています！',
        introducingNaid2Sub2: '',
        introducingNaid2SubSub:
            '自動的にこの新しいモデルに設定されましたが、お望みなら元に戻すこともできます。',
        ucPresetEnabled: '除外したい要素プリセットが有効です',
        advancedSettings: '詳細設定',
        cfgRescale: 'プロンプトガイダンスの再調整',
        or: '',
        randomize: 'ランダム化',
        randomPrompt: 'ランダム・プロンプト',
        noiseSchedule: 'ノイズ設定',
        legacyPromptBehaviour: '旧バージョン用のプロンプトのインポートが検出されました',
        legacyPromptBehaviour2:
            '画像を再現するために、古い動作を使用するようにフラグが設定されています。設定またはプロンプトを変更すると、このフラグはリセットされます。',
        addChristmas: 'クリスマスモード',
        heavyPlusDeAI: '強い＋AIっぽさ軽減',
        naidXLFullDesc: '',
        naidXLCuratedDesc: '',
        humanFocus: '人間に重点を置く',
        automaticDownload: '自動ダウンロード',
        automaticDownloadSubOn: '画像は生成後に自動ダウンロードされます',
        automaticDownloadSubOff: '画像は生成後に自動ダウンロードされません',
        imageTooLong: 'この画像は生成時間が長すぎます。',
        image: '画像',
        sameFeel: 'バイブストランスファー',
        sameFeelSub: '画像を変えてビジュアルを維持する。',
        samefeelInformationExtracted: '抽出情報',
        samefeelImageStrength: '参照強度',
        sameFeelUnsuppportedModel: 'このモデルではバイブストランスファーはサポートされていません。',
        imageImportTitle: 'この画像で何をしたいですか？',
        importImage2Image: 'image2image',
        importSameFeel: 'バイブストランスファー',
        importThisImageHasMetadata: 'この画像にはメタデータが含まれています！',
        importThisImageHasMetadataSub: '代わりにそちらをインポートしますか？',
        importMetadata: 'メタデータをインポート',
        importSeed: 'シードをインポート',
        sessionExpired: 'セッションが中断されました。再度ページを読み込んでください。',
        diffEditSub: 'ダミー',
        add: 'ダミー',
        subtract: 'ダミー',
        sameFeelInitImageError: 'ダミー',
        importFor: 'ダミー',
        sendToImageTools: 'ディレクターツールに送信',
        cannotCopyHistorySettings: 'この画像から履歴設定を複製できません。',
        imageTools: 'ディレクターツール',
        imageToolsSub: '豊富なAIツールで画像を編集する。',
        modelRemovedSoon: 'このモデルは近日中に削除されます。',
    },
    dashboard: {
        welcomeBack: 'ようこそ',
        createNewStory: '新しい物語を生成',
        importStory: '文章を読み込む',
        generateImages: '画像を生成',
        tts: '文章自動読み上げ',
        accountStatus: '現在のサブスクリプション',
        latestUpdate: '最近のアップデート情報',
        allUpdates: '過去のアップデート',
        recentStories: '最近の物語',
        lastEdited: '更新日:',
        imageGenerator: '画像ジェネレーター',
        imageGeneratorSub: '最先端の画像生成モデルで、アニメ、そしてケモノ系の画像を生成！',
        visualize: 'お気に入りのキャラクターをビジュアル化しよう。',
    },
    menubar: {
        accountSettings: 'アカウント設定',
        novelAIDiscord: 'NovelAI公式ディスコード',
        yourStories: '作った物語',
        filters: 'フィルター',
        display: '表示',
        compactView: 'コンパクト表示',
        showOnTop: '先頭に表示',
        shelves: '本棚',
        favorites: 'お気に入り',
        localStories: 'ダウンロードした物語',
        sortBy: '表示順',
        mostRecent: '最終更新日',
        alphabetical: 'アルファベット順',
        creationDate: '制作日',
        searchSub: '物語を検索',
        duplicateStoryTT: '物語をコピー',
        downloadStoryTT: '物語をダウンロード',
        deleteStoryTT: '物語を削除',
        editInfoTT: '編集する',
        usesEditorV2: 'エディターV2を使用する',
        newStory: '新しい物語',
        newShelf: '新規本棚',
        importFile: 'ファイルをインポート',
        searchShelf: 'この本棚を検索',
        trialGenerationsRemaining: '残りのテキスト生成回数は [0]/[1] です。',
        freeTrial: '無料お試し',
        trialTokenCount: 'あなたはAIコンテキストを[0]トークン得ています。我々の[1]に相当します。',
        trialTier: 'スクロール・ティア',
        trialSeenEnough: 'もう十分ですか？',
        trialPlansLink: 'プランをチェック',
        trialCreateAccount: 'アカウントを作成',
    },
    settings: {
        aiSettings: 'AI詳細設定',
        interface: '画面表示設定',
        theme: '色テーマ',
        account: 'アカウント',
        currentTier: '現在のティア',
        yourSubEnds: '現在のサブスクリプションは [0] まで有効です。',
        penName: 'ペンネーム',
        emailUnverified: '有効なEメールアドレスを入れてください。',
        deleteAccount: 'アカウント消去',
        notPossibleWhileSubscribedError: 'サブスクリプションを解除した後に有効です。',
        showAccountID: 'アカウントIDを表示',
        textToSpeech: '文章読み上げ',
        defaults: 'デフォルト設定',
        hotkeys: 'ショートカットキー設定',
        support: 'サポート',
        changeLog: 'アップデート情報',
        yourSubExpired: '[0] にサブスクリプションは解約されます',
        uiLanguage: 'UI言語設定',
        couldntSaveSettings: '設定を保存できませんでした： [0]',
        debugStoryNotFound: 'ストーリー一覧にストーリーが見つかりません。',
        debugStoryDeleted: 'ストーリーは削除されました。',
        debugStoryDeleteFailed: 'ストーリーの削除に失敗しました。',
        debugStoryDeleteFailedReason: 'ストーリーの削除に失敗しました：[0]',
        debugStoryDownloadFailed: 'ストーリーのダウンロードに失敗しました。',
        debugStoryDownloadFailedReason: 'ストーリーのダウンロードに失敗しました：[0]',
        debugStoryNotRemote: 'ストーリーはリモートではありません。',
        debugStoryCloneFailedReason: 'ストーリーのクローンに失敗しました： [0]',
        debugStoryCloned: 'ID [0] のストーリーがクローンされました。',
        debugHideSettings: 'デバッグ設定の非表示',
        debugStoryDownload: 'デバッグストーリーのダウンロード',
        debugStoryDownloadPlaceholder: 'ストーリーIDを入力し、「ダウンロード」をクリックしてください。',
        debugStoryDelete: 'デバッグストーリーの削除',
        debugStoryDeletePlaceholder:
            'ストーリーIDを入力し、「削除」をクリックしてください。元に戻すことはできません。',
        debugLogprobsCount: 'ログプローブ数',
        debugPreventModuleOverride: 'モジュールのオーバーライド防止',
        debugPreventModuleOverrideChecked: '選択されたモジュールは常に生成に使用されます。',
        debugPreventModuleOverrideUnchecked: '選択されたモジュールは特定の状況でオーバーライドされます。',
        debugTrimExcessWhitespace: '余分な余白を取り除く',
        debugTrimExcessWhitespaceChecked: '余分な空白はAIに送られる前に文脈から削除されます。',
        debugTrimExcessWhitespaceUnchecked:
            '余分な空白はAIに送られる前にコンテキストから削除されません。余分な空白は出力の質を著しく低下させることがあります。',
        debugTrimExcessWhitespaceNote: '余分な空白には、末尾の空白と重複した改行文字が含まれます。',
        debugIgnoreRemoteStoryConflicts: 'リモートストーリーの競合を無視',
        debugIgnoreRemoteStoryConflictsChecked:
            'リモートストレージ内の競合は無視され、ローカルでの変更は常にリモートでの保存より優先されます。',
        debugIgnoreRemoteStoryConflictsUnchecked: 'リモートストレージの競合を検出します。',
        debugRemoteStoryClone: 'リモートストーリーのクローンをデバッグ',
        debugRemoteStoryCloneSub: '同じIDで同じリモートストーリーを作成',
        debugRemoteStoryClonePlaceholder: 'ストーリーIDを入力し、「クローン」をクリックしてください。',
        debugClone: 'クローン',
        debugDownloadRawRemoteMeta: '生のリモートメタをダウンロード',
        debugDownloadRawRemoteContent: '生のリモートコンテンツをダウンロード',
        aiResponses: 'AIの応答',
        streamAiResponses: 'AIの応答をストリーミングする',
        streamAiResponsesChecked: 'AIの応答はストリーミングされ、トークンごとに表示されます。',
        streamAiResponsesUnchecked: '生成が終了すると、テキストが一度に表示されます。',
        streamedResponseDelay: 'ストリーミング応答の遅延',
        continueResponseToEndOfSentence: '文の終わりまで応答を続ける',
        continueResponseToEndOfSentenceChecked: '文の終わりが見つかるまで応答が続けられます。',
        continueResponseToEndOfSentenceUnchecked: '応答は正常に終了します',
        hypebot: 'HypeBot',
        hypebotCommentOutput: 'コメントの出力',
        hypebotSetCommentOff: 'コメント生成をオフにする',
        hypebotOff: 'オフ',
        hypebotSetCommentAuto: 'コメント生成をオンにする',
        hypebotAuto: '自動',
        hypebotSetCommentPermanent: 'コメント生成をオンにし、ダイアログを常に表示',
        hypebotPermanent: 'パーマネント設定',
        hybebotClearComments: 'コメントをクリア',
        hypebotClearCommentsChecked: 'ストーリーの文章生成の際、コメントを自動的に消去します。',
        hypebotClearCommentsUnchecked: 'ストーリーの文章生成の際、コメントを自動的に消去しません。',
        hybebotCommentChance: 'コメントの頻度',
        hypebotAvatar: 'コメントのアバター',
        hypebotSelectLabel: 'コメントのアバターを選択',
        experimental: '試用版',
        preamble: '前文',
        preambleChecked:
            '低コンテキストで生成を向上させられるように、コンテキストに短い前文が追加されます。実際の挙動はモデルにより異なります。',
        preambleUnchecked: 'コンテキストに前文は追加されません。',
        defaultBias: 'デフォルトバイアス',
        defaultBiasChecked:
            'デフォルトのバイアスが適用され、dinkus(***)とasterism(⁂) が生成される可能性が低減されます。',
        defaultBiasUnchecked: 'デフォルトバイアスは適用されません。',
        bidirectionalInlineGeneration: '双方向インライン生成',
        bidirectionalInlineGenerationChecked:
            'インライン生成には２つのポイント間でテキストを生成する特別なモデルが使用されます。',
        bidirectionalInlineGenerationUnchecked: 'インライン生成にはストーリーモデルが使用されます。',
        enableLogprobs: 'トークン確率の有効化',
        enableLogprobsChecked: {
            '0': '生成リクエストを行うと、応答時にトークン確率を返します。',
            '1': 'リトライの隣のボタンから確認することができます。',
        },
        enableLogprobsUnchecked: '生成リクエストを行った際、トークン確率は返されません。',
        editorLogprobs: 'トークン確率エディター',
        editorLogprobsChecked: 'トークン確率は最新の応答のエディターにも表示されます。',
        editorLogprobsUnchecked: 'トークン確率はエディターに表示されません。',
        maxContextTokens: '最大コンテキストサイズ',
        maxContextTokensNoLimit: '無制限',
        maxContextTokensNote:
            '注：実際の制限値はサブスクリプションのティアとモデルによって異なります。この設定は最大コンテキストサイズを人為的に制限するためのものです。',
        uiLanguageLabel: '言語を選択する',
        textSettings: 'テキスト設定',
        interfaceFontSize: 'インターフェースのフォントサイズ',
        lineSpacing: '行間',
        paragraphSpacing: '段落間',
        maxLineWidth: '行の最大幅',
        outputFontSize: '出力時のフォントサイズ',
        paragraphIndent: '段落のインデント',
        buttonScale: 'ボタンのサイズ',
        interactionSettings: 'インタラクション設定',
        gestureControls: 'ジェスチャー制御',
        gestureControlsChecked: 'タッチデバイス上でスワイプするとサイドバーが開閉します。',
        gestureControlsUnchecked: 'ジェスチャー制御は無効化されています。',
        swapContextMenuControls: 'コンテキストメニュー切り替え制御',
        swapContextMenuControlsChecked:
            '右クリックで標準のコンテキストメニューが開きます。Ctrl+右クリックでNAIコンテキストメニューが開きます。',
        swapContextMenuControlsUnchecked:
            '右クリックでNAIコンテキストメニューが開きます。Ctrl+右クリックで標準のコンテキストメニューが開きます。',
        otherSettings: 'その他の設定',
        inputBox: '入力ボックス',
        inputBoxChecked: '入力ボックスが表示されます。',
        inputBoxUnchecked: '入力ボックスが非表示になります。',
        outputSpellcheck: 'エディタースペルチェック',
        outputSpellcheckChecked: 'スペルチェックは（サポート中のブラウザの）エディターで有効です。',
        outputSpellcheckUnchecked: 'スペルチェックはエディターで無効化されています。',
        outputSpellcheckWarning:
            'お使いのブラウザのバージョンではスペルチェックのパフォーマンスに問題があります。スペルチェックが自動的に無効化されました。',
        editorLoreKeys: 'ロア―ブックキーエディター',
        editorLoreKeysChecked: '"現在アクティブなロア―ブックに入力されたキーはエディター内で太字になります。',
        editorLoreKeysUnchecked: 'エディター内のロア―ブックキーに特別な書式は適用されません。',
        showTips: 'ヒントを表示',
        showTipsChecked: 'ヒントはエディターの下に表示されます。',
        showTipsSessionDisabled: 'このセッションでは一時的にヒントが無効化されます。',
        showTipsUnchecked: 'ヒントは表示されません。',
        useEditorV2: '新しいストーリーにV2エディターを使用する',
        useEditorV2Checked: '新しいストーリーでは新しいドキュメントエディターが使用されます。',
        useEditorV2Info: {
            '0': '新しいドキュメントエディターは、パフォーマンスの向上、テキストの書式設定、モバイルコンテキストメニュー、および追加機能のためのセクションの動的ロードをサポートしています。',
            '1': 'ストーリーでは作成時のエディターが使用され続けます。',
            '2': '機能パリティに達したため、今後のある時点でV1は完全に置換されます。よってV2エディターの使用を推奨します。',
        },
        useEditorV2Unchecked: '新しいストーリーでは古いストーリーエディターが使用されます。',
        editorHighlighting: 'エディターのハイライト機能',
        editorHighlightingChecked: 'エディター内の文章はオリジナルに基づきハイライトされます。',
        editorHighlightingUnchecked: 'エディター内の文章はハイライトされません。',
        contextViewerColors: 'コンテキストビューアーの色',
        contextViewerColorsChecked: 'コンテキストビューアー内の文章はオリジナルに基づき色分けされます。',
        contextViewerColorsUnchecked: 'コンテキストビューアー内の文章はデフォルト色を使用します。',
        showStoryTitle: 'ストーリーのタイトル表示',
        showStoryTitleChecked: 'エディター上部にストーリーのタイトルが表示されます。',
        showStoryTitleUnchecked: 'エディター上部にストーリーのタイトルが表示されません。',
        showEditorToolbox: 'エディターツールボックスの表示',
        showEditorToolboxChecked:
            'V2エディター内で文章を選択すると、文章のフォーマット変更などのオプション付きのツールボックスが表示されます。',
        showEditorToolboxUnchecked:
            'V2エディター内で文章を選択しても、文章のフォーマット変更などのオプション付きのツールボックスが表示されません。',
        editorUnloadSectionsRange: 'パラグラフの可視性範囲',
        editorUnloadSectionsRangeSub:
            '指定された範囲外にある段落は、V2エディターに読み込まれません。この設定により特に長いストーリーで編集のパフォーマンスが向上します。',
        noUnloading: '読み込まれない部分はありません',
        highlightSpeech: '会話部分の強調',
        highlightSpeechDesc:
            'エディター内の会話部分（クオーテーションマーク間の文章）を斜体にし、少しだけ透明にして強調したい場合は選択。反転を選択した場合、代わりに会話ではない部分が斜体になり少し透明になります。',
        editorV2Only: 'V2エディターでのみ使用可能な機能です。',
        highlightSpeechOff: 'オフ',
        highlightSpeechOffLabel: '会話部分の強調を無効にする',
        highlightSpeechOn: '強調',
        highlightSpeechOnLabel: '会話部分を強調する',
        highlightSpeechInverted: '反転',
        highlightSpeechInvertedLabel: '会話以外の部分を強調する',
        showIdenticon: 'Identiconの表示',
        showIdenticonChecked: 'ユニークなIdenticonがアカウントに表示されます。',
        showIdenticonUnchecked: 'テーマ特有のデフォルトアバターがアカウントに表示されます。',
        useMinibar: 'ミニバーを表示（PCのみ）',
        useMinibarChecked: 'ミニバーは画面の左側に表示されます。',
        useMinibarUnchecked: 'ミニバーは表示されません。',
        invalidThemeFile: '無効なテーマファイルです。',
        importedTheme: 'テーマエディターにテーマがインポートされました。',
        saveTheme: 'テーマを適用して保存',
        importTheme: 'テーマファイルをインポート',
        exportTheme: 'テーマファイルをエクスポート',
        defaultThemes: 'デフォルトのテーマ',
        themeEditor: 'テーマエディター',
        downloadAllFailed: 'ダウンロードがすべて失敗しました。',
        persistentTokenFailed: '永続的トークンの取得に失敗しました。',
        accountId: 'アカウントID',
        getPersistentToken: '永続的APIトークンを取得',
        persistentToken: '永続的APIトークン',
        persistentTokenDesc: '以下はAPIのアクセスに使用される永続的トークンです。',
        persistentTokenShowFull: 'フル・トークンを表示',
        persistentTokenCopied: 'トークンをクリップボードにコピーしました。',
        persistentTokenNote:
            '注：一度に使用できる永続的トークンは1つのみ。新しいトークンを作成すると古い方は無効となります。このウィンドウを閉じると同じトークンを表示することはできません。',
        persistentTokenOverwrite: '永続的APIトークンを上書きします',
        persistentTokenOverwriteDesc:
            '既存の永続的APIトークンがあります。新規作成すると古い方は無効となります。',
        persistentTokenOverwriteConfirm: '本当に上書きしたいですか？',
        localStorageUnavailable: 'ローカルストレージは現在使用不可で機能しません。',
        downloadAllStories: 'すべてのストーリーをダウンロード',
        downloadingAllStories: 'すべてのストーリーをダウンロード中…',
        changeDetailsMustEnterExisting: '現在のメールアドレスとパスワードを入力してください。',
        changeDetailsMustEnterNew: '新しいメールアドレスまたは新しいパスワードを入力してください。',
        changeDetailsEmailFormat: 'メールアドレスはメールアドレスの形式で入力してください。',
        changeDetailsPasswordRequirements: 'パスワードは8文字以上で入力してください。',
        changeDetailsPasswordMatch: 'パスワードが一致しません。',
        changeDetailsFailed: '詳細の変更に失敗しました。',
        changeDetailsSuccessEmail: '詳細が変更されました。新しいメールアドレスに確認メールが送信されました。',
        changeDetailsSuccess: '詳細が変更されました。',
        changeDetailsIncorrectAccessKey: 'ユーザー名とパスワードの組み合わせが間違っています。',
        changeDetailsResentEmail: '確認メールを再送しました。',
        changeDetailsRefreshClaimActions:
            'メールを確認後、ページを更新して50回の無料テキスト生成を獲得してください。',
        changeDetailsAccountDeletionEmail: 'アカウント削除の確認メールを送信しました。',
        changeDetailsAccountDeletionFollow:
            'メールに記載されているリンクに従って、アカウントの削除を確認してください。',
        changeDetailsOldEmail: '旧メールアドレス',
        changeDetailsNewEmail: '新しいメールアドレス',
        changeDetailsPassword: 'パスワード',
        changeDetailsCurrentEmail: '現在のメールアドレス',
        changeDetailsCurrentPassword: '現在のパスワード',
        changeDetailsNewPassword: '新しいパスワード',
        changeDetailsConfirmNewPassword: '新しいパスワードの確認',
        changeDetailsEmail: 'メールアドレス',
        changeDetailsSendConfirmationEmail: '確認メールの送信',
        changeDetailsConfirmationEmailDesc: {
            '0': '以下にメールアドレスを入力すると、アカウント削除の確認メールが送信されます。',
            '1': 'アカウントを削除すると、ストーリーやその他のコンテンツが永久に失われますのでご注意ください。残しておきたいコンテンツについては必ず事前にエクスポートすることをお勧めします。アカウント設定の「すべてのストーリーをエクスポート」オプションを使ってエクスポートすることもできます。',
        },
        changeDetailsSendDeletionConfirmationEmail: '削除確認メールの送信',
        changeDetailsPenNameLabel: 'ペンネームの変更',
        changeDetailsEmailLabel: 'メールの変更',
        changeDetailsPasswordLabel: 'パスワードの変更',
        changeDetailsVerifyFreeActions:
            '50回の無料テキスト生成を獲得するにはメールアドレスを照合してください。',
        changeDetailsDeleteAccountLabel: 'アカウントの削除をリクエストする',
        ttsAreaDescription: 'この欄に入力されたテキストは、テスト/ダウンロードボタン使用時に適用されます。',
        ttsSource: '文章読み上げのソース',
        ttsSourceLocalDesc: 'ローカルTTSではユーザーのブラウザで有効な音声合成機能が使用されます。',
        ttsSourceStreamedDesc:
            'ストリーミングTTSには、より高品質でNovelAIがホストするモデルが使用されますので、有効なサブスクリプションが必要です。トライアルとして無料で100回の生成をお試しいただけます。',
        ttsLocalUnsupported: 'お使いのブラウザはローカルTTSをサポートしていません。',
        ttsStreamed: 'ストリーミング',
        ttsLocal: 'ローカル',
        ttsUsingLocal: 'TTSにブラウザの音声合成APIが使用されます。',
        ttsUsingStreamed: 'TTSにNovelAIのリモートTTSサービスが使用されます。',
        ttsNoMediaSource:
            'お使いのブラウザは MediaSource をサポートしていません。ストリーミングTTSは機能しますが、遅延が大きくなる可能性があります。',
        ttsNoMpeg:
            "お使いのブラウザは MediaSource タイプとして 'audio/mpeg' をサポートしていません。ストリーミングTTSは機能しますが、若干遅延が大きくなる可能性があります。",
        ttsOff: '文章読み上げ機能はオフになっています。',
        ttsSpeakOutputs: '出力の読み上げ',
        ttsSpeakOutputsChecked: '出力はTTSによって読み上げられます。',
        ttsSpeakOutputsUnchecked: '出力は読み上げられません。',
        ttsSpeakInputs: '入力の読み上げ',
        ttsSpeakInputsChecked: '出力に加えて、入力もTTSによって読み上げられます。',
        ttsSpeakInputsUnchecked: '入力は読み上げられません。',
        ttsSpeakInputsNoEffect: '出力の読み上げが無効化されている場合は、変わりません。',
        tssSpeakHypebot: 'HypeBotコメントの読み上げ',
        ttsSpeakHypebotChecked: 'HypeBotのコメントはTTSによって読み上げられます。',
        ttsSpeakHypebotUnchecked: 'HypeBotのコメントは読み上げられません。',
        ttsSpeakHypebotButDisabled: 'すでに、HypeBotは無効化されています。',
        ttsSpeakHypebotDisabled: 'HypeBotは無効化されています。',
        ttsLocalSettings: 'ローカルTTS設定',
        ttsSelectAVoice: 'ボイスを選択してください',
        ttsTesting: 'テスト中...',
        ttsTestVoice: 'テストボイス',
        ttsVolume: '音量',
        ttsSpeed: '速度',
        ttsPitch: 'ピッチ',
        ttsStreamedSettings: 'ストリーミングTTSの設定',
        ttsModel: 'モデル',
        ttsVoice: 'ボイス',
        ttsUnknownVoice: '未知のボイス',
        ttsVoiceSeedDesc: '指定されたシードに基づいたボイスを使用します。',
        ttsVoiceSeedLabel: 'ストリーミング音声読み上げシード',
        ttsVoiceSeedPlaceholder: 'ここにシードを入力してください',
        ttsRandomizeSeed: 'ランダム抽出',
        ttsRandomizeSeedLabel: 'ランダム抽出されたシード',
        ttsSeedNote:
            '注：一般的な名字から始めると、音程やイントネーションに影響を与えます。今後TTSの開発が進むにつれて、取得したどのシードのボイスも変更される可能性があります。',
        ttsStoryVoice: 'ストーリーのボイス',
        ttsHypebotVoice: 'Hypebotのボイス',
        ttsSavedVoices: '保存されたボイス',
        ttsSelectVoiceLabel: '編集するボイスを選択してください。',
        ttsNoSavedVoices: 'ボイスがありません。まず追加してください。',
        ttsUnnamedVoice: '無名のボイス',
        ttsName: 'ボイス名',
        ttsSeed: 'シード',
        ttsSaveVoice: 'ボイスを保存',
        ttsDeleteVoice: 'ボイスを削除',
        ttsDeleteVoiceButton: '削除します！',
        ttsDeleteAreYouSure: '本当にボイス"[0]"を削除しますか？',
        ttsDeleteCannotBeUndone: '元に戻せません。',
        ttsSpeedDownloadNote: '注：ダウンロードされた音声の速度には影響しません。',
        defaultStorageLocation: 'デフォルトの保管場所',
        defaultStorageLocationLocal: 'ローカル',
        defaultStorageLocationRemote: 'サーバー',
        defaultStorageLocationLocalDesc: '新規＆取り込まれたストーリーはローカルにのみ保存されます。',
        defaultStorageLocationRemoteDesc:
            '新規＆取り込まれたストーリーはローカルに保存され、リモートでは暗号化されて保管されます。',
        defaultStorageLocationBackupNote:
            'ブラウザのキャッシュがクリアされたり、アカウントへのアクセス権を失ったりした場合に備え、ストーリーのエクスポートとバックアップを強く推奨します。',
        defaultAIModel: 'デフォルトのAIモデル',
        defaultAIModelDesc: '新しいストーリーではこのモデルがデフォルトで使用されます。',
        defaultAIModelSelectLabel: 'AIモデルを選択',
        defaultPreset: 'デフォルトプリセット',
        defaultPresetDesc: '新しいストーリーでは選択したプリセットがデフォルトで使用されます。',
        defaultAIModule: 'デフォルトのAIモジュール',
        defaultAIModuleDesc: '新しいストーリーでは選択したモジュールがデフォルトで使用されます。',
        defaultAIModuleNotSupported: '選択されたAIモデルはモジュールに非対応です。',
        lorebookGenerationSettings: 'ロア―ブック生成設定',
        lorebookGenerationSettingsDesc:
            'ロア―ブックジェネレーターによって使用されたモデルと設定のプリセットを変更します。',
        lorebookGenerationModel: 'ロア―ブック生成モデル',
        lorebookGenerationModelSelectLabel: 'ロア―ブック生成に使用するAIモデルを選択',
        lorebookGenerationPreset: 'ロア―生成プリセット',
        lorebookGenerationLegacy: 'レガシーロアー生成',
        lorebookGenerationLegacyChecked: {
            '0': 'ロアージェネレーターに基づいた統一性の低いFew-shot プロンプトが使用されます。',
            '1': '選択したストーリーのモジュールは、選択したモデルで使用可能な場合、生成に使用されます。任意の生成タイプはサポートされていません。',
        },
        lorebookGenerationLegacyUnchecked: 'ロアージェネレーターには予約されたモジュールが使用されます。',
        giftKeys: 'ギフトキー',
        purchaseGiftKey: '新しいギフトキーを購入',
        giftKeyKey: 'キー',
        giftKeyDateCreated: '作成日',
        giftKeyTier: 'ティア',
        giftKeyStatus: '状態',
        noGiftKeys: 'ギフトキーがまだありません！',
        giftKeyCopiedToClipboard: 'ギフトキーがクリップボードにコピーされました。',
        giftKeyRedeemed: '引き換えられました',
        giftKeyAvailable: '有効です',
        confetti: '紙吹雪エフェクト',
        imageGenerationConfettiAnimation: '画像生成紙吹雪アニメーション',
        imageGenerationConfettiAnimationChecked: '画像生成中に紙吹雪が表示されます。',
        imageGenerationConfettiAnimationUnchecked: '画像生成中に紙吹雪が表示されません。',
        storiesConfettiAnimation: '物語ページ紙吹雪アニメーション',
        storiesConfettiAnimationChecked: '物語ページに紙吹雪が表示されます。',
        storiesConfettiAnimationUnchecked: '物語ページに紙吹雪が表示されません。',
        giftKeyDisabled: '現在、ギフトキーの購入はできません。',
    },
    subscription: {
        tierCards: {
            perMonth: '/月',
            titleMain: '使用可能なAI機能',
            titleExtra: 'その他特典',
            unlimitedText: '無限の可能性を秘めた文章生成',
            tokenMemory: '[0] トークン',
            tokenMemorySub: 'トークン数 ~[0] はAIが記憶できるおおよその文字数を示しています。',
            anlasSub: '画像生成のための通貨。毎月補充されます。',
            TTS: 'TTS生成（文章読み上げ機能）',
            TTSSub: '生成された音声読み上げボイスの心地よいサウンドで、ストーリーに命を吹き込みましょう。',
            imageGen: '画像生成機能',
            imageGenSub: '高度な画像生成モデルを試しましょう！',
            imageGenSubUnlimited: '普通と小サイズの画像生成をAnlasの消費なしで無限に楽しめます！**',
            accessBest: 'Kayra、最高のAIストーリーテラーへのアクセス',
            accessBestSub: 'その他全てのAIストーリーテラーにアクセスできます',
            freeGenerations: '無料テキスト生成',
            experimentalFeatures: '全ての試験的機能へのアクセス',
            experimentalFeaturesSub: '他の誰よりも早く最新でクールな機能にアクセスしましょう！',
            freeTTS: '100回分の無料音声合成',
            finePrintImage: '**画像生成では 1024x1024ピクセル、28ステップ、同時生成数一枚の時',
            accessLess: '当社の小規模ながら最速の概念実証AIストーリーテラーClioにアクセス',
            paperPrice: '無料',
            unlimited: '無制限',
            tokensOfMemory: '記憶文字数',
            tokensOfMemorySub: 'AIが記憶できる文字数（～4文字=1トークン）',
            nCharacters: '～ [0] 文字',
            nTokens: '[0] トークン',
            extraFeatures: 'その他の特典',
            advancedAI: '高度なAI',
            bestValue: 'ベストバリュー',
            forTheEnthusiast: 'スペシャリスト向け',
        },
        tierPricing: 'Tier段階ごとの価格',
        checkoutDisabled:
            '現在メンテナンス中の為、試行に失敗しました。しばらくしてからもう一度お願いします。',
        subLongerActivate:
            'サブスクリプションは既に購入済みです。アクティベートまで暫く時間をいただく場合があります。',
        alreadyPurchased: {
            '0': 'サブスクリプションは既に購入済みです。処理に時間がかかっています。',
            '1': '数分後にもう一度お試しください。',
            '2': '３０分ほど処理に時間をいただく場合がございます。また、アジア圏からのサブスクリプションのアクティベートには更に４時間ほど遅れが生じる事もございます、ご了承ください。問題の解決しない場合は、[0]　までご連絡ください.',
        },
        asiaPurchaseDelay:
            '注：アジア圏からのサブスクリプションの購入処理には４時間ほど遅れが生じる事もございます。ご了承ください。',
        paymentProcessing:
            'お支払いはこれで完了です。アカウント開設まで３０分程お待ちください。もし問題が解決しない場合は、support@novelai.net　までご連絡ください。',
        processingGiftKey: 'ギフトキーのアクティベートに時間がかかっています。',
        cannotUseSubGiftKeyUnlessGifted:
            'サブスクリプション用ギフトキーの使用には制限があります。現在のサブスクリプションがギフトキー由来の場合、新しいサブスクリプション用ギフトキーを使う事は出来ません。',
        cannotSubGiftKey:
            '現在既にサブスクリプションに契約中です。サブスクリプション用のギフトキーは使用できません。',
        upgradeProcessing:
            'サブスクリプションのアップグレード成功。あなたのサブスクリプションは [0] になりましたが、私達側の処理に時間がかかっています。少々お待ちください・・・',
        needAsubscription: 'このアクションにはサブスクリプションの購入が必要です',
        notSubscribed: '有効なサブスクリプションを持っていないようです！',
        upgradeSubscriptionQ: 'サブスクリプションをアップグレードしますか？',
        takeMeThere: '進む',
        noTakeMeBack: '戻る',
        youAreSubscribedTo: ' あなたのサブスクリプションは [0] です',
        yourSubscriptionRenews: 'あなたのサブスクリプションは [0] に更新されます.',
        yourSubscriptionEnds: 'あなたのサブスクリプションは [0] に終了し、更新されません',
        yourSubScriptionHighRisk:
            'あなたのサブスクリプションは現在、当社の決済業者によって高リスクに分類されています。これは解決まで1日程度かかる場合があります。',
        unsubscribe: 'サブスクリプション購入停止',
        upgradeSubscription: 'サブスクリプションの種類を変更',
        updatePaymentMethod: '支払方法を更新',
        replaceSubscription: 'サブスクリプション交換',
        activateGiftKey: 'ギフトキーをアクティベート',
        activate: 'アクティベート',
        giftKeySuccess: 'アクティベート成功!',
        giftKeySuccessSubscription: 'じゃーん！ [0] サブスクリプションがアクティベートされました！',
        giftKeySuccessAnlas: 'じゃーん！ [0] Anlasを受け取りました！',
        giftKeySuccessSubscriptionAndAnlas:
            'じゃじゃーん！ [0] サブスクリプションがアクティベートされ、 [1] Anlasを受け取りました',
        upgradeSubscriptionInfo:
            'サブスクリプションの交換によって新しいサブスクリプションの当月分の支払いが生じます。現在のサブスクリプションの残り日数に応じた割引が適応されます。',
        upgradeSubscriptionConfirm: 'サブスクリプションを [0] から [1] にアップグレードしてよろしいですか？',
        upgradeSubscriptionCharge: '今すぐ当月分 [0] USドルの支払いが生じます',
        upgradeConfirm: 'アップグレードします',
        replaceSubscriptionInfo:
            'サブスクリプションの交換によって新しいサブスクリプションの当月分の支払いが生じ、古いサブスクリプションは破棄されます。',
        giftKeyConfirmation: 'ギフトキー使用確認',
        giftKeySubscriptionAlreadySubbedConfirm: {
            '0': 'このギフトキーには([0], [1] )日分のサブスクリプションが含まれますが、お客様は既にサブスクリプションを契約中のようです',
            '1': 'このギフトキーに含まれる新しいサブスクリプションに交換しますか？',
        },
        giftKeySubcriptionAlreadySubbedAndAnlasConfirm: {
            '0': 'このギフトキーには([0], [1] )日分のサブスクリプションと([2]) Anlasが含まれますいますが、お客様は既にサブスクリプションを契約中のようです',
            '1': 'Anlasだけを取得しますか?',
            '2': 'Anlasのみ取得するか、新しいサブスクリプションに交換するかを選択できます',
        },
        redeemJustAnlas: 'ギフトキーでAnlasのみを取得',
        replaceMySubscription:
            '現在のサブスクリプションを終了し、このギフトキーに含まれる新しいサブスクリプションに交換しますか？',

        thankYouForCompletingTheSurvey: 'ご意見、ご感想をいただきありがとうございました！',
        submitSurvey: '提出する',
    },
    purchase: {
        mustBeLoggedIn: 'Anlasを購入するにはログインが必要です。',
        purchasedXAnlas: '[0]Anlasを購入しました！',
        purchasedNonSubAnlas:
            '購入成功！\n Anlasがお客様のアカウントに追加されるまで、最大30分以上かかる場合があります。予想以上に時間がかかる場合は、support@novelai.net までご連絡ください。',
        purchaseDescription1:
            'ここでは、AIモジュールのトレーニングや画像生成のための追加のAnlasを購入することができます。',
        purchaseDescription2: '定期購入のAnlasは、毎月定期購入に応じて補充されます。',
        yourSubscriptionAnlas: 'あなたの定期購入のAnlas:',
        yourPaidAnlas: 'あなたの有償Anlas:',
        anlasPerUSD: 'Anlas/ドル',
        purchase: '購入',
        doYouReallyWantTo: '本当に',
        purchaseTheFollowing: '以下の内容を購入しますか？',
        xAnlasForXUsd: '[0]Anlas を [1]ドルで購入',
        highRisk:
            'あなたの購読取引は、私たちの支払いプロバイダで高リスクとしてマークされています。 このステータスが解除されるまで、Anlasを購入することはできませんが、通常24時間以内に解除されます。',
        confirmPurchase: '購入を確定する',
        cancel: 'キャンセル',
    },
    landing: {
        typewriter: {
            castle: '湾の向こうの城',
            steam: '蒸気で動く都市',
            city: '自然に飲み込まれた廃虚',
            childhood: '子供の頃の思い出',
            fracture: '壊れた過去',
            mountains: '山々の旅',
            desert: '砂漠の中の王国',
            magic: '魔法の国',
            woods: '霧の森',
            moon: '月夜の呪い',
            war: '戦時国家',
            age: '人の世',
            case: '新たな事件',
            country: '静かな田舎暮らし',
            river: '川辺の散策',
            evening: '暗澹たる夕方',
            twisted: '歪んだ現実',
            coastline: '活気に満ちた海岸線',
            gardens: '庭園の散歩',
            inn: '暖かい宿',
            research: '忘れ去られた研究',
            insurrection: '武装蜂起',
            library: '魔法の図書館',
            dreamscape: '儚い夢物語',
            expedition: '未知への探検',
            ancient: '古代の預言',
            market: '賑やかな市場',
            sea: '穏やかな海の夜',
            crystal: '水晶洞窟の探検',
            lost: '道迷い',
            egg: '卵？',
            picnic: '湖畔でのピクニック',
            distant: '遥かな山々',
            hallway: '不吉な予感のする廊下',
            heroes: '英雄と悪者',
            temple: '隠された寺院',
            inspiring: '感動の物語',
            forgotten: '忘れ去られた場所',
            love: '愛情と欲望',
            otherworldly: '異世界の存在',
            storm: '荒海',
            rwar: '戦争の轟音',
            rural: '田舎社会',
            whale: 'スカイクジラの捕獲',
            strange: '奇妙な出来事',
            dawn: '新しい一日の始まり',
            alchemy: '錬金術の街',
            night: '真夜中',
            end: '時代の終わり',
            heaven: '天国の庭園',
            hell: '地獄の庭園',
            gods: '神々の家',
            court: '宮廷',
            menagerie: '宮殿のメナジェリー',
            underworld: '冥界への道',
            institute: '山頂の研究所',
            artist: '芸術家の苦悩',
            sun: '太陽の恵み',
            workshop: '人里離れた工房',
            beyond: '理解を超えた物事',
            frozen: '凍てつく大地の旅',
            romance: '思いがけないロマンス',
            depths: '見えざる深淵',
            victorian: 'ヴィクトリア時代',
            alien: '未知の場所',
            library2: '変わった図書館',
            torn: '張り裂けそうな世界',
        },
        title: 'NovelAI – AIストーリーテラー',
        blog: 'ブログ',
        discord: 'ディスコード',
        login: 'ログイン',
        writeAbout: 'について書いてみよう',
        description:
            'AI を活用して苦労せずにユニークなストーリーを構築したり、スリリングな物語を作ったり、魅惑的なロマンスを書いたり、ただふざけたりすることができます。検閲やガイドラインはありません。何でもありです！',
        learnMore: 'さらに詳しく',
        startForFree: '無料で書き始める',
        startForFreeNew: '無料で書き始める',
        whatIs: 'NovelAIとは？',
        intro1: 'NovelAIは、AIによる画像生成、ストーリーテリング、またはLLM（大規模言語モデル）を利用した想像力のサンドボックスのための月額制サービスです。',
        intro2: '当社の人工知能アルゴリズムはあなたの文章に基づいて人間が書くような文章を作成し、能力に関係なく誰でも質の高い文学作品を生み出すことを可能にします。当社の自然言語処理のプレイグラウンドでは実際の文献に基づいてトレーニングされた独自の AI モデルを使用し、前例のないレベルの自由を提供します。AIはあなたの視点とスタイルを維持しながら、インプットされた内容にシームレスに対応します。',
        toPricing: '価格設定へ',
        ourFeatures: '製品の特長',
        imgTitle: '画像生成でお気に入りのキャラクターをビジュアル化してみよう',
        imgSub: '強力なイメージモデルを使用し、最先端のアニメアートAIとその他のAIモデルであなたのストーリーの瞬間を描写します。',
        getStarted: '始める',
        adventure1:
            'あなたはぼんやりと、オーブの中に映るものを見つめ考えを巡らせる。それは世界中のランダムな画像だ。球体のなかを覗き込み、それらの画像が自分にとってどんな意味を持つのか熟考する。森、海、砂漠、山脈…　その意味について考えながら画像を眺め、数えきれないほどの時間を過ごす。',
        adventure2: '＞あなたはため息をつき、疲れた目をしばらくの間休ませる。「ひと休みしないと」',
        adventure3:
            'オーブの中の画像はその美しさに畏敬の念を抱かずにはいられないほど奇妙で異質だ。その色や形はあなたの魂に語りかけ、穏やかでさわやかな気分にさせてくれる。あなたの思考はさまよう。もっと深く覗くと、どんな不思議に出会えるんだろう？',
        whatWouldYouLikeToDo: 'あなたは何がしたいですか？',
        adventureTitle: 'ここからあなたの（テキストの）冒険を始めましょう',
        adventureSub:
            'オープンエンドがあまり好みではない場合、テキストアドベンチャーモジュールを有効にしましょう',
        adventureCta: '冒険を始める',
        customizeTitle: 'エディターをカスタマイズ',
        customizeSub:
            'テーマエディターでスペースを自分用にデザインしてください。フォント、サイズ、配色をいつでも自由に編集できます。',
        anywhereTitle: '作品をどこでも好きな場所で',
        anywhereSub: '家にいても外出先でも、いつでもNovelAIにアクセスして新しいものを書くことができます。',
        secureTitle: '作品を保護',
        secureSub:
            'あなたの作品を他の人が読むことはできません。当社のサーバーに保管されているすべての作品はXSalsa20により暗号化されています。',
        steerTitle: 'AI を新しい道へ誘導する',
        steerSub:
            'AIモジュールを使えば、特定のテーマを用いたり、有名な作家のスタイルを真似したり、独自のデータでAIを訓練することさえ可能です。',
        steerCta: '書き始める',
        useAiModules: 'AIモジュールで旅をさらに充実させよう',
        useAiModulesSub: 'モジュール化された豊富なツールを使って、作品を全く新しい方向へと導きます。',
        modules: 'AIモジュール',
        style: 'スタイル',
        styleSub: '好きな作家の真似をしましょう。',
        module1Title: 'テキストアドベンチャー',
        module1Sub: 'AIのダンジョンマスターとのスリリングな冒険を楽しみましょう。',
        module2Title: 'インストラクト',
        module2Sub: 'カスタマイズされたインストラクションにより、物語を正しい方向へ進めます。',
        module3Title: '文章補強機能',
        module3Sub: 'ディテールの質を上げ文章を補強することで最高のライティングを追求します。',
        theme: 'テーマ',
        themeSub: 'あなたの作品を特定のテーマに基づいて進めましょう。',
        module4Title: 'ダーク・ファンタジー',
        module5Title: 'ドラゴン',
        module6Title: '火星移住',
        expand: 'ストーリーの可能性を広げます',
        expandSub1: '当社のサービスは最大8192',
        expandSub2: 'トークン',
        expandSub3: 'のメモリを保持します。言うまでもなく、このAIは多くを記憶することができます。',
        tweak: 'AIの出力を微調整',
        tweakSub:
            '豊富なオプションで AI を制御しましょう。一貫性を保ってもいいですし、少しランダムにすることもできます。',
        legacies: '古い遺産を引き継ぐ',
        legaciesSub: 'AIDCATやAIDWIからインポートした作品をこの場所でよみがえらせましょう',
        lorebookTitle: '作品の世界観をコントロール',
        lorebookSub:
            'ロアーブックはあなたの作品の世界観をAIが逸脱することのないように、常に確認してくれる機能です。',
        lorebookCta: '世界を創造する',
        testimonialLead: 'ユーザーの声',
        testimonialTitle: 'ぜひご自分の目で確かめてみてください',
        testimonialTitleNew: 'ユーザーの声',
        beginYourAdventure: '冒険を始める',
        selectATier: 'プランを選択',
        selectATierAndBeginYourAdventure: 'ティアを選んで冒険を始めよう',
        getStartedForFree: '無料体験',
        fineprint:
            '*ユーザーの皆様は50回分の無料生成をお試しいただけます。アカウント作成をしていただいた時点で無料回数が50回まで補充されます。',
        termsOfService: '利用規約',
        contactUs: 'お問い合わせ',
        support: 'サポート',
        pressKit: 'プレスキット',
        updates: '更新情報',
        about: '会社概要',
        pricing: '価格',
        bringYourCharactersToLife: '登場人物に命を吹き込み、スリリングな物語や冒険を楽しもう',
        latestBlogPosts: '最新のブログ記事',
        testimonials: {
            0: {
                0: '「NovelAIは常に進化を続けている最高のツールです。',
                1: '書き手の創造性を刺激するライティング補助的なこともできるし、ユーザーが一緒に楽しめるゲームでもある、マルチツールのような存在です',
                2: '」',
                3: 'felixchowmusic様',
            },
            1: {
                0: '「',
                1: 'NovelAIのユーザーインターフェースと作者の自由度が気に入っています。',
                2: '好きなだけシステムをいじれる機能と、すぐに利用できる適切なプリセットが組み込まれていて、それだけでもお金を払う価値があります」',
                3: '匿名ユーザー様',
            },
            2: {
                0: '「素晴らしい。AIダンジョンの真の後継者です。',
                1: 'さまざまなオプション、特にモジュールによって物語を制御することができます',
                2: '」',
                3: '匿名ユーザー様',
            },
            3: {
                0: '「好きな点はたくさんあります。ニーズに応えてくれる開発者、プライバシー性、',
                1: '思いつくことを何でも書けるところ',
                2: '」',
                3: '匿名ユーザー様',
            },
            4: {
                0: {
                    0: '「良いグラフィックカードを持っていないため、',
                    1: 'AI Roguelite',
                    2: 'で画像を生成するのにすごく時間がかかっていました。',
                },
                1: 'NovelAIの画像は素晴らしく、そして生成も早いです。クレークと共にダンジョンを進むのは最高です！',
                2: '」',
                3: 'Panergicagony様',
            },
            5: {
                0: '「趣味で執筆をしているので、物語を書くためにAIを補助として使っています。アイデアが全く浮かばない時や実験したいことがある時ですね。',
                1: '私の創作の幅を広げてくれる本当に素晴らしいサービスです',
                2: '」',
                3: 'Otviss様',
            },
        },
    },
    inspect: {
        metadata: 'メタデータ',
        title: 'タイトル',
        description: '詳細',
        generationTime: '生成時間',
        software: 'ソフトウェア',
        source: 'ソース',
        parameters: 'パラメーター',
        simplified: '簡易版',
        rawParameters: '未加工のパラメーター',
        requestType: '要求タイプ',
        requestTypes: {
            '0': '未確認の要求タイプです',
            PromptGenerateRequest: 'Text to Image',
            Img2ImgRequest: 'Image to Image',
            NativeInfillingRequest: 'インペイント',
        },
        noMetadata: '当画像にメタデータはありません',
        instructions:
            '画像のメタデータを確認する場合は、アップロードボタンをクリックまたはウインドウ内に画像をドラッグしてください',
    },
    chatpage: {
        contact: 'コンタクト',
        tags: 'タグ',
        name: '名前',
        species: '（生物学上の）種',
        gender: '性別',
        chooseOrEnterCustom: '選択または直接入力',
        male: '男性',
        female: '女性',
        na: '該当なし',
        pronouns: '代名詞',
        heHim: '彼/彼の',
        sheHer: '彼女/彼女の',
        persona: '人物像',
        appearance: '外見',
        neutral: '普通',
        angry: '怒り',
        tired: '疲れ',
        aroused: '興奮',
        embarrassed: '恥ずかしい',
        love: '愛、好き',
        worried: '心配',
        determined: '決意、やる気',
        happy: '幸せ、楽しい',
        scared: '怖い',
        excited: 'ワクワク、興奮、楽しみ',
        thinking: '考える、疑問に思う',
        shy: 'もじもじする、はにかむ',
        smug: 'ドヤ顔、自慢、得意げ',
        laughing: '笑う',
        hurt: '傷つく(精神的/肉体的)',
        sad: '悲しい',
        surprised: '驚く',
        nervous: '緊張や不安',
        confused: '混乱',
        disgusted: '嫌悪',
        bored: '飽きる',
        irritated: 'イライラする',
        playful: 'ふざける、面白がる',
        addChat: 'チャットを追加',
        addExample: '見本を追加',
        addDeletionResponse: '引止め言葉を追加',
        addBrain: 'ブレインを追加',
        export: 'エクスポート',
        importChats: 'チャットをインポート',
        chatN: 'チャット[0]',
        exampleN: '見本[0]',
        brainN: 'ブレイン[0]',
        deleteN: '引止め[0]',
        user: 'ユーザー',
        nMessages: '[0]メッセージ',
        deletionResponse: '引止め言葉',
        exampleChat: '見本チャット',
        exampleIsGreeting: '見本は挨拶ですか？',
        chatName: 'チャット名（整理用）',
        chatTags: 'チャットタグ',
        style: 'スタイル',
        conversation: '会話',
        roleplay: 'ロールプレイ',
        intimacy: '親しさ',
        stranger: '初対面',
        acquaintance: '知り合い',
        close: '親しい',
        romantic: '恋愛',
        adultsOnly: '成人専用ですか？',
        adultNo: 'いいえ',
        adultYes: 'はい',
        tagsOfUser: 'ユーザーのタグ',
        nameOfUser: 'ユーザーの名前',
        speciesOfUser: 'ユーザーの種',
        genderOfUser: 'ユーザーの性別',
        personaOfUser: 'ユーザーの人物像',
        appearanceOfUser: 'ユーザーの外見',
        pronounsUsedForUser: 'ユーザーの代名詞',
        scene: 'シーン',
        environment: '環境',
        summary: 'サマリー',
        deleteChat: 'チャットを削除',
        createNew: '新規作成',
        or: 'または',
        chooseFile: 'ファイルの選択',
        noFileChosen: 'ファイルが選択されていません',
        importWithUserAndContactReversed: 'ユーザーとコンタクトを入れ替えますか？',
        intro1: 'このページの使用方法',
        intro2: '編集ページは同じキャラクターのチャットグループを中心に構成されています。左側ではキャラクターの情報を設定でき、右側では、チャットの情報を設定できます。右側の、チャットの情報を設定できる場所は、チャットが選択されるまで開けません。',
        intro3: '上部のタブでチャットを切り替えることができます。プラスのアイコンをクリックすると新規作成できます。いずれかを実行すると今ご覧の説明ページは消えます。その横のボタンを押すと現在のチャットが jsonファイル形式でエクスポートされます。先ほどの画面で「新規作成」をクリックする代わりにそのファイルを選択し、インポートすることができます。自動保存機能はありませんので定期的に編集内容を保存するようにしてください。ダウンロードされたファイルのタイムスタンプを使えば常に最新のファイルを把握することができます。',
        intro4: 'チャットを選択すると、下に一対の入力欄が表示されます。右側がユーザー用、左側がコンタクト用です。タブキーを使ってユーザーとコンタクトを切り替えることができます。',
        intro5: 'エンターキーを押すとメッセージが送信されます。Shift + Enterで改行されます。',
        intro6: 'コンタクト用のメッセージ入力欄に付いている絵文字を変更すると、入力した文章と一緒に送られる絵文字を変えることができます。その横にある鍵のアイコンを使うと、メッセージを生成する度に感情を選ばなくても、新規メッセージに自動で同じ感情の絵文字が付きます。',
        intro7: 'メッセージを送信した後は、クリックすると編集可能になります。絵文字をクリックして、メッセージに付随する絵文字を変更することもできます。',
        intro8: '代名詞の欄は、必要に応じてカスタマイズされた代名詞を入力していただけます。',
        intro9: 'ユーザーのメッセージ入力欄の上部の名前をクリックするとユーザー名の変更が可能です。編集できないように見えますが、実はできます。',
        chatMetadata: 'チャットメタデータ',
        nameTitle: '名前/タイトル',
        noName: 'no name',
        deleteChatQuestion: 'チャットを削除しますか？',
        deleteChatAreYouSure: '本当にこのチャットを削除したいですか？',
        format1Text: '通常の会話文',
        format1Format: 'このように入力',
        format2Text: 'ナレーションおよびアクション',
        format2Format: '（このように入力）または(このように入力)',
        format3Text: '心の声、思考',
        format3Format: '【このように入力】',
        format4Text: 'テレパシー',
        format4Format: '＜このように入力＞または<このように入力>',
        format5Text: '前後の文脈と関係のない文章',
        format5Format: '((OOC: このように入力))',
        format6Text: '太字',
        format6Format: '**このように入力**',
        format7Text: '斜体の文字',
        format7Format: '_このように入力_',
        format8Text: '斜体の太字',
        format8Format: '**_このように入力_**',
        format9Text: '引用文',
        format9Format: '「このように入力」「このように入力」',
        format10Text: '翻訳文',
        format10Format: 'comme ça [TL: このように入力]',
        format11Text: '共有されたファイル',
        format11Format: '[記録媒体: 内容をこのように入力]',
        enterBrainContentHere: 'ブレインエントリーの内容をここに入力',
        actionChar: '(（',
        changedMyMind: '考え直す',
    },
    confetti: {
        confettiPopupEnableButton: 'お祝いを始めよう！',
        confettiPopupOptOutButton: 'オプトアウト：パーティーは苦手。遠慮します！',
        confettiPopupNote:
            'みんなで楽しみたい？パーティーに参加したい場合は、「テーマ」でお祝いのテーマに切り替えてください。',
        confettiPerfWarning: {
            0: '一部のデバイスではアニメーションの適用でパフォーマンスが低下する場合がありますが、色テーマ設定からいつでも無効化できます。',
            1: '',
            2: '',
        },
    },
    contest: {
        title: 'タイトル：',
        titleSub: '作品の題名',
        textFieldOptional: 'テキストフィールド：（任意）',
        textField: 'テキストフィールド：',
        textFieldSub: '応募作品の簡単な説明や背景の情報をご記入ください。',
        email: 'メールアドレス：',
        emailSub: '賞品送付用のメールアドレスをご記入ください。',
        name: '名前：',
        nameSub: 'アーティスト名をご記入ください。',
        submit: '提出',
        currentSubmission: '現在の応募作品',
        deleteSubmission: '応募作品を削除',
        loginRequired: {
            '0': '応募には',
            '1': '登録とログイン',
            '2': 'が必要です。',
        },
        supportedFileTypes: 'サポート形式：.png',
        selectFile: 'ファイルを選択',
        backToDetails: '詳細に戻る',
        imageContestSubmission: '画像コンテスト応募作品',
        textContestSubmission: 'テキストコンテスト応募作品',
        submissionSuccess: '応募が完了しました！',
        submitToContest: 'コンテストに応募する',
        copiedPromptToClipboard: 'プロンプトがクリップボードにコピーされました。',
        copiedUCToClipboard: '除外したい要素がクリップボードにコピーされました。',
        saveImage: '画像を保存',
        recreate: '再現',
        submissionGallery: '応募作品ギャラリー',
        pleaseVote: '好きな作品全てに投票しよう！',
        mustBeLoggedInToVote: '投票には登録とログインが必要です。',
        uploadFailedNoMetadata: 'アップロード失敗：メタデータのない画像です。',
        uploadFailed: 'アップロード失敗：[0]',
        invalidFile: '無効なファイル',
        uploadFailedDuplicate: 'アップロード失敗：重複した応募が検出されました。',
        fillMandatoryFields: '必須項目は必ずご記入ください。',
        theWinners: '入賞者',
        firstPlace: '1位',
        secondPlace: '2位',
        thirdPlace: '3位',
        fourthPlace: '4位',
        fifthPlace: '5位',
        sixthPlace: '6位',
        seventhPlace: '7位',
        eighthPlace: '8位',
        ninthPlace: '9位',
        tenthPlace: '10位',
        nthPlace: '[0]位',
        developersChoice: '開発者特別賞',
        comedyChoicePrize: 'コメディー賞',
        submissionTitle: '作品名',
        vote: '投票',
        remove: '削除',
        submissionCloses: '応募作品の提出期間終了：2024年6月25日(火) 午後2:00',
        days: '日',
        day: '日',
        hours: '時間',
        hour: '時間',
        minutes: '分',
        minute: '分',
        seconds: '秒',
        second: '秒',
        countdownSeparator: '',
        votingClosed: '提出は締め切られました。まもなく投票が始まります。',
        againstRules: '誠に残念ですが、テーマやルールに沿っていない作品は除外させていただきました。',
        imageContest2024Theme: '幸運の数字「3」',
        textContest2024Theme: 'Three Years of Imagined Worlds: Memories',
        votingCloses: '投票終了日時：',
        votingClosesTime: '2024年6月29日(土) 午後3:59',
        seeResultsNow: '今すぐ結果を確認する',
        congratulationsPrizesDistributedShortly:
            '受賞者の皆様、おめでとうございます！賞品はまもなく配布されます。 NovelAIチームより御礼申し上げます！',
        yourSubmission: 'あなたの応募作品',
        byX: '作者：[0]',
        description: '説明',
        errorLoadingImages: '画像の読み込みエラー',
        approve: '承認',
        unapprove: '否認',
        displayedEntries: '応募作品の表示数',
    },
    links: {
        title: 'リンク - NovelAI',
        novelai: 'NovelAI',
        blog: 'ブログ',
        tutorialsAndGuides: 'チュートリアル＆ガイド',
        discord: 'Discord',
        twitter: 'X（旧 Twitter）',
        reddit: 'Reddit',
        youtube: 'YouTube',
        aetherroom: 'AetherRoom',
        anlatan: 'Anlatan',
    },
    ban: {
        full_banned: {
            0: 'お客様のアカウントは不審な支払い行為により停止されています。',
            1: '利用規約をご確認ください。https://novelai.net/terms',
            2: 'アカウントへのアクセスは完全に制限されています。',
        },
        banned: {
            0: 'お客様のアカウントは不審な支払い行為により停止されています。',
            1: '利用規約をご確認ください。https://novelai.net/terms',
            2: '今後、テキストや画像の生成、新規サブスクリプションの購入、アンラスの購入はできなくなります。',
            3: '既に生成したストーリーがありましたら、[ユーザー設定] > [アカウント] > [すべてのストーリーをダウンロード] の手順でダウンロードしてください。',
        },
    },
    newsletter: {
        novelaiNewsletter: 'NovelAI ニュースレター',
        stayInformed: '当社の最新情報をお届けします。',
        subscribeToOurNewsletter: 'ニュースレターを登録',
        toSubscribeLater:
            '後で登録したり、Eメールの設定を管理するには、[0] 設定 > アカウントをご利用ください。',
        enterYourEmail: 'メールアドレスを入力してください。',
        youreSignedUp: 'ニュースレターに登録されました。',
        youllRecieve: '新しいアップデートやその他のニュースが発表されると、当社からメールが届きます。',
    },
    imageTools: {
        tools: {
            'bg-removal': '背景の除去',
            declutter: 'デクラッター',
            'declutter-keep-bubbles': 'デクラッター（吹き出しを残す）',
            lineart: '線画',
            sketch: 'スケッチ',
            colorize: 'カラー化',
            emotion: '感情',
            upscale: 'アップスケール',
        },
        toolTooltip: {
            'bg-removal': '画像から背景を削除し、キャラクターだけを残します。',
            declutter:
                '画像の上に描かれたテキストや吹き出しなどの「クラッター（雑然としたもの）」を取り除く機能です。',
            'declutter-keep-bubbles': 'ダミー',
            lineart: '画像の輪郭を描きます。',
            sketch: '画像をスケッチの段階に戻します。',
            colorize:
                'スケッチや線画の絵をカラフルに着色しましょう。カラー化の際、プロンプトを使ってガイドしたり、画像を少し修正することもできます。',
            emotion: '任意のキャラクターの表情を変更します。',
            upscale: 'ダミー',
        },
        prompt: 'プロンプト（オプション）',
        defry: '色補正',
        transform: '変換',
        masked: 'マスク',
        generated: '生成',
        blend: 'ブレンド',
        keepBubbles: '文字の吹き出しを残す',
        tooLarge: '選択された画像が大きすぎます。最高解像度は 1536 x 2048 ピクセルまで。',
        tooLargeForUpscale:
            '選択された画像は大きすぎてアップスケールできません。最大解像度は1024 x 1024 ピクセルまで。',
        emotionStrength: '感情の強度',
        emotionStrengths: {
            0: 'ニュートラル',
            1: '少し弱い',
            2: '弱い',
            3: 'まあまあ弱い',
            4: 'とても弱い',
            5: '一番弱い',
        },
        emotion: '感情',
        animeOnly: 'アニメのみ。ニュートラルの感情の画像から開始してください。',
    },
    models: {
        erato: {
            name: 'Llama 3 Erato',
            description: 'Meta Llama 3 70Bで構築した当社最大かつ最強のモデル',
        },
        kayra: {
            name: 'Kayra',
            description: '一から作り上げたモデルで、現在2位の能力をもつ勇ましい挑戦者',
        },
    },
}

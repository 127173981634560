export const nerdstashUnitrim = {
    '203': -1,
    '204': -1,
    '205': -1,
    '206': -1,
    '207': -1,
    '208': -1,
    '209': -1,
    '210': -1,
    '211': -1,
    '212': -1,
    '213': -1,
    '214': -1,
    '215': -1,
    '216': -1,
    '217': -1,
    '218': -1,
    '219': -1,
    '220': -1,
    '221': -1,
    '222': -1,
    '223': -1,
    '224': -1,
    '225': -1,
    '226': -1,
    '227': -1,
    '228': -1,
    '229': -1,
    '230': -1,
    '231': -1,
    '232': -1,
    '233': -1,
    '234': -1,
    '235': -1,
    '236': -1,
    '237': -1,
    '238': -1,
    '239': -1,
    '240': -1,
    '241': -1,
    '242': -1,
    '243': -1,
    '244': -1,
    '245': -1,
    '246': -1,
    '247': -1,
    '248': -1,
    '249': -1,
    '250': -1,
    '251': -1,
    '252': -1,
    '253': -1,
    '254': -1,
    '255': -1,
    '256': -1,
    '257': -1,
    '258': -1,
    '259': -1,
    '260': -1,
    '261': -1,
    '262': -1,
    '263': -1,
    '264': -1,
    '265': -1,
    '266': -1,
    '267': 1,
    '268': 1,
    '269': 1,
    '270': 1,
    '271': 1,
    '272': 1,
    '273': 1,
    '274': 1,
    '275': 1,
    '276': 1,
    '277': 1,
    '278': 1,
    '279': 1,
    '280': 1,
    '281': 1,
    '282': 1,
    '283': 1,
    '284': 1,
    '285': 1,
    '286': 1,
    '287': 1,
    '288': 1,
    '289': 1,
    '290': 1,
    '291': 1,
    '292': 1,
    '293': 1,
    '294': 1,
    '295': 1,
    '296': 1,
    '297': 1,
    '298': 1,
    '299': 2,
    '300': 2,
    '301': 2,
    '302': 2,
    '303': 2,
    '304': 2,
    '305': 2,
    '306': 2,
    '307': 2,
    '308': 2,
    '309': 2,
    '310': 2,
    '311': 2,
    '312': 2,
    '313': 2,
    '314': 2,
    '315': 3,
    '316': 3,
    '317': 3,
    '318': 3,
    '319': 3,
    '320': 3,
    '321': 3,
    '322': 3,
}

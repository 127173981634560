export const llama3Unitrim = {
    '94': -1,
    '95': -1,
    '96': -1,
    '97': -1,
    '98': -1,
    '99': -1,
    '100': -1,
    '101': -1,
    '102': -1,
    '103': -1,
    '104': -1,
    '105': -1,
    '106': -1,
    '107': -1,
    '108': -1,
    '109': -1,
    '110': -1,
    '111': -1,
    '112': -1,
    '113': -1,
    '114': -1,
    '115': -1,
    '116': -1,
    '117': -1,
    '118': -1,
    '119': -1,
    '120': -1,
    '121': -1,
    '122': -1,
    '123': -1,
    '124': 1,
    '125': 1,
    '126': 1,
    '127': 1,
    '128': 1,
    '129': 1,
    '130': 1,
    '131': 1,
    '132': 1,
    '133': 1,
    '134': 1,
    '135': 1,
    '136': 1,
    '137': 1,
    '138': 1,
    '139': 1,
    '140': 1,
    '141': 1,
    '142': 1,
    '143': 1,
    '144': 1,
    '145': 1,
    '146': 1,
    '147': 1,
    '148': 1,
    '149': 1,
    '150': 1,
    '151': 1,
    '152': 1,
    '153': 1,
    '154': 1,
    '155': 1,
    '156': 2,
    '157': 2,
    '158': 2,
    '159': 2,
    '160': 2,
    '161': 2,
    '162': 2,
    '163': 2,
    '164': 2,
    '165': 2,
    '166': 2,
    '167': 2,
    '168': 2,
    '169': 2,
    '170': 2,
    '171': 2,
    '172': 3,
    '173': 3,
    '174': 3,
    '175': 3,
    '176': 3,
    '177': 3,
    '178': 3,
    '179': 3,
    '222': -1,
    '223': -1,
    '224': -1,
    '225': -1,
    '226': -1,
    '227': -1,
    '228': -1,
    '229': -1,
    '230': -1,
    '231': -1,
    '232': -1,
    '233': -1,
    '234': -1,
    '235': -1,
    '236': -1,
    '237': -1,
    '238': -1,
    '239': -1,
    '240': -1,
    '241': -1,
    '242': -1,
    '243': -1,
    '244': -1,
    '245': -1,
    '246': -1,
    '247': -1,
    '248': -1,
    '249': -1,
    '250': -1,
    '251': -1,
    '252': -1,
    '253': -1,
    '254': -1,
    '255': -1,
    '378': 1,
    '639': 1,
    '1300': 1,
    '1301': 1,
    '1569': 1,
    '1717': 1,
    '2188': 1,
    '2243': 1,
    '2275': 1,
    '2845': 1,
    '2928': 2,
    '3098': 1,
    '3299': -2,
    '3396': 2,
    '3484': 1,
    '3574': 1,
    '3732': 1,
    '4268': 1,
    '4916': 1,
    '4996': 2,
    '5080': 1,
    '5251': 2,
    '5619': 1,
    '5681': 1,
    '5877': 1,
    '6026': 1,
    '6079': 1,
    '6271': 1,
    '6655': 1,
    '6701': 1,
    '6704': 2,
    '6708': 1,
    '6744': 1,
    '6823': 1,
    '7190': 1,
    '7305': 1,
    '7459': 1,
    '7518': 1,
    '7644': -2,
    '7688': 1,
    '7741': 1,
    '8008': 1,
    '8067': 1,
    '8192': 1,
    '8239': 1,
    '8321': 1,
    '8676': 1,
    '8687': 1,
    '8790': 2,
    '8979': 1,
    '9015': 1,
    '9019': 1,
    '9085': 2,
    '9212': 1,
    '9444': 1,
    '9468': 2,
    '9921': 1,
    '10044': 1,
    '10287': 1,
    '10414': 1,
    '10447': 2,
    '10634': 1,
    '10890': 1,
    '10997': 2,
    '11001': 1,
    '11239': 1,
    '11372': 1,
    '11410': 2,
    '11589': 1,
    '11743': 1,
    '11795': 1,
    '11881': 1,
    '11882': 1,
    '12264': 1,
    '12554': 1,
    '12774': 1,
    '12870': 1,
    '12906': 1,
    '13079': 1,
    '13357': 1,
    '13393': 1,
    '13467': 1,
    '13647': 1,
    '13773': 1,
    '13821': 1,
    '13828': 1,
    '13841': 1,
    '13870': 1,
    '13879': 1,
    '14167': 1,
    '14191': 1,
    '14276': 1,
    '14309': 1,
    '14608': 1,
    '14705': 1,
    '14806': 1,
    '14860': 1,
    '14888': 1,
    '14901': 1,
    '15017': 1,
    '15269': 1,
    '15272': 1,
    '15284': 1,
    '15291': 1,
    '15308': 1,
    '15355': 1,
    '15478': 1,
    '15568': 1,
    '15581': 1,
    '15722': 1,
    '15755': 1,
    '15973': 1,
    '16050': -2,
    '16175': 1,
    '16205': 1,
    '16275': 1,
    '16281': -2,
    '16306': 1,
    '16555': 1,
    '16633': 1,
    '16906': 1,
    '17044': 1,
    '17164': 1,
    '17169': 1,
    '17196': 1,
    '17245': 1,
    '17486': 1,
    '17597': 1,
    '17599': 1,
    '17732': 1,
    '17839': 1,
    '17857': 1,
    '17885': 1,
    '17920': 1,
    '17925': 1,
    '18049': 1,
    '18107': 1,
    '18202': 1,
    '18259': 1,
    '18550': 1,
    '18630': 2,
    '19012': 1,
    '19044': -2,
    '19097': 1,
    '19630': 1,
    '19658': 1,
    '19817': 1,
    '20022': 1,
    '20033': 1,
    '20119': 1,
    '20135': 1,
    '20321': 1,
    '20541': -1,
    '20551': 1,
    '20627': 1,
    '20648': 1,
    '20701': 1,
    '20740': 1,
    '21007': 1,
    '21105': 1,
    '21370': 1,
    '21403': 1,
    '21441': 1,
    '21549': 1,
    '21555': 1,
    '21589': 1,
    '21688': 1,
    '21784': 1,
    '21819': 1,
    '21980': 1,
    '22254': 1,
    '22289': 1,
    '22447': 1,
    '22605': 1,
    '22783': 1,
    '22817': 1,
    '22914': -2,
    '23043': 1,
    '23419': -2,
    '23436': 1,
    '23602': 1,
    '23706': 1,
    '23964': 1,
    '24153': -2,
    '24326': 1,
    '24339': -2,
    '24615': 1,
    '24715': -2,
    '24814': 1,
    '24839': 1,
    '25005': 1,
    '25010': -1,
    '25038': 1,
    '25132': 1,
    '25190': 1,
    '25340': 1,
    '25451': -2,
    '25493': 1,
    '25766': 1,
    '25781': 1,
    '25870': 1,
    '25906': 1,
    '26062': -1,
    '26182': 1,
    '26203': 1,
    '26274': 1,
    '26530': -2,
    '26602': 1,
    '26787': 1,
    '26856': 1,
    '26955': 1,
    '27006': 1,
    '27017': 1,
    '27280': 1,
    '27350': -2,
    '27433': 1,
    '27552': 1,
    '27623': 1,
    '27699': 1,
    '27869': 1,
    '27951': 1,
    '28025': 1,
    '28194': 1,
    '28308': 1,
    '28313': 1,
    '28359': -1,
    '28425': 1,
    '28498': 1,
    '28587': -2,
    '28617': 1,
    '28638': 1,
    '28740': 1,
    '28857': -2,
    '28867': 1,
    '29082': 2,
    '29099': 1,
    '29207': 1,
    '29245': 1,
    '29753': 1,
    '29857': 1,
    '30027': 1,
    '30250': 1,
    '30320': 1,
    '30337': 1,
    '30489': -2,
    '30775': 1,
    '30868': 1,
    '31041': -1,
    '31055': 1,
    '31075': 1,
    '31106': 1,
    '31374': 1,
    '31495': 1,
    '31584': 1,
    '31620': 1,
    '31928': -2,
    '31938': 1,
    '32016': 1,
    '32386': 1,
    '32405': 1,
    '32438': -1,
    '32511': 1,
    '32582': 1,
    '32601': 1,
    '33281': 1,
    '33308': 1,
    '33354': 1,
    '33443': 1,
    '33565': 1,
    '33595': 2,
    '33943': 1,
    '34026': -2,
    '34085': 1,
    '34273': 1,
    '34452': 1,
    '34524': 1,
    '34643': -2,
    '34656': 1,
    '34693': 1,
    '34839': 1,
    '35086': 1,
    '35243': 1,
    '35469': 1,
    '35510': 1,
    '35529': -2,
    '35677': 1,
    '35722': 1,
    '35729': 1,
    '35859': 1,
    '35911': -2,
    '36092': 1,
    '36117': 1,
    '36149': 1,
    '36155': 1,
    '36225': -2,
    '36246': -2,
    '36278': 1,
    '36393': 1,
    '36458': 1,
    '36596': 1,
    '36735': 1,
    '36820': -1,
    '37197': -2,
    '37209': -2,
    '37430': 1,
    '37648': -2,
    '37769': 1,
    '37777': -2,
    '37795': 1,
    '38078': 1,
    '38164': 1,
    '38248': 1,
    '38295': 1,
    '38389': 1,
    '38540': -2,
    '38572': 1,
    '38609': -1,
    '38798': 1,
    '39013': 1,
    '39277': 1,
    '39519': 1,
    '39533': 1,
    '39622': 1,
    '39623': 1,
    '40003': 1,
    '40011': 1,
    '40198': 1,
    '40275': 1,
    '40417': 1,
    '40466': -2,
    '40934': -2,
    '41519': -2,
    '41766': 1,
    '41814': 1,
    '41832': 1,
    '41847': 1,
    '41871': 1,
    '42579': 1,
    '43002': -2,
    '43244': 1,
    '43336': 1,
    '43411': 1,
    '43576': 1,
    '43949': 1,
    '44510': 1,
    '44559': 1,
    '44647': 1,
    '44659': 1,
    '44714': 1,
    '44816': -1,
    '44820': -1,
    '44852': 1,
    '45114': 1,
    '45144': 1,
    '45154': 1,
    '45204': -2,
    '45358': 1,
    '45501': -2,
    '45780': 2,
    '45806': -2,
    '45826': 1,
    '45918': 1,
    '45932': 1,
    '45934': -1,
    '45951': 1,
    '46065': 1,
    '46204': 1,
    '46230': 1,
    '46413': 1,
    '46981': -2,
    '47118': 1,
    '47318': -2,
    '47382': -2,
    '47453': 1,
    '47600': 1,
    '47702': 1,
    '47850': 1,
    '47900': 1,
    '47932': 1,
    '47971': -1,
    '48478': -2,
    '48479': -2,
    '48555': 1,
    '48765': 1,
    '49011': 1,
    '49072': 1,
    '49208': 1,
    '49412': 1,
    '49464': 1,
    '49558': -1,
    '49633': 1,
    '49691': 1,
    '49849': 1,
    '49854': 1,
    '49900': -2,
    '49940': 1,
    '50228': 1,
    '50266': 1,
    '50273': 1,
    '50520': 1,
    '50534': 1,
    '50702': 1,
    '51043': 1,
    '51202': 1,
    '51211': 1,
    '51462': -2,
    '52188': -1,
    '52375': -2,
    '52514': 1,
    '52561': 1,
    '52597': 1,
    '53233': 1,
    '53508': 1,
    '53606': 1,
    '53898': 1,
    '53906': 1,
    '53987': 1,
    '54125': 1,
    '54596': 1,
    '54597': 1,
    '54656': 1,
    '54699': 1,
    '55089': 1,
    '55228': 1,
    '55570': -1,
    '55592': -2,
    '55699': 1,
    '55742': 1,
    '55799': 1,
    '55884': 1,
    '56055': 1,
    '56159': 1,
    '56829': 1,
    '56938': 1,
    '56990': 1,
    '57352': 2,
    '57923': -2,
    '57942': 1,
    '58233': -1,
    '58254': 1,
    '58260': 2,
    '58699': 1,
    '58843': 1,
    '59198': -2,
    '59269': 1,
    '59308': 1,
    '59330': 1,
    '59399': 1,
    '59564': 1,
    '59622': -1,
    '59657': 1,
    '59815': -2,
    '59999': 1,
    '60094': 1,
    '60153': 1,
    '60239': -1,
    '61696': 1,
    '61727': 1,
    '61857': -2,
    '61964': 1,
    '62085': 2,
    '62717': -1,
    '62904': 1,
    '63105': 1,
    '63199': 1,
    '63516': 1,
    '63644': -2,
    '63718': -1,
    '63840': 1,
    '63923': 1,
    '64139': 1,
    '64146': -2,
    '64319': -2,
    '64500': 1,
    '64861': 1,
    '65488': 1,
    '65620': -2,
    '65905': 1,
    '65993': 1,
    '66285': 1,
    '66297': 1,
    '66391': -2,
    '66556': 1,
    '66619': -2,
    '66653': 1,
    '66799': 1,
    '66822': 1,
    '66827': 1,
    '66950': 1,
    '66977': 1,
    '67218': 1,
    '67361': -1,
    '67598': 1,
    '67621': 1,
    '67735': -1,
    '68995': -2,
    '69272': -1,
    '69350': 1,
    '69394': 1,
    '69398': 1,
    '69505': 1,
    '69533': 1,
    '69697': 1,
    '69839': 1,
    '70041': -2,
    '70133': 1,
    '70292': 1,
    '70482': 1,
    '70483': -2,
    '70541': 1,
    '70787': 1,
    '70892': 1,
    '71279': 1,
    '71397': -2,
    '71567': 1,
    '71685': 1,
    '71697': 1,
    '71831': -2,
    '72406': 1,
    '72543': -2,
    '72581': -2,
    '73028': 1,
    '73033': -2,
    '73136': 1,
    '73319': 1,
    '73444': 1,
    '73596': 1,
    '73597': 1,
    '73609': -2,
    '73673': 1,
    '74247': -1,
    '75265': 1,
    '75309': -2,
    '75461': 1,
    '75677': 1,
    '75966': 1,
    '75984': 1,
    '76207': 1,
    '76232': -1,
    '76242': 1,
    '76460': 1,
    '76475': 1,
    '76698': 1,
    '76771': 1,
    '77062': 1,
    '77180': 1,
    '77597': 1,
    '78052': 1,
    '78086': 1,
    '78147': -2,
    '78237': 1,
    '78256': 1,
    '78507': 1,
    '78696': 1,
    '80010': -2,
    '80071': 1,
    '80242': 1,
    '80307': 1,
    '80402': 1,
    '80426': -1,
    '80527': -2,
    '80631': 1,
    '80690': 1,
    '80721': 1,
    '80979': -1,
    '80988': 1,
    '81180': 1,
    '81190': 1,
    '81278': 1,
    '81802': 1,
    '82350': 2,
    '83042': -1,
    '83601': 1,
    '83806': 1,
    '83812': 1,
    '83898': 1,
    '84012': 1,
    '84102': 1,
    '84150': 1,
    '84264': 1,
    '84291': 1,
    '84326': 1,
    '84743': -2,
    '84949': 1,
    '85241': 1,
    '85300': 1,
    '85315': 1,
    '85338': 1,
    '85663': 1,
    '85667': -1,
    '85805': 1,
    '86513': 1,
    '86894': 1,
    '87189': 2,
    '87743': 1,
    '88399': 1,
    '89059': 1,
    '89641': 1,
    '90040': 1,
    '90161': 1,
    '90183': -2,
    '90195': 1,
    '90279': -2,
    '90397': 1,
    '90463': -1,
    '90534': 1,
    '91576': 1,
    '91657': -2,
    '91739': 1,
    '91779': 1,
    '91811': 1,
    '91834': -2,
    '91939': 1,
    '91994': 1,
    '92148': 1,
    '92150': -1,
    '92871': -2,
    '92988': 1,
    '93220': -2,
    '93273': 1,
    '93287': -1,
    '94278': 1,
    '94355': 1,
    '94537': -1,
    '95020': 1,
    '95405': 1,
    '95591': 1,
    '95925': 1,
    '96064': 1,
    '96169': 1,
    '96311': 1,
    '96622': 1,
    '96841': 1,
    '97255': 1,
    '97908': -1,
    '98038': 1,
    '98115': 1,
    '98272': 1,
    '98359': 1,
    '98629': 1,
    '99046': 1,
    '99413': 1,
    '99742': -2,
    '99834': -2,
    '100112': 1,
    '100256': 1,
    '100257': 1,
    '100258': 1,
    '100261': 1,
    '100262': 1,
    '100263': 1,
    '100265': 1,
    '100289': 1,
    '100290': -1,
    '100309': -1,
    '100310': 1,
    '100313': 1,
    '100371': 1,
    '100382': 1,
    '100383': 1,
    '100389': 1,
    '100401': 1,
    '100452': 1,
    '100458': 1,
    '100465': -2,
    '100479': 1,
    '100487': 1,
    '100499': 1,
    '100514': 1,
    '100525': 1,
    '100529': 1,
    '100543': 1,
    '100554': -2,
    '100560': 1,
    '100570': 1,
    '100571': 1,
    '100593': 1,
    '100602': 1,
    '100617': 1,
    '100646': -1,
    '100701': -2,
    '100702': 1,
    '100756': 1,
    '100757': 1,
    '100762': 1,
    '100763': 1,
    '100773': -1,
    '100783': 1,
    '100795': 1,
    '100796': 1,
    '100818': 1,
    '100844': 1,
    '100845': 1,
    '100853': 1,
    '100859': -1,
    '100938': -1,
    '100950': 1,
    '100966': 1,
    '100990': 1,
    '100992': 1,
    '101012': 1,
    '101037': 1,
    '101042': 1,
    '101057': 1,
    '101065': -2,
    '101085': 1,
    '101128': 1,
    '101161': 1,
    '101168': 1,
    '101171': 1,
    '101187': -2,
    '101197': 1,
    '101211': 1,
    '101219': 1,
    '101223': -2,
    '101253': 1,
    '101268': 1,
    '101274': 2,
    '101310': 1,
    '101350': 1,
    '101355': 1,
    '101363': -2,
    '101370': 1,
    '101389': 1,
    '101392': 1,
    '101413': 1,
    '101427': 1,
    '101443': 1,
    '101445': -2,
    '101457': 1,
    '101462': 1,
    '101477': 1,
    '101497': 1,
    '101519': 1,
    '101523': 1,
    '101557': 1,
    '101560': -2,
    '101563': 1,
    '101571': 1,
    '101576': 1,
    '101583': 1,
    '101658': 1,
    '101694': -2,
    '101712': 1,
    '101714': 1,
    '101734': 1,
    '101762': 1,
    '101790': 1,
    '101792': -1,
    '101805': 1,
    '101814': 1,
    '101837': 1,
    '101851': 1,
    '101862': 1,
    '101890': 1,
    '101921': 1,
    '101960': 1,
    '102008': 1,
    '102032': -2,
    '102048': 1,
    '102057': 1,
    '102068': -2,
    '102147': 1,
    '102227': 1,
    '102236': 1,
    '102246': -2,
    '102266': 1,
    '102267': 1,
    '102319': 1,
    '102333': 1,
    '102370': 1,
    '102374': 1,
    '102384': 1,
    '102448': 1,
    '102457': 1,
    '102508': 1,
    '102526': 1,
    '102535': 1,
    '102542': 1,
    '102558': 1,
    '102601': 1,
    '102708': 1,
    '102729': 1,
    '102732': 1,
    '102735': 1,
    '102754': 1,
    '102783': 1,
    '102794': -2,
    '102821': 1,
    '102839': -2,
    '102853': 1,
    '102958': -2,
    '102966': 1,
    '103001': 1,
    '103013': 1,
    '103043': 1,
    '103067': 1,
    '103148': 1,
    '103180': -1,
    '103198': 1,
    '103206': 1,
    '103222': 1,
    '103230': 1,
    '103241': 1,
    '103273': 1,
    '103298': 1,
    '103345': 1,
    '103346': 1,
    '103402': 1,
    '103423': 1,
    '103433': 1,
    '103435': 1,
    '103462': 1,
    '103480': 1,
    '103497': -1,
    '103553': 1,
    '103557': 1,
    '103558': 1,
    '103590': -2,
    '103593': 1,
    '103618': -1,
    '103667': 1,
    '103721': 1,
    '103773': 1,
    '103802': 1,
    '103809': 1,
    '103842': 1,
    '103850': 1,
    '103858': 1,
    '103872': 1,
    '103879': 1,
    '103885': 1,
    '103897': 1,
    '103952': 1,
    '103965': -1,
    '103990': 1,
    '104037': 1,
    '104074': -2,
    '104102': 1,
    '104137': 1,
    '104145': 1,
    '104207': 1,
    '104217': 1,
    '104227': 1,
    '104238': 1,
    '104247': -2,
    '104258': -2,
    '104340': -1,
    '104351': 1,
    '104354': 1,
    '104381': 1,
    '104467': 1,
    '104519': 1,
    '104553': 1,
    '104626': 1,
    '104637': 1,
    '104645': 1,
    '104694': 1,
    '104735': 1,
    '104738': 1,
    '104759': -2,
    '104789': -1,
    '104799': 1,
    '104812': 1,
    '104814': 1,
    '104828': -1,
    '104849': 1,
    '104921': 1,
    '104944': 1,
    '104974': 1,
    '105017': 1,
    '105037': 1,
    '105098': -2,
    '105120': 1,
    '105126': 1,
    '105136': 1,
    '105143': -2,
    '105194': 1,
    '105214': 1,
    '105338': 1,
    '105357': 1,
    '105382': 1,
    '105394': -1,
    '105402': -2,
    '105432': 1,
    '105444': 1,
    '105490': 1,
    '105505': -2,
    '105522': 1,
    '105547': 1,
    '105588': 1,
    '105623': 1,
    '105656': 1,
    '105682': 1,
    '105694': 1,
    '105697': 1,
    '105742': 1,
    '105750': 1,
    '105863': 1,
    '105864': 1,
    '105897': 1,
    '105911': -1,
    '105940': 1,
    '106007': 1,
    '106036': 1,
    '106076': 1,
    '106101': 1,
    '106181': 1,
    '106198': 1,
    '106217': 1,
    '106235': -1,
    '106278': 1,
    '106301': 1,
    '106347': 1,
    '106410': 1,
    '106454': 1,
    '106459': 1,
    '106509': 1,
    '106536': 1,
    '106562': 1,
    '106564': 1,
    '106623': -1,
    '106631': 1,
    '106665': 1,
    '106717': 1,
    '106782': 1,
    '106793': 1,
    '106890': 1,
    '106911': 1,
    '106925': 1,
    '106955': 1,
    '106984': 1,
    '106986': 1,
    '106992': 1,
    '107023': 1,
    '107040': 1,
    '107049': 1,
    '107063': 1,
    '107090': 1,
    '107117': 1,
    '107139': 1,
    '107142': 1,
    '107194': 1,
    '107218': 1,
    '107235': -1,
    '107280': -1,
    '107292': 1,
    '107399': 1,
    '107429': 1,
    '107443': 1,
    '107454': -1,
    '107494': 1,
    '107569': -2,
    '107677': 1,
    '107686': -1,
    '107710': 1,
    '107722': -1,
    '107745': 1,
    '107798': 1,
    '107820': 1,
    '107860': 1,
    '107879': 1,
    '107893': 1,
    '107929': 1,
    '107934': 1,
    '107964': 1,
    '108005': 1,
    '108061': 1,
    '108119': 1,
    '108138': 1,
    '108160': -2,
    '108166': 1,
    '108220': 1,
    '108244': -2,
    '108269': 1,
    '108333': 1,
    '108341': 1,
    '108366': 1,
    '108376': -1,
    '108419': 1,
    '108425': 1,
    '108477': 1,
    '108575': 1,
    '108587': 1,
    '108607': 1,
    '108834': 1,
    '108837': 1,
    '108864': 1,
    '109049': -2,
    '109056': 1,
    '109147': -2,
    '109158': 1,
    '109261': -2,
    '109293': 1,
    '109304': 1,
    '109349': 1,
    '109455': -2,
    '109478': 1,
    '109481': 1,
    '109517': 1,
    '109526': 1,
    '109546': 1,
    '109633': 1,
    '109649': 1,
    '109697': 3,
    '109778': 1,
    '109864': -2,
    '109897': 1,
    '110124': 1,
    '110203': 1,
    '110233': 1,
    '110329': 1,
    '110347': 1,
    '110385': 1,
    '110453': 1,
    '110502': 1,
    '110523': 1,
    '110544': 1,
    '110565': 1,
    '110606': 1,
    '110646': 1,
    '110680': 1,
    '110771': -2,
    '110838': 1,
    '110848': -2,
    '110909': 1,
    '110936': 1,
    '110937': 1,
    '110944': 1,
    '110972': 1,
    '111020': 1,
    '111023': 1,
    '111092': 1,
    '111109': 1,
    '111292': 1,
    '111335': 1,
    '111345': -2,
    '111368': 1,
    '111370': 1,
    '111549': -1,
    '111584': 1,
    '111648': 1,
    '111699': 1,
    '111704': 1,
    '111896': 1,
    '111909': 1,
    '111930': 1,
    '111963': 1,
    '111972': -1,
    '112039': -1,
    '112045': 1,
    '112086': 1,
    '112170': 1,
    '112200': -2,
    '112243': 1,
    '112245': -1,
    '112258': 1,
    '112351': 1,
    '112362': 1,
    '112399': 1,
    '112417': -1,
    '112451': 1,
    '112470': 1,
    '112558': 1,
    '112606': 1,
    '112722': 1,
    '112731': 1,
    '112800': -2,
    '112865': -1,
    '112934': -1,
    '113010': 1,
    '113168': 1,
    '113204': 1,
    '113222': 1,
    '113347': -1,
    '113375': 1,
    '113384': 1,
    '113566': 1,
    '113594': -2,
    '113604': 1,
    '113752': 1,
    '113851': 1,
    '114114': 1,
    '114125': 1,
    '114201': 1,
    '114203': -1,
    '114292': 1,
    '114354': 1,
    '114417': 1,
    '114431': 1,
    '114450': 1,
    '114503': -1,
    '114617': 1,
    '114769': 1,
    '114783': -2,
    '114898': 1,
    '115003': 1,
    '115064': 1,
    '115100': 1,
    '115175': 1,
    '115189': 1,
    '115328': 1,
    '115468': -2,
    '115581': 1,
    '115594': -2,
    '115617': -1,
    '115740': 1,
    '115799': 1,
    '115833': 1,
    '115861': 1,
    '116041': 1,
    '116115': 1,
    '116131': 1,
    '116195': 1,
    '116196': 1,
    '116201': 1,
    '116209': 1,
    '116231': 1,
    '116489': 1,
    '116649': 1,
    '116741': 1,
    '116747': 1,
    '116804': 1,
    '116808': 1,
    '116814': 1,
    '116990': 1,
    '117067': -2,
    '117190': 1,
    '117261': 1,
    '117262': 1,
    '117411': 1,
    '117482': 1,
    '117484': 1,
    '117523': -1,
    '117580': 1,
    '117653': -1,
    '117917': 1,
    '117953': 1,
    '118047': 1,
    '118083': 1,
    '118360': 1,
    '118375': 1,
    '118807': 1,
    '119235': 1,
    '119410': -2,
    '119487': 1,
    '119611': -2,
    '119786': 1,
    '119848': 1,
    '119969': 1,
    '120066': 1,
    '120153': 1,
    '120355': 1,
    '120388': -2,
    '120635': 1,
    '120657': 1,
    '120801': 1,
    '120912': -2,
    '121077': 1,
    '121151': 1,
    '121306': 1,
    '121390': -2,
    '121497': -2,
    '121533': 1,
    '121586': 1,
    '121785': 1,
    '121881': 1,
    '121882': 1,
    '122075': 1,
    '122076': 1,
    '122160': 1,
    '122175': 1,
    '122226': -1,
    '122252': 1,
    '122266': 1,
    '122311': 1,
    '122586': 1,
    '122704': -1,
    '122723': 1,
    '122738': 1,
    '122828': 1,
    '122901': 1,
    '123143': -1,
    '123225': 1,
    '123252': 1,
    '123375': -1,
    '123451': 1,
    '123585': 1,
    '123768': 1,
    '123778': 1,
    '123903': -1,
    '124022': 1,
    '124053': 1,
    '124066': 1,
    '124692': 1,
    '124761': 1,
    '124797': -1,
    '124805': 1,
    '124928': 1,
    '124934': 1,
    '125099': 1,
    '125149': 1,
    '125483': 1,
    '125613': 1,
    '125705': 1,
    '125746': 1,
    '125799': -1,
    '125820': 1,
    '125825': 1,
    '125906': 1,
    '126058': 1,
    '126176': 1,
    '126293': 1,
    '126443': 1,
    '126743': 1,
    '127038': 1,
    '127039': 1,
    '127437': 1,
    '127451': 1,
    '127467': 1,
    '127587': 1,
    '127771': 1,
    '127802': -2,
    '127815': 1,
}

import { nth } from 'rambda'
import { cli } from 'webpack'

/* eslint-disable max-len */
export default {
    lang: 'en',
    tier: {
        paper: 'Paper',
        tablet: 'Tablet',
        scroll: 'Scroll',
        opus: 'Opus',
        unknown: 'Unknown / None',
    },
    general: {
        tier: 'Tier',
        account: 'Account',
        other: 'Other',
        help: 'Help',
        logout: 'Logout',
        endSession: 'End Session',
        login: 'Login',
        version: 'Version',
        beta: 'beta',
        email: 'Email',
        password: 'Password',
        anlas: 'Anlas',
        signIn: 'Sign In',
        signUp: 'Sign Up',
        logIn: 'Log In',
        note: 'Note:',
        welcomeBack: 'Welcome back!',
        loading: 'Loading...',
    },
    cta: {
        manage: 'Manage',
        change: 'Change',
        request: 'Request',
        confirm: 'Confirm',
        cancel: 'Cancel',
        save: 'Save',
        upgrade: 'Upgrade',
        ok: 'OK',
        yes: 'Yes',
        gotIt: 'Got it!',
        readMore: 'Read More',
        manageSubscription: 'Manage Subscription',
        overwrite: 'Overwrite',
        startWriting: 'Start Writing',
        submit: 'Submit',
        subscribe: 'Subscribe',
        unsubscribe: 'Unsubscribe',
        clickHere: 'Click Here',
    },
    common: {
        download: 'Download',
        delete: 'Delete',
        defaultX: 'Default: [0]',
        applyAndSave: 'Apply & Save',
        import: 'Import',
        export: 'Export',
        anonymouseTrial: 'Anonymous Trial',
        processing: 'Processing',
        notSubscribed: 'Not Subscribed',
        somethingWentWrong: 'Something went wrong!',
        copy: 'Copy',
        hide: 'Hide',
        reveal: 'Reveal',
        unknown: 'Unknown',
        back: 'Back',
        giftKey: 'Gift Key',
        optional: '(optional)',
        fileTypeNotSupported: 'File type not supported.',
        maximumFileSize: 'Maximum allowed file size is [0]MB.',
        expand: 'Expand',
        collapse: 'Collapse',
        dontShowThisAgain: "Don't show this again.",
        learnMore: 'Learn More',
        skip: 'Skip',
        fix: 'Fix',
        selectAnOptionToContinue: 'Select an option to continue.',
        storyteller: 'Storyteller',
        textAdventure: 'Text Adventure',
        shuffle: 'Shuffle',
        stories: 'Stories',
        generateInline: 'Generate Inline',
        memory: 'Memory',
        authorsNote: "Author's Note",
        undo: 'Undo',
        redo: 'Redo',
        retry: 'Retry',
        lorebook: 'Lorebook',
        tokenProbabilities: 'Token Probabilities',
        cancel: 'Cancel',
        send: 'Send',
        reload: 'Reload',
        closeModal: 'Close Modal',
        selectAll: 'Select All',
        deselect: 'Deselect',
        search: 'Search',
        enabled: 'Enabled',
        disabled: 'Disabled',
        generate: 'Generate',
        story: 'Story',
        xTokens: '[0] Tokens',
        tokens: 'Tokens',
        changedMyMind: 'I changed my mind.',
        noStorySelected: 'No Story selected.',
        ignore: 'Ignore',
        lDisable: 'disable',
        lEnable: 'enable',
        characters: 'Characters',
        token: 'Token',
        accept: 'Accept',
        paymentProviderFailedToLoad:
            'Payment provider failed to load. Please reload the page. Some regions cannot be served by Paddle, please check if that applies to you if this error persists.',
    },
    commonError: {
        clipboardUnavailable: 'The clipboard is not available',
        failedToWriteToClipboardX: 'Failed to write to clipboard: [0]',
        failedToReadFromClipboardX: 'Failed to read from clipboard: [0]',
        cantSpeakAnEmptySelection: "Can't speak an empty selection.",
    },
    authFormGeneral: {
        getStarted: 'Get Started',
        email: 'Email',
        repeatEmail: 'Repeat Email',
        repeatPassword: 'Repeat Password',
        emailEmptyWarning: 'Email cannot be empty',
        emailPasswordEmptyWarning: 'Email and Password cannot be empty',
        rememberMe: 'Remember me',
        loginRemembered: 'Login is remembered for 30 days',
        loginNotRemembered: 'Login will not be remembered',
        credentialsSub:
            'Please take good care of your login credentials. Due to local encryption, losing your email or password results in permanently losing access to your remotely stored stories.',
        captchaSub:
            'This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply',
        naiTermsNote: {
            0: 'By registering, you agree to consent to the NovelAI',
            1: 'Privacy Policy and Terms of Service',
            2: '.',
        },
        googleTermsNote: {
            0: 'This site is protected by reCAPTCHA and the Google',
            1: 'Privacy Policy',
            2: 'and',
            3: 'Terms of Service',
            4: 'apply.',
        },
        marketingEmails: 'Would you like to receive emails about updates from us in the future?',
        emailWhitelistWarning: {
            0: 'NOTE',
            1: 'The email provider you used has been marked as being unable to access the free trial. Please subscribe to use the product.',
        },
    },
    passwordReset: {
        resetPassword: 'Reset Password',
        resetPassWordSub:
            'By submitting this form, you will receive an email containing a password reset link with further steps.',
        encryptionSub:
            'Due to local encryption, resetting your password results in permanently losing access to your remotely stored stories and AI Modules.',
    },
    storyDelete: {
        deleteIt: 'Delete it!',
        deleteStory: 'Delete Story?',
        areYouSure: 'Are you sure you want to delete "[0]"?',
        cannotBeUndone: 'This cannot be reversed.',
        delete: 'Delete',
    },
    features: {
        imageGeneration: 'Image Generation',
        aiModuleTraining: 'AI Module Training',
        tokenizer: 'Tokenizer',
        tutorial: 'Interactive Tutorials',
        tips: 'Tips',
        docs: 'Documentation',
    },
    modal: {
        userSettings: 'User Settings',
        aiModules: 'AI Modules',
        lorebook: 'Lorebook',
        storySettings: 'Story Settings',
        contextViewer: 'Context Viewer',
    },
    image: {
        recommended: 'Recommended',
        generateText: 'Generate',
        paintNewImage: 'Paint New Image',
        uploadImage: 'Upload Image',
        modelSettings: 'Model-Specific Settings',
        modelSettingsTTSteps: 'Steps: The number of iterations to refine the image for.',
        modelSettingsTTScale:
            'Scale: At high scale the prompt will be followed more closely, with finer detail and sharpness. Low scale often results in greater creative freedom, but reduced definition.',
        portrait: 'Portrait',
        landscape: 'Landscape',
        square: 'Square',
        custom: 'Custom',
        small: 'Small',
        normal: 'Normal',
        large: 'Large',
        largeplus: 'Large+',
        wallpaper: 'Wallpaper',
        tokenCounter: '[0] tokens out of [1] tokens used',
        prompt: 'Prompt',
        undesiredContent: 'Undesired Content',
        undesiredContentSub: 'Write what you want removed from the generation.',
        promptSub: 'Write your prompt here. Use tags to sculpt your outputs.',
        lowQualityPlusBadAnatomy: 'Low Quality + Bad Anatomy',
        lowQuality: 'Low Quality',
        light: 'Light',
        heavy: 'Heavy',
        heavyPlusDeAI: 'Heavy+DeAI',
        humanFocus: 'Human Focus',
        badAnatomy: 'Bad Anatomy',
        none: 'None',
        addQualityTags: 'Add Quality Tags',
        addQualityTagsSubOff: 'The prompt will be used unmodified.',
        addQualityTagsSubOn: 'Tags to increase quality will be prepended to the prompt.',
        disableTagSuggestions: 'Disable Tag Suggestions',
        steps: 'Steps',
        promptGuidance: 'Prompt Guidance',
        negativePromptGuidance: 'Undesired Content Strength',
        negativePromptGuidanceTooltip: 'Values other than 100% will increase generation time and Anlas cost.',
        guidance: 'Guidance',
        scale: 'Scale',
        seed: 'Seed',
        seedSub: 'Enter a seed',
        advancedSampler: 'Advanced: Sampler',
        sampler: 'Sampler',
        numberOfImages: 'Number of Images',
        history: 'History',
        historyTT:
            'Ctrl+Click on an image to set your settings to the ones used to generate it (except for any init image).',
        settings: 'Settings',
        naidFullDesc:
            'One of our original models trained on an expanded dataset. No longer recommended for use.',
        naidCuratedDesc: 'One of our original models. No longer recommended for use.',
        naidFurryDesc: 'Beta model for furry and other non-human content. No longer recommended for use.',
        naidXLFullDesc: '',
        naidXLCuratedDesc: '',
        naidAnime2Desc: 'Our previous model. No longer recommended for use.',
        naidAnime3Desc: 'Our most recent state of the art model. (Recommended)',
        naidFurry3Desc: 'Our most recent state of the art furry model. (Recommended)',
        downloadZIP: 'Download ZIP',
        selected: 'Selected:',
        draw: 'Draw',
        erase: 'Erase',
        select: 'Select',
        save: 'Save',
        cancel: 'Cancel',
        colorPicker: 'Color Picker',
        penSize: 'Pen Size',
        pressureSens: 'Pressure Sensitivity',
        resizeCanvas: 'Resize Canvas',
        manageSubscription: 'Manage Subscription',
        autoSmea: 'Auto nai_smea',
        autoSmeaChecked: 'nai_smea will be used automatically at high resolutions.',
        autoSmeaUnchecked: 'nai_smea will not be used automatically.',
        imageResolution: 'Resolution',
        useSmea: 'Use smea',
        smea: 'SMEA',
        dyn: 'DYN',
        useSmeaTooltip: 'Smea versions of samplers are modified to perform better at high resolutions.',
        useSmeaTooltipDisabled: 'SMEA style sampling is not available for this sampler.',
        autoSmeaTooltip: 'High resolution samplers will automatically be used above a certain image size.',
        useDynVariant: 'Dyn variant',
        useDynVariantTooltip:
            'SMEA Dyn variants of samplers are optimized to increase the coherence of higher resolution images without introducing the characteristic SMEA look. The effective range of resolutions is smaller than for regular SMEA.',
        enhanceButton: 'Enhance!',
        enhanceImage: 'Enhance Image',
        invalid: 'Invalid',
        image2imageNotSupported: 'Image2Image not supported for chosen model.',
        controlToolsNotSupported: 'Control Tools not supported for chosen model.',
        inpaintingNotSupported: 'Inpainting not supported for chosen model.',
        enhanceScale: 'Upscale Amount',
        strength: 'Strength',
        noise: 'Noise',
        magnitude: 'Magnitude',
        showAdvanced: 'Show Advanced',
        hideAdvanced: 'Hide Advanced',
        aiSettings: 'AI Settings',
        advancedSettings: 'Advanced Settings',
        addBaseImage: 'Add a Base Img (Optional)',
        img2img: 'Image2Image',
        img2imgSub: 'Transform your image.',
        palleteSwap: 'Palette Swap',
        palleteSwapSub: 'Reskin and re-stylize.',
        formLock: 'Form Lock',
        formLockSub: 'Keep the layout, change the content.',
        scribbler: 'Scribbler',
        scribblerSub: 'Turn fridge art into modern art.',
        buildingControl: 'Building Control',
        buildingControlSub: 'Construct new buildings and rooms.',
        landscaper: 'Landscaper',
        landscaperSub: 'Sculpt valleys and vistas.',
        undecided: 'Dummy',
        convert: 'Convert',
        selectATool: 'Select a Control Tool',
        didYouMean: 'Did you mean?',
        result: 'Result',
        palleteSwapDetailed: 'Add More Detail?',
        lowThreshold: 'Low Threshold',
        highThreshold: 'High Threshold',
        alpha: 'Alpha', // Transparency
        valueThreshold: 'Value Threshold',
        distanceThreshold: 'Dist Threshold', // Distance Threshold was too long
        generate1Image: 'Generate 1 Image',
        generateXImages: 'Generate [0] Images',
        dynamicThresholding: 'Decrisp',
        cfgDelay: 'Variety+',
        enhance: 'Enhance',
        variations: 'Variations',
        setAsInit: 'Use as Base',
        setAsInitImage: 'Use as Base Image',
        generateVariations: 'Generate Variations',
        editImage: 'Edit Image',
        inpaintImage: 'Inpaint Image',
        undesiredContentPreset: 'Undesired Content Preset',
        maskDraw: 'Draw Mask',
        maskErase: 'Erase Mask',
        saveAndClose: 'Save & Close',
        removeInpaintingMask: 'Remove Inpainting Mask',
        addInpaintingMask: 'Inpaint Image',
        clear: 'Clear', // Clear seed
        resetAllSettings: 'Reset all settings to default?',
        imageWithMetadataFound: 'Image with Metadata found!',
        imageWithMetadataFoundSub: 'Import the Image or import Settings and Prompt?',
        importImage: 'Import Image',
        importPrompt: 'Import Prompt',
        importSettings: 'Import Settings',
        importSettingsPlusSeed: 'Import Settings + Seed',
        toolInfluence: 'Tool Influence',
        upscale: 'Upscale',
        controlnetUndecidedError: 'Please select an image input mode or remove the base image.',
        dynamicThresholdingTooltip: 'Reduce artifacts caused by high prompt guidance values.',
        cfgDelayTooltip:
            'Enable guidance only after body has been formed, to improve diversity and saturation of samples. May reduce relevance.',
        copyToClipboard: 'Copy to Clipboard',
        pinImage: 'Pin Image',
        downloadImage: 'Download Image',
        copyToSeed: 'Copy to Seed',
        detatchUc: 'Detach Undesired Content',
        reattachUc: 'Reattach Undesired Content',
        promptChunkTooltip: 'Prompt Settings',
        resetSettings: 'Reset Settings',
        removePinnedImage: 'Remove Pinned Image',
        goToImage: 'Go to Image',
        old: 'OLD',
        new: 'NEW',
        imageSettings: 'Image Settings',
        regenerate: 'Regenerate',
        changeSize: 'Change Size',
        cropClosestValid: 'Crop to closest valid generation size',
        shiftEdges: 'Shift Edges',
        resize: 'Resize',
        imageTooLargeUpscale: 'Image is larger than 1024x1024.',
        compareWithOriginal: 'Compare with Original',
        convertUsingControlTool: 'Convert using a Control Tool',
        retryConversion: 'Retry Conversion',
        useOriginal: 'Use Original',
        editInpaintingMask: 'Edit Inpainting Mask',
        addOriginalImage: 'Overlay Original Image',
        addOriginalImageChecked:
            'Prevents the existing image from changing, but can introduce seams along the edge of the mask.',
        addOriginalImageUnchecked:
            'Prevents seams along the edge of the mask, but means the existing image may change slightly.',
        inpainting: 'Inpaint',
        inpaintingSub: 'Change part of an image.',
        mask: 'Mask',
        qualityTagsEnabled: 'Quality Tags Enabled',
        ucPresetEnabled: 'UC Preset Enabled',
        introducingNaid2: 'Introducing NovelAI Diffusion 3.0!',
        introducingNaid2Sub:
            'Multiple times better in every way than our previous models, including knowledge, controllability, coherency, and style!',
        introducingNaid2Sub2: '',
        introducingNaid2SubSub:
            'Your model has automatically been set to this new model, though you can change it back in the model dropdown if you like.',
        cfgRescale: 'Prompt Guidance Rescale',
        or: 'or',
        randomize: 'Randomize',
        randomPrompt: 'Random Prompt',
        noiseSchedule: 'Noise Schedule',
        legacyPromptBehaviour: 'Outdated legacy prompt import detected: ',
        legacyPromptBehaviour2:
            'For image reproduction purposes a flag has been set to use old behavior. Changing any settings or prompt will reset this flag.',
        addChristmas: 'Add some Christmas spirit.',
        automaticDownload: 'Automatic Download',
        automaticDownloadSubOn: 'Images will automatically download after generation.',
        automaticDownloadSubOff: 'Images will not automatically download after generation.',
        imageTooLong: 'These settings would take too long to generate.',
        //diffEdit: 'Diff Edit',
        diffEditSub: 'Edit an image by addding and removing content.',
        image: 'Image',
        add: 'Add',
        subtract: 'Subtract',
        //diffEditInitImageError: 'Init image is required for Diff Edit.',
        //diffEditUnsuppportedModel: 'Diff Edit is not supported for this model.',
        sameFeelInitImageError: 'Init image is required for Same Feel control tool.',
        sameFeel: 'Vibe Transfer',
        sameFeelSub: 'Change the image, keep the vision.',
        samefeelInformationExtracted: 'Information Extracted',
        samefeelImageStrength: 'Reference Strength',
        sameFeelUnsuppportedModel: 'Vibe Transfer is not supported for this model.',
        imageImportTitle: 'What do you want to do with this image?',
        importFor: 'Import for',
        importImage2Image: 'Image2Image',
        importSameFeel: 'Vibe Transfer',
        importThisImageHasMetadata: 'This image has metadata!',
        importThisImageHasMetadataSub: 'Did you want to import that instead?',
        importMetadata: 'Import Metadata',
        importSeed: 'Import Seed',
        sessionExpired: 'Your session has expired. Please refresh the page and log in again.',
        sendToImageTools: 'Send to Director Tools',
        cannotCopyHistorySettings: 'Cannot copy history settings from this image.',
        imageTools: 'Director Tools',
        imageToolsSub: 'Use a variety of AI tools to edit your images.',
        promptMixTooMany: 'Maximum prompt mixes of [0] exceeded. Extra "[1]" will be ignored.',
        sdLicenseLead:
            'In order to use NovelAI Diffusion, you must first read and agree with the Stable Diffusion license:',
        modelRemovedSoon: 'This model will be removed soon.',
        imageFromDeprecatedModel: 'This image is from a deprecated model. Settings import not available.',
    },
    dashboard: {
        dashboard: 'Dashboard',
        welcomeBack: 'Welcome back, [0]',
        defaultName: 'Author',
        createNewStory: 'Create New Story',
        importStory: 'Import Story',
        generateImages: 'Generate Images',
        tts: 'Text-to-Speech',
        accountStatus: 'Account Status',
        latestUpdate: 'Latest Update',
        allUpdates: 'All Updates',
        recentStories: 'Recent Stories',
        lastEdited: 'Last Edited: [0]',
        imageGenerator: 'Image Generator',
        imageGen: 'Image Gen',
        imageGeneratorSub: 'Generate Anime and Furry imagery with our SOTA image gen model!',
        visualize: 'Visualize your favorite characters.',
        manageAccount: 'Manage Account',
        latestNews: 'Latest News',
        changelog: 'Changelog',
        scrollToViewMore: 'Scroll to view more',
        continueAStory: 'Continue a Story',
        lastUpdatedUnknown: 'last updated unknown',
        startYourFirstStory: 'Start your first Story',
    },
    menubar: {
        accountSettings: 'Account Settings',
        novelAIDiscord: 'NovelAI Discord',
        yourStories: 'Your Stories',
        filters: 'Filters',
        display: 'Display',
        compactView: 'Compact View',
        showOnTop: 'Show On Top',
        shelves: 'Shelves',
        favorites: 'Favorites',
        localStories: 'Local Stories',
        sortBy: 'Sort By',
        mostRecent: 'Most Recent',
        alphabetical: 'Alphabetical',
        creationDate: 'Creation Date',
        searchSub: 'Search your stories',
        searchShelf: 'Search this shelf',
        duplicateStoryTT: 'Duplicate Story',
        downloadStoryTT: 'Download Story',
        deleteStoryTT: 'Delete Story',
        editInfoTT: 'Edit Information',
        usesEditorV2: 'Uses Editor V2',
        newStory: 'New Story',
        newShelf: 'New Shelf',
        importFile: 'Import File',
        trialGenerationsRemaining: '[0]/[1] text generations remaining.',
        freeTrial: 'Free Trial',
        trialTokenCount: "You're getting [0] tokens of AI context. Equivalent to our [1].",
        trialTier: 'Scroll tier', // Inserted into [1] in trialTokenCount
        trialSeenEnough: 'Seen enough?',
        trialPlansLink: 'Check out our plans',
        trialCreateAccount: 'Create an account',
        sideMenuHoverTitle: 'Quickly Access Your Favorites',
        sideMenuHoverSub: 'Click the Heart icon next to your stories to view them here!',
        categoryOther: 'Other',
        categoryHelp: 'Help',
        desktopOnly: 'Desktop-only',
    },
    settings: {
        aiSettings: 'AI Settings',
        interface: 'Interface',
        theme: 'Theme',
        account: 'Account',
        currentTier: 'Current Tier',
        yourSubEnds: 'Your subscription ends on [0] and does not renew.',
        yourSubExpired: 'Your subscription expired on [0].',
        penName: 'Pen Name',
        emailUnverified: 'Email Unverified',
        deleteAccount: 'Delete Account',
        notPossibleWhileSubscribedError: 'Not possible while subscribed.',
        showAccountID: 'Show Account ID',
        textToSpeech: 'Text To Speech',
        defaults: 'Defaults',
        hotkeys: 'Hotkeys',
        support: 'Support',
        changeLog: 'Change Log',
        uiLanguage: 'UI Language',
        couldntSaveSettings: 'Couldn’t save settings: [0]',
        debugStoryNotFound: 'Story not found in story list.',
        debugStoryDeleted: 'Story deleted.',
        debugStoryDeleteFailed: 'Story deletion failed.',
        debugStoryDeleteFailedReason: 'Story deletion failed: [0]',
        debugStoryDownloadFailed: 'Story download failed.',
        debugStoryDownloadFailedReason: 'Story download failed: [0]',
        debugStoryNotRemote: 'Story is not remote.',
        debugStoryCloneFailedReason: 'Story clone failed: [0]',
        debugStoryCloned: 'Story with id [0] cloned.',
        debugHideSettings: 'Hide Debug Settings',
        debugStoryDownload: 'Debug Story Download',
        debugStoryDownloadPlaceholder: 'Enter a story id and click "Download".',
        debugStoryDelete: 'Debug Story Deletion',
        debugStoryDeletePlaceholder: 'Enter a story id and click "Delete". Cannot be undone.',
        debugLogprobsCount: 'Logprobs Count',
        debugPreventModuleOverride: 'Prevent Module Override',
        debugPreventModuleOverrideChecked: 'The selected module will always be used for generation.',
        debugPreventModuleOverrideUnchecked: 'The selected module will be overridden in specific situations.',
        debugPreventPreamble: 'Prevent Preamble',
        debugPreventPreambleChecked:
            'The context will never have a preamble prepended. This can cause problems or incorrect behaviour.',
        debugPreventPreambleUnchecked:
            'The context will have a preamble prepended depending on various factors.',
        debugTrimExcessWhitespace: 'Trim Excess Whitespace',
        debugTrimExcessWhitespaceChecked:
            'Excess whitespace will be trimmed from context before it is sent to the AI.',
        debugTrimExcessWhitespaceUnchecked:
            'Excess whitespace will not be trimmed from context before it is sent to the AI. Excess whitespace can significantly degrade output quality.',
        debugTrimExcessWhitespaceNote:
            'Excess whitespace includes trailing spaces and repeated newline characters.',
        debugIgnoreRemoteStoryConflicts: 'Ignore Remote Story Conflicts',
        debugIgnoreRemoteStoryConflictsChecked:
            'Remote storage conflicts will be ignored, local changes always override remote saves.',
        debugIgnoreRemoteStoryConflictsUnchecked: 'Remote storage conflicts will be detected.',
        debugRemoteStoryClone: 'Debug Remote Story Clone',
        debugRemoteStoryCloneSub: 'Creates an identical remote story with same ID',
        debugRemoteStoryClonePlaceholder: 'Enter a story id and click "Clone".',
        debugClone: 'Clone',
        debugDownloadRawRemoteMeta: 'Download Raw Remote Meta',
        debugDownloadRawRemoteContent: 'Download Raw Remote Content',
        aiResponses: 'AI Responses',
        streamAiResponses: 'Stream AI Responses',
        streamAiResponsesChecked: 'AI responses will be streamed, appearing token by token.',
        streamAiResponsesUnchecked: 'Text will appear all at once when generation has finished.',
        streamedResponseDelay: 'Streamed Response Delay',
        continueResponseToEndOfSentence: 'Continue Response to End of Sentence',
        continueResponseToEndOfSentenceChecked:
            'Responses will attempt to continue until the end of a sentence is found.',
        continueResponseToEndOfSentenceUnchecked: 'Responses will end normally.',
        hypebot: 'Hypebot',
        hypebotCommentOutput: 'Comment Output',
        hypebotSetCommentOff: 'Set comment generation off',
        hypebotOff: 'Off',
        hypebotSetCommentAuto: 'Set comment generation on',
        hypebotAuto: 'Automatic',
        hypebotSetCommentPermanent: 'Set comment generation on and always show dialog',
        hypebotPermanent: 'Permanent',
        hybebotClearComments: 'Clear Comments',
        hypebotClearCommentsChecked: 'Automatically clear comments on generating story text.',
        hypebotClearCommentsUnchecked: "Don't automatically clear comments on generating story text.",
        hybebotCommentChance: 'Comment Chance',
        hypebotAvatar: 'Comment Avatar',
        hypebotSelectLabel: 'Select a comment avatar',
        experimental: 'Experimental',
        preamble: 'Preamble',
        preambleChecked:
            'The context will have a small preamble prepended to improve generations on low context. The exact behaviour varies per model.',
        preambleUnchecked: 'The context will not have a preamble prepended.',
        defaultBias: 'Default Bias',
        defaultBiasChecked:
            'A default bias will be applied, reducing the likelyhood of dinkus (***) and asterism (⁂) to be generated.',
        defaultBiasUnchecked: 'No default bias will be applied.',
        bidirectionalInlineGeneration: 'Bidirectional Inline Generation',
        bidirectionalInlineGenerationChecked:
            'Inline generation will use a special model for generating text between two points.',
        bidirectionalInlineGenerationUnchecked: 'Inline generation will use the story model.',
        enableLogprobs: 'Enable Token Probabilities',
        enableLogprobsChecked: {
            0: 'Generation requests will return token probabilities for the response which can be examined by clicking the',
            // Icon is displayed here
            1: 'button next to Retry.',
        },
        enableLogprobsUnchecked: 'Token probabilities will not be returned with generation requests.',
        editorLogprobs: 'Editor Token Probabilities',
        editorLogprobsChecked: 'Selectable alternate tokens and probabilities will be shown in the editor.',
        editorLogprobsUnchecked:
            'Enable to show selectable alternate tokens and probabilities in the editor.',
        maxContextTokens: 'Max Context Size',
        maxContextTokensNoLimit: 'No Limit',
        maxContextTokensNote:
            'Note: The actual limit will depend on subscription tier and model. This setting exists to artificially limit the maximum context size.',
        uiLanguageLabel: 'Select a language',
        textSettings: 'Text Settings',
        interfaceFontSize: 'Interface Font Size',
        lineSpacing: 'Line Spacing',
        paragraphSpacing: 'Paragraph Spacing',
        maxLineWidth: 'Max Line Width',
        outputFontSize: 'Output Font Size',
        paragraphIndent: 'Paragraph Indent',
        buttonScale: 'Button Scale',
        interactionSettings: 'Interaction Settings',
        gestureControls: 'Gesture Controls',
        gestureControlsChecked: 'Swiping on touch devices will open and close the sidebars.',
        gestureControlsUnchecked: 'Gesture controls are disabled.',
        swapContextMenuControls: 'Swap Context Menu Controls',
        swapContextMenuControlsChecked:
            'Right click will open the standard context menu. Ctrl+right click will open the NAI context menu.',
        swapContextMenuControlsUnchecked:
            'Right click will open the NAI context menu. Ctrl+right click will open the standard context menu.',
        otherSettings: 'Other Settings',
        inputBox: 'Input Box',
        inputBoxChecked: 'The input box is visible.',
        inputBoxUnchecked: 'The input box is hidden.',
        outputSpellcheck: 'Output Spellcheck',
        outputSpellcheckChecked: 'Spellcheck is enabled in the editor (on supported browsers)',
        outputSpellcheckUnchecked: 'Spellcheck is disabled in the editor.',
        outputSpellcheckWarning:
            'Your browser version has known performance issues with spellcheck. Spellcheck has been automatically disabled.',
        editorLoreKeys: 'Editor Lorebook Keys',
        editorLoreKeysChecked:
            'Keys of currently active Lorebook entries will be bolded in the editor. Disabling this setting may improve performance on very large stories or stories with large Lorebooks.',
        editorLoreKeysUnchecked: 'No special styling will be applied to Lorebook keys in the editor.',
        showTips: 'Show Tips',
        showTipsChecked: 'Tips will be shown below the editor.',
        showTipsSessionDisabled: 'Tips are temporarily disabled for this session.',
        showTipsUnchecked: 'Tips will not be shown.',
        useEditorV2: 'Use Editor V2 for New Stories',
        useEditorV2Checked: 'New stories will use the new document editor.',
        useEditorV2Info: {
            0: 'The new document editor supports dynamic loading of sections for increased performance, text formatting, a mobile context menu and additional features.',
            1: 'Stories will continue using the editor they were created with.',
            2: 'Using Editor V2 is recommended, as now that it has reached feature parity it will entirely replace Editor V1 at some time in the future.',
        },
        useEditorV2Unchecked: 'New stories will use the old story editor.',
        editorHighlighting: 'Editor Highlighting',
        editorHighlightingChecked: 'Text in the editor will be highlighted based on origin.',
        editorHighlightingUnchecked: 'Text in the editor will not be highlighted.',
        contextViewerColors: 'Context Viewer Colors',
        contextViewerColorsChecked: 'Text in the context viewer will be color coded based on origin.',
        contextViewerColorsUnchecked: 'Text in the context viewer will use the default color.',
        showStoryTitle: 'Show Story Title',
        showStoryTitleChecked: 'The story title will be shown above the editor.',
        showStoryTitleUnchecked: 'The story title will not be shown above the editor.',
        showEditorToolbox: 'Show Editor Toolbar',
        showEditorToolboxChecked:
            'A toolbox with options to change text formatting and more will appear when selecting text in Editor V2.',
        showEditorToolboxUnchecked:
            'Selecting text in Editor V2 will not open the toolbox to change text formatting and more.',
        editorUnloadSectionsRange: 'Paragraph Visibility Range',
        editorUnloadSectionsRangeSub:
            'Paragraphs scrolled out-of-view past the set range will be unloaded in Editor V2. This improves editing performance especially with larger stories.',
        noUnloading: 'No Unloading',
        highlightSpeech: 'Highlight Speech',
        highlightSpeechDesc:
            'Choose if speech (text between quotation marks) in the editor should be highlighted in italic and slightly less opaque. When choosing Inverted, non-speech is shown in italic and slightly less opaque instead.',
        editorV2Only: 'Only available in Editor V2.',
        highlightSpeechOff: 'Off',
        highlightSpeechOffLabel: 'Disable speech highlight',
        highlightSpeechOn: 'Highlight',
        highlightSpeechOnLabel: 'Highlight speech',
        highlightSpeechInverted: 'Inverted',
        highlightSpeechInvertedLabel: 'Highlight non-speech',
        showIdenticon: 'Show Identicon',
        showIdenticonChecked: 'A unique identicon will be shown for your account.',
        showIdenticonUnchecked: 'A theme-specific default avatar will be shown for your account.',
        useMinibar: 'Show Minibar (Desktop Only)',
        useMinibarChecked: 'The minibar will display on the left side of the screen.',
        useMinibarUnchecked: 'The minibar will not be displayed.',
        invalidThemeFile: 'Invalid theme file.',
        importedTheme: 'Theme imported to theme editor.',
        saveTheme: 'Apply & Save Theme',
        importTheme: 'Import Theme File',
        exportTheme: 'Export Theme File',
        defaultThemes: 'Default Themes',
        themeEditor: 'Theme Editor',
        downloadAllFailed: 'Download all failed.',
        persistentTokenFailed: 'Failed to get persistent token.',
        accountId: 'Account ID',
        getPersistentToken: 'Get Persistent API Token',
        persistentToken: 'Persistent API Token',
        persistentTokenDesc: 'Below is a persistent API token that can be used to access the API.',
        persistentTokenShowFull: 'Show Full Token',
        persistentTokenCopied: 'Copied token to clipboard.',
        persistentTokenNote:
            'Note: You can only have a single persistent token at a time. Creating a new one will invalidate the old one. You will not be able to view this token again after closing this window.',
        persistentTokenOverwrite: 'Overwrite Persistent API Token',
        persistentTokenOverwriteDesc:
            'You have an existing persistent API token. Creating a new one will invalidate the old one.',
        persistentTokenOverwriteConfirm: 'Are you sure you want to overwrite it?',
        localStorageUnavailable: 'Local storage is currently unavailable and disabled.',
        downloadAllStories: 'Download All Stories',
        downloadingAllStories: 'Downloading all stories...',
        changeDetailsMustEnterExisting: 'You need to enter your existing email and password.',
        changeDetailsMustEnterNew: 'You need to enter a new email or a new password.',
        changeDetailsEmailFormat: 'Email must be in email format.',
        changeDetailsPasswordRequirements: 'Password must be 8 characters or more.',
        changeDetailsPasswordMatch: 'Passwords do not match.',
        changeDetailsFailed: 'Failed to change details.',
        changeDetailsSuccessEmail:
            'Details changed! A confirmation email has been sent to your new email address.',
        changeDetailsSuccess: 'Details changed!',
        changeDetailsIncorrectAccessKey: 'Incorrect username and password combination.',
        changeDetailsResentEmail: 'Resent confirmation email!',
        changeDetailsRefreshClaimActions:
            ' Refresh the page after confirming your email to claim your 50 free actions.', // Leading space is intentional. It gets appended to the above.
        changeDetailsAccountDeletionEmail: 'Sent account deletion confirmation email!',
        changeDetailsAccountDeletionFollow:
            ' Follow the link in your email to confirm the deletion of your account.',
        changeDetailsOldEmail: 'Old Email',
        changeDetailsNewEmail: 'New Email',
        changeDetailsPassword: 'Password',
        changeDetailsCurrentEmail: 'Current Email',
        changeDetailsCurrentPassword: 'Current Password',
        changeDetailsNewPassword: 'New Password',
        changeDetailsConfirmNewPassword: 'Confirm New Password',
        changeDetailsEmail: 'Email',
        changeDetailsSendConfirmationEmail: 'Send Confirmation Email',
        changeDetailsConfirmationEmailDesc: {
            0: 'Enter your email address below to receive an account deletion confirmation email.',
            1: 'Be aware that the deletion of your account results in the permanent loss of your stories and other content! Make sure to export the content you want to keep available beforehand; For example by using the "Export all Stories" option in the Account Settings. Keep in mind that trained AI Modules and some other content are not exportable and will be lost if not saved beforehand.',
        },
        changeDetailsSendDeletionConfirmationEmail: 'Send Deletion Confirmation Email',
        changeDetailsPenNameLabel: 'Change Pen Name',
        changeDetailsEmailLabel: 'Change Email',
        changeDetailsPasswordLabel: 'Change Password',
        changeDetailsVerifyFreeActions: 'Verify your email to gain 50 free actions.',
        changeDetailsDeleteAccountLabel: 'Request to Delete Account',
        ttsAreaDescription: 'Text entered here will be used for the test/download buttons.',
        ttsSource: 'Text to Speech Source',
        ttsSourceLocalDesc: 'Local TTS uses your browsers available speech synthesis capabilities.',
        ttsSourceStreamedDesc:
            'Streamed TTS is higher quality, uses a model hosted by NovelAI, and requires an active subscription. 100 free generations given for trial purposes.',
        ttsLocalUnsupported: 'Your browser does not support local TTS.',
        ttsStreamed: 'Streamed',
        ttsLocal: 'Local',
        ttsUsingLocal: 'TTS will use the browsers Speech Synthesis API.',
        ttsUsingStreamed: "TTS will use NovelAI's remote TTS service.",
        ttsNoMediaSource:
            'Your browser does not support MediaSource. Streamed TTS will still work, but you may notice higher latency.',
        ttsNoMpeg:
            "Your browser does not support 'audio/mpeg' as a MediaSource type. Streamed TTS will still work, but you may notice slightly higher latency.",
        ttsOff: 'Text to speech is turned off.',
        ttsSpeakOutputs: 'Speak Outputs',
        ttsSpeakOutputsChecked: 'Outputs will be read by TTS.',
        ttsSpeakOutputsUnchecked: 'Outputs will not be read.',
        ttsSpeakInputs: 'Speak Inputs',
        ttsSpeakInputsChecked: 'In addition to outputs, Inputs will be read by TTS.',
        ttsSpeakInputsUnchecked: 'Inputs will not be read.',
        ttsSpeakInputsNoEffect: ' Has no effect if speak outputs is disabled',
        tssSpeakHypebot: 'Speak HypeBot Comments',
        ttsSpeakHypebotChecked: 'HypeBot comments will be read by TTS.',
        ttsSpeakHypebotUnchecked: 'HypeBot comments will not be read.',
        ttsSpeakHypebotButDisabled: ' But HypeBot is disabled.',
        ttsSpeakHypebotDisabled: ' HypeBot is disabled.',
        ttsLocalSettings: 'Local TTS Settings',
        ttsSelectAVoice: 'Select a voice',
        ttsTesting: 'Testing...',
        ttsTestVoice: 'Test Voice',
        ttsVolume: 'Volume',
        ttsSpeed: 'Speed',
        ttsPitch: 'Pitch',
        ttsStreamedSettings: 'Streamed TTS Settings',
        ttsModel: 'Model',
        ttsVoice: 'Voice',
        ttsUnknownVoice: 'unknown voice',
        ttsVoiceSeedDesc: 'Use a voice based on the given seed.',
        ttsVoiceSeedLabel: 'Streamed Text to Speech Seed',
        ttsVoiceSeedPlaceholder: 'Enter a seed here',
        ttsRandomizeSeed: 'Randomize',
        ttsRandomizeSeedLabel: 'Randomize Seed',
        ttsSeedNote:
            'Note: Starting with a common first name will have a relevant influence on pitch and intonation. The voice for any given seed is liable to change in the future as we continue to develop the TTS.',
        ttsStoryVoice: 'Story Voice',
        ttsHypebotVoice: 'HypeBot Voice',
        ttsSavedVoices: 'Saved Voices',
        ttsSelectVoiceLabel: 'Select a voice to edit',
        ttsNoSavedVoices: 'No saved voices. Add one first.',
        ttsUnnamedVoice: 'unnamed voice',
        ttsName: 'Name',
        ttsSeed: 'Seed',
        ttsSaveVoice: 'Save Voice',
        ttsDeleteVoice: 'Delete Voice',
        ttsDeleteVoiceButton: 'Delete it!',
        ttsDeleteAreYouSure: 'Are you sure you want to delete the voice "[0]"?',
        ttsDeleteCannotBeUndone: 'This cannot be reversed.',
        ttsSpeedDownloadNote: 'Note: Will not affect the speed of downloaded audio.',
        defaultStorageLocation: 'Default Storage Location',
        defaultStorageLocationLocal: 'Local',
        defaultStorageLocationRemote: 'Server',
        defaultStorageLocationLocalDesc: 'New & imported stories will be saved locally only.',
        defaultStorageLocationRemoteDesc:
            'New & imported stories will be saved locally and stored encrypted remotely.',
        defaultStorageLocationBackupNote:
            'Exporting and backing up your stories is highly recommended, should your browser cache get cleared, or if you lose access to your account.',
        defaultAIModel: 'Default AI Model',
        defaultAIModelDesc: 'New stories will use this model by default.',
        defaultAIModelSelectLabel: 'Select an AI Model',
        defaultPreset: 'Default Preset',
        defaultPresetDesc: 'New Stories will use the selected preset as a default.',
        defaultAIModule: 'Default AI Module',
        defaultAIModuleDesc: 'New Stories will use the selected Module as a default.',
        defaultAIModuleNotSupported: 'The selected AI Model does not support Modules.',
        lorebookGenerationSettings: 'Lorebook Generation Settings',
        lorebookGenerationSettingsDesc:
            'Change the Model and settings preset used by the Lorebook Generator.',
        lorebookGenerationModel: 'Lorebook Generation Model',
        lorebookGenerationModelSelectLabel: 'Select an AI Model for lorebook generation',
        lorebookGenerationPreset: 'Lore Generation Preset',
        lorebookGenerationLegacy: 'Legacy Lore Generation',
        lorebookGenerationLegacyChecked: {
            0: 'The less consistent fewshot prompt based Lore Generator will be used.',
            1: 'The module of the selected story will be used for generation if available for the selected model. Arbitrary generation types are not supported.',
        },
        lorebookGenerationLegacyUnchecked: 'A bespoke module will be used for Lore Generator.',
        giftKeys: 'Gift Keys',
        purchaseGiftKey: 'Purchase New Gift Key',
        giftKeyKey: 'Key',
        giftKeyDateCreated: 'Date Created',
        giftKeyTier: 'Tier',
        giftKeyStatus: 'Status',
        noGiftKeys: 'No Gift Keys yet!',
        giftKeyCopiedToClipboard: 'Gift Key copied to clipboard.',
        giftKeyRedeemed: 'Redeemed',
        giftKeyAvailable: 'Available',
        giftKeyDisabled: 'Gift key purchases are currently disabled.',
        giftKeyRemoved: 'Gift key purchases have been removed indefinitely due to abuse.',
        giftKeyPurchasingDisabled: 'Gift Key (Purchasing Disabled)',
        confetti: 'Confetti',
        imageGenerationConfettiAnimation: 'Image Generation Confetti Animation',
        imageGenerationConfettiAnimationChecked: 'Confetti will be shown when generating images.',
        imageGenerationConfettiAnimationUnchecked: 'Confetti will not be shown when generating images.',
        storiesConfettiAnimation: 'Stories Page Confetti Animation',
        storiesConfettiAnimationChecked: 'Confetti will be shown on the stories page.',
        storiesConfettiAnimationUnchecked: 'Confetti will not be shown on the stories page.',
        special: 'Special',
        keyboardDisplacesContent: 'Keyboard Displaces Content',
        keyboardDisplacesContentChecked:
            'The onscreen keyboard of some mobile devices will displace the content of the page.',
        keyboardDisplacesContentUnchecked:
            'The onscreen keyboard of some mobile devices will overlay the content of the page.',
    },
    subscription: {
        tierCards: {
            perMonth: '/mo',
            titleMain: 'The AI',
            titleExtra: 'Extra Goodies',
            unlimitedText: 'Unlimited Text Generations',
            tokenMemory: '[0] Tokens of Memory',
            tokenMemorySub: 'That’s about ~[0] characters that the AI can remember.',
            anlasSub: 'Currency for Image Generations, refilled every month.',
            TTS: 'TTS Generations',
            TTSSub: 'Bring your stories to life with the soothing sound of our generated Text-to-Speech voices.',
            imageGen: 'Image Generation',
            imageGenSub: 'Access to our advanced image generation models.',
            imageGenSubUnlimited: 'Unlimited** normal and small sized generations.',
            accessLess: 'Access to Clio, our smaller but fastest proof of concept AI Storyteller.',
            accessBest: 'Access to Kayra, our top of the line AI Storyteller.',
            accessBestSub: 'Including all our other AI Storytellers',
            paperPrice: 'Free',
            freeGenerations: 'Free Text Generations',
            experimentalFeatures: 'Access to new Experimental Features',
            experimentalFeaturesSub:
                'You’ll get access to the latest and coolest new stuff before everyone else.',
            freeTTS: '100 Free TTS Generations',
            finePrintImage:
                '**For images of up to 1024x1024 pixels and up to 28 steps when generating a single image.',
            unlimited: 'Unlimited',
            tokensOfMemory: 'Characters of Memory',
            tokensOfMemorySub: 'The number of characters the AI can remember. (~4 characters = 1 token)',
            nCharacters: '~[0] characters',
            nTokens: '[0] tokens',
            extraFeatures: 'Extra Features',
            advancedAI: 'Advanced AI',
            bestValue: 'Best Value',
            forTheEnthusiast: 'For the Enthusiast',
            textGeneration: 'Text Generation',
            animeDiffusionSub: 'SOTA image model capable of generating Anime imagery',
            furryDiffusionSub: 'SOTA image model capable of generating Furry imagery',
        },
        tierPricing: 'Tier Pricing',
        checkoutDisabled:
            'Checkout has been disable due to ongoing maintenance, please stay tuned for further updates.',
        subLongerActivate: 'Subscriptions already purchased might take longer than expected to activate.',
        alreadyPurchased: {
            0: 'You already purchased a subscription, but it is taking longer than expected to be processed.',
            1: 'Please check again in a few minutes.',
            2: 'In some circumstances, it can take up to 30+ minutes or more to process. Purchasing from Asia might result in additional delay in subscription activation (up to 4 hours). If this situation persists, please contact us at [0].',
        },
        asiaPurchaseDelay:
            'Note: Purchasing from Asia might currently result in additional delay in subscription activation (up to 4 hours).',
        paymentProcessing:
            'Your payment was successful but processing it on our side is taking longer than expected... It can take up to 30+ minutes to process in some circumstances. If it takes longer than expected please contact us at support@novelai.net',
        processingGiftKey: 'Processing your gift key is taking longer than expected...',
        cannotUseSubGiftKeyUnlessGifted:
            'Cannot use a subscription gift key when already subscribed unless the current subscription came from a gift key.',
        cannotSubGiftKey: 'Cannot use a subscription gift key when already subscribed.',
        upgradeProcessing:
            'Upgrading your subscription to [0] was successful but processing it on our side is taking longer than expected...',
        needAsubscription: 'You need a subscription to do this!',
        notSubscribed: 'You are not subscribed!',
        upgradeSubscriptionQ: 'Upgrade your subscription?',
        takeMeThere: 'Take me there',
        noTakeMeBack: 'No, take me back!',
        youAreSubscribedTo: ' You are subscribed to the [0] tier!',
        yourSubscriptionRenews: 'Your subscription renews around [0].',
        yourSubscriptionEnds: 'Your subscription ends on [0] and does not renew.',
        yourSubScriptionHighRisk:
            'Your subscription is currently classified as high risk by our payment provider, this can take up to a day to clear up.',
        unsubscribe: 'Unsubscribe',
        upgradeSubscription: 'Upgrade Subscription Tier',
        updatePaymentMethod: 'Update Payment Method',
        replaceSubscription: 'Replace Subscription',
        activateGiftKey: 'Activate a Gift Key',
        activate: 'Activate',
        giftKeySuccess: 'Success!',
        giftKeySuccessSubscription: 'Ta-da! Your [0] subscription has been activated.',
        giftKeySuccessAnlas: 'Ta-da! your [0] Anlas have been redeemed.',
        giftKeySuccessSubscriptionAndAnlas:
            'Ta-da! Your [0] subscription has been activated and [1] Anlas have been redeemed.',
        upgradeSubscriptionInfo:
            "Upon upgrading your new subscription tier, you will immediately be charged by the new tier's monthly price with a discount based on your remaining time of usage of the past tier for the current month.",
        upgradeSubscriptionConfirm:
            'Are you sure you want to upgrade your subscription tier from [0] to [1]?',
        upgradeSubscriptionCharge: 'You will be immediately charged [0] USD for the remainder of this month.',
        upgradeConfirm: 'Yes I want to upgrade.',
        replaceSubscriptionInfo:
            'Replacing your subscription will charge you the full price of the new subscription and void your old one.',
        giftKeyConfirmation: 'Gift Key Confirmation',
        giftKeySubscriptionAlreadySubbedConfirm: {
            0: 'This gift key contains a subscription ([0], [1]), but you already have a subscription.',
            1: 'Would you like to redeem the key and replace your current subscription?',
        },
        giftKeySubcriptionAlreadySubbedAndAnlasConfirm: {
            0: 'This gift key contains a subscription ([0], [1]) and Anlas ([2]), but you already have a subscription.',
            1: 'Would you like to redeem just the Anlas?',
            2: 'You can either redeem the just the Anlas or replace your current subscription with the new one.',
        },
        redeemJustAnlas: 'I want to redeem the key for just Anlas.',
        replaceMySubscription: 'I want to replace my current subscription with the new one.',
        thankYouForCompletingTheSurvey: 'Thank you for your feedback!',
        unsubscribeLinkMissing: 'Unsubscribe link missing. Please contact support.',
        submitSurvey: 'Submit',
    },
    purchase: {
        mustBeLoggedIn: 'You need to be logged in to purchase Anlas.',
        purchasedXAnlas: 'Purchased [0] Anlas!',
        purchasedNonSubAnlas:
            'Purchased Successful!\nIt may take up to 30+ minutes to for the Anlas to be credited to your account. If it takes longer than expected please contact us at support@novelai.net',
        purchaseDescription1:
            'Here you can purchase additional Anlas for training your AI Modules and for Image Generation.',
        purchaseDescription2:
            'Subscription Anlas will be refilled according to your subscription every month.',
        yourSubscriptionAnlas: 'Your Subscription Anlas:',
        yourPaidAnlas: 'Your Paid Anlas:',
        anlasPerUSD: 'Anlas/USD',
        purchase: 'Purchase',
        doYouReallyWantTo: 'Do you really want to',
        purchaseTheFollowing: 'purchase the following?',
        xAnlasForXUsd: '[0] Anlas for [1]?',
        highRisk:
            "Your subscription transaction is marked as high-risk with our payment provider. You won't be able to purchase Anlas until this status is cleared, which usually happens within 24 hours.",
        confirmPurchase: 'Confirm Purchase',
        cancel: 'Cancel',
    },
    landing: {
        typewriter: {
            castle: 'a castle across the bay',
            steam: 'a city powered by steam',
            city: 'a city reclaimed by nature',
            childhood: 'childhood memories',
            fracture: 'a fractured past',
            mountains: 'a journey through the mountains',
            desert: 'a kingdom in the desert',
            magic: 'a land of magic',
            woods: 'a misty wood',
            moon: 'a moonlit curse',
            war: 'a nation at war',
            age: 'the age of man',
            case: 'a new case',
            country: 'a quiet life in the country',
            river: 'a riverside stroll',
            evening: 'a somber evening',
            twisted: 'a twisted reality',
            coastline: 'a vibrant coastline',
            gardens: 'a walk through the gardens',
            inn: 'a warm inn',
            torn: 'a world tearing at the seams',
            research: 'research best forgotten',
            insurrection: 'an armed insurrection',
            library: 'an enchanted library',
            library2: 'an unusual library',
            dreamscape: 'an ephemeral dreamscape',
            expedition: 'an expedition into the unknown',
            ancient: 'ancient prophecies',
            market: 'bustling markets',
            sea: 'a calm night at sea',
            crystal: 'exploring crystalline caverns',
            lost: 'losing your way',
            egg: 'egg?',
            picnic: 'a picnic by the lake',
            distant: 'far-off mountains',
            hallway: 'foreboding hallways',
            heroes: 'heroes and villains',
            temple: 'hidden temples',
            inspiring: 'inspiring tales',
            forgotten: 'forgotten places',
            love: 'love and lust',
            otherworldly: 'otherworldly beings',
            storm: 'rough waters',
            rwar: 'rumblings of war',
            rural: 'rural society',
            whale: 'hunting sky whales',
            strange: 'strange occurrences',
            dawn: 'the beginning of a new day',
            alchemy: 'the city of Alchemy',
            night: 'the dead of night',
            end: 'the end of an era',
            heaven: 'the gardens of Heaven',
            hell: 'the gardens of Hell',
            alien: 'alien locales',
            gods: 'the Home of the Gods',
            court: 'the Imperial Court',
            menagerie: 'the palace menagerie',
            underworld: 'the path to the underworld',
            institute: 'a mountaintop institute',
            artist: 'the struggles of an artist',
            sun: "the sun's blessings",
            workshop: 'a secluded workshop',
            beyond: 'things beyond comprehension',
            frozen: 'travelling through frozen lands',
            romance: 'unexpected romance',
            depths: 'unseen depths',
            victorian: 'Victorian times',
        },
        title: 'NovelAI - The AI Storyteller',
        blog: 'Blog',
        discord: 'Discord',
        status: 'Status',
        login: 'Login',
        writeAbout: 'Write about',
        description:
            'Driven by AI, painlessly construct unique stories, illustrate thrilling tales, write seductive romances, or just fool around. No censorship or guidelines - anything goes!',
        learnMore: 'LEARN MORE',
        startForFree: 'START WRITING FOR FREE_',
        startForFreeNew: 'START WRITING FOR FREE',
        whatIs: 'What is NovelAI?',
        intro1: 'NovelAI is a monthly subscription service for AI-assisted image generation, storytelling, or simply a LLM powered sandbox for your imagination.',
        intro2: 'Our Artificial Intelligence algorithms create human-like writing based on your own, enabling anyone, regardless of ability, to produce quality literature. We offer unprecedented levels of freedom with our Natural Language Processing playground by using our own AI models, trained on real literature. The AI seamlessly adapts to your input, maintaining your perspective and style.',
        toPricing: 'Take me to Pricing',
        ourFeatures: 'Our Features',
        imgTitle: 'Visualize your favorite characters with Image Generation.',
        imgSub: 'Use powerful image models to depict characters and moments from your stories, with the leading Anime Art AI and other AI models.',
        getStarted: 'Get Started',
        adventure1:
            'With a glazed stare, you watch and ponder what you see in the orb: random images from all around the world. You gaze into the globe and contemplate what these images mean to you. A forest, an ocean, a desert, a mountain range... you spend countless hours staring at the image, pondering its meaning.',
        adventure2: '> You sigh and rest your weary eyes for a moment, "Mayhaps a break is in order."',
        adventure3:
            "The images in this orb are so strange and foreign that you can't help but feel a sense of awe at their beauty. Their colors and shapes speak to your soul and leave you feeling peaceful and refreshed. Your thoughts wander. What wonders await you when you look deeper?",
        whatWouldYouLikeToDo: 'What would you like to do?',
        adventureTitle: 'Begin your (text) adventure here.',
        adventureSub: 'Open-ended not quite your style? Enable the Text Adventure Module.',
        adventureCta: 'Start your quest',
        customizeTitle: 'Customize the editor.',
        customizeSub:
            'Design this space for yourself with our theme editor. Freely edit fonts, sizes, and the color scheme at any time.',
        anywhereTitle: 'Take your writing anywhere.',
        anywhereSub:
            'Whether you’re at home or on the go, NovelAI is always accessible to write something new.',
        secureTitle: 'Secure your writing.                      ',
        secureSub:
            'No one except you will be able to read your stories. Every story stored on our servers is encrypted with [0].',
        steerTitle: 'Steer the AI down a new path.',
        steerSub:
            'Using AI Modules, you can draw upon specific themes, replicate famous writers, or even train one with your own data.',
        steerCta: 'Begin writing',
        useAiModules: 'Use AI Modules to augment your journey',
        useAiModulesSub:
            'Take your writing to a whole new direction using a variety of module-powered tools.',
        modules: 'AI Modules',
        style: 'Style',
        styleSub: 'Emulate your favorite writers.',
        module1Title: 'Text Adventure',
        module1Sub: 'Engage in a thrilling adventure between you and an AI DM.',
        module2Title: 'Instruct',
        module2Sub: 'Send your story in the right direction with personalized instruction.',
        module3Title: 'Prose Augmenter',
        module3Sub: 'Push your writing to its limit with enhanced detail and prose.',
        theme: 'Theme',
        themeSub: 'Drive your writing in a specific thematic direction.',
        module4Title: 'Dark Fantasy',
        module5Title: 'Dragons',
        module6Title: 'Mars Colonization',
        expand: 'Expand your story’s potential.',
        expandSub1: 'Our service keeps up to 8192 ',
        expandSub2: 'tokens',
        expandSub3: ' of memory, in non-nerd speak, the AI remembers a lot.',
        tweak: 'Tweak the AI’s output.',
        tweakSub:
            'Take control of the AI with a plethora of options. Keep things consistent, or maybe go a little more random.',
        legacies: 'Continue your old legacies here.',
        legaciesSub: 'Import from AIDCAT or AIDWI and revive those stories with a place to stay here.',
        lorebookTitle: 'Take control of your world.',
        lorebookSub:
            'The Lorebook is the place to keep track of your world and make sure that the AI obeys its rules.',
        lorebookCta: 'Create your universe',
        testimonialLead: 'Still not convinced?',
        testimonialTitle: 'Don’t just take our word for it.',
        testimonialTitleNew: 'What our users are saying...',
        beginYourAdventure: 'Begin your adventure',
        selectATier: 'Select a Tier to get started.',
        selectATierAndBeginYourAdventure: 'Select a Tier and begin your adventure.',
        getStartedForFree: 'Get Started for Free',
        fineprint: '*Users start with 50 free generations, which refill back to 50 upon creating an account.',
        termsOfService: 'Terms of Service',
        contactUs: 'Contact Us',
        support: 'Support',
        pressKit: 'Press Kit',
        updates: 'Updates',
        about: 'About',
        pricing: 'Pricing',
        bringYourCharactersToLife: 'Bring your characters to life in thrilling stories and adventures.',
        latestBlogPosts: 'Latest Blog Posts',
        testimonials: {
            0: {
                0: '“NovelAI is an amazing tool that just keeps getting better and better. ',
                1: "It is a swiss army knife that has the feel of a writing helper to help fuel the writer's creativity, and a game for users to play with.",
                2: '”',
                3: 'felixchowmusic',
            },
            1: {
                0: '“',
                1: 'I really love the UI and general sense of authorial freedom that comes with Novel AI - ',
                2: 'the ability to fiddle with systems as much or as little as I please, combined with decent presets for quicker "jump in and go" play makes it worth paying for just for that.”',
                3: 'Anonymous',
            },
            2: {
                0: '“Amazing, a real successor to AI Dungeon. ',
                1: 'All the bells and whistles really put you in control of the story, especially the modules.',
                2: '”',
                3: 'Anonymous',
            },
            3: {
                0: "“There's a lot to love—responsive devs, privacy, and ",
                1: 'the ability to write about anything you think of.',
                2: '”',
                3: 'Anonymous',
            },
            4: {
                0: {
                    0: '“Without a good graphics card, I was stuck waiting ages for ',
                    1: 'AI Roguelite',
                    2: 'to generate any pictures at all. ',
                },
                1: "NovelAI's images are spectacular and fast, and having Krake power my dungeon crawling is awesome!",
                2: '”',
                3: 'Panergicagony',
            },
            5: {
                0: "“As a hobby writer I use the AI to help me write my stories. Perhaps when I'm running out of ideas or just want to experiment. ",
                1: "It's an extension to my creative writing and it's absolutely fabulous.",
                2: '”',
                3: 'Otviss',
            },
        },
    },
    inspect: {
        metadata: 'Metadata',
        title: 'Title',
        description: 'Description',
        generationTime: 'Generation Time',
        software: 'Software',
        source: 'Source',
        parameters: 'Parameters',
        simplified: 'Simplified',
        rawParameters: 'Raw Parameters',
        requestType: 'Request Type',
        requestTypes: {
            0: 'Unknown Request Type',
            PromptGenerateRequest: 'Text to Image',
            Img2ImgRequest: 'Image to Image',
            NativeInfillingRequest: 'Inpainting',
        },
        noMetadata: 'This image contains no metadata.',
        instructions: 'Click the upload button or drag an image into the window to check its metadata.',
    },
    chatpage: {
        contact: 'Contact',
        tags: 'tags',
        name: 'Name',
        species: 'Species',
        gender: 'Gender',
        chooseOrEnterCustom: 'Choose or enter custom',
        male: 'male',
        female: 'female',
        na: 'n/a',
        pronouns: 'Pronouns',
        heHim: 'he/him',
        sheHer: 'she/her',
        persona: 'Persona',
        appearance: 'Appearance',
        neutral: 'Neutral',
        angry: 'Angry',
        tired: 'Tired',
        aroused: 'Aroused',
        embarrassed: 'Embarrassed',
        love: 'Love',
        worried: 'Worried',
        determined: 'Determined',
        happy: 'Happy',
        scared: 'Scared',
        excited: 'Excited',
        thinking: 'Thinking',
        shy: 'Shy',
        smug: 'Smug',
        laughing: 'Laughing',
        hurt: 'Hurt',
        sad: 'Sad',
        surprised: 'Surprised',
        nervous: 'Nervous',
        confused: 'Confused',
        disgusted: 'Disgusted',
        bored: 'Bored',
        irritated: 'Irritated',
        playful: 'Playful',
        addChat: 'Add Chat',
        addExample: 'Add Example',
        addDeletionResponse: 'Add Del. Resp.',
        addBrain: 'Add Brain',
        export: 'Export',
        importChats: 'Import Chats',
        chatN: 'Chat [0]',
        exampleN: 'Example [0]',
        brainN: 'Brain [0]',
        deleteN: 'Delete [0]',
        user: 'User',
        nMessages: '[0] Messages',
        deletionResponse: 'Deletion Response',
        exampleChat: 'Example Chat',
        exampleIsGreeting: 'Example is greeting?',
        chatName: 'Chat name (for organization only)',
        chatTags: 'chat tags',
        style: 'Style',
        conversation: 'conversation',
        roleplay: 'roleplay',
        intimacy: 'Intimacy',
        stranger: 'stranger',
        acquaintance: 'acquaintance',
        close: 'close',
        romantic: 'romantic',
        adultsOnly: 'nsfw?',
        adultNo: 'sfw',
        adultYes: 'nsfw',
        tagsOfUser: 'Tags of user',
        nameOfUser: 'Name of user',
        speciesOfUser: 'Species of user',
        genderOfUser: 'Gender of user',
        personaOfUser: 'Persona of user',
        appearanceOfUser: 'Appearance of user',
        pronounsUsedForUser: 'Pronouns used for user',
        scene: 'Scene',
        environment: 'Environment',
        summary: 'Summary',
        deleteChat: 'Delete Chat',
        createNew: 'Create New',
        or: 'or',
        chooseFile: 'Choose File',
        noFileChosen: 'No file chosen',
        importWithUserAndContactReversed: 'Import with user and contact reversed?',
        intro1: 'Quick explanation of how this page works.',
        intro2: "It's structured around groups of chats with the same character. To the left is where you can set the character's information. To the right is where you can set the chat's information you can't open it until you have a chat selected though.",
        intro3: 'Above is where you can switch between chats. Clicking the plus icon will create a new one. This message will disappear once you do. The button next to it exports your current chats as a json file. You can import that file on the previous screen instead of clicking new. Nothing is saved automatically so export regularly. Downloaded files are timestamped, so you can use that to keep track of which is newest.',
        intro4: "Once you have a chat selected you'll see a pair of input fields at the bottom, the one on the right is for the user, the one on the left is for the contact. You can use the tab key to switch between them.",
        intro5: 'Hitting enter in either will send the message. Shift+enter will create a new line.',
        intro6: 'The emoji next to the contact input will change the emotion that gets added to messages you send from that input. The lock icon next to it will make it so that generations use that emotion instead of trying to generate one as part of the message.',
        intro7: "Once you've sent a message you can click on it to edit it. You can also click on the emoji next to the message to change the emotion tied to it.",
        intro8: 'If you click into the pronoun field you can write customized pronouns if needed.',
        intro9: "Oh yeah, you change the user name by clicking on it above the user input. It doesn't look like you can edit it, I know, but you can.",
        chatMetadata: 'Chat Metadata',
        nameTitle: 'Name/Title',
        noName: 'no name',
        deleteChatQuestion: 'Delete Chat?',
        deleteChatAreYouSure: 'Are you sure you want to delete this chat?',
        format1Text: 'Format speech with regular type',
        format1Format: 'like this',
        format2Text: 'Format narration and physical actions',
        format2Format: '*like this*',
        format3Text: 'Format internal thoughts',
        format3Format: '(like this)',
        format4Text: 'Format telepathy',
        format4Format: '<like this>',
        format5Text: 'Format Out Of Context text',
        format5Format: '((OOC: like this))',
        format6Text: 'Format bold text',
        format6Format: '**like this**',
        format7Text: 'Format italicized text',
        format7Format: '_like this_',
        format8Text: 'Format bold italicized text',
        format8Format: '_**like this**_',
        format9Text: 'Format quotations',
        format9Format: '"like this"',
        format10Text: 'Format translations',
        format10Format: 'comme ça [TL: like this]',
        format11Text: 'Format the sharing of files',
        format11Format: '[Format: and content, like this]',
        enterBrainContentHere: 'Enter brain content here',
        actionChar: '*',
        changedMyMind: 'I changed my mind.',
        autosave: 'Autosave',
        autosaveInterval: 'Autosave Interval (Minutes)',
    },
    confetti: {
        confettiPopupEnableButton: 'Opt In: Enable Halloween Bat Animations!',
        confettiPopupOptOutButton: 'Opt Out: I hate parties, let me opt out.',
        confettiPopupNote: 'FOMO? Toggle the festivity theme under Themes, if you want to join the party.',
        confettiPerfWarning: {
            0: 'Please be aware some devices may experience performance issues with animations, but they can be disabled at any time under the ',
            1: 'Theme settings',
            2: '.',
        },
    },
    contest: {
        currentSubmission: 'Current Submission',
        deleteSubmission: 'Delete Submission',
        submit: 'Submit',
        loginRequired: { 0: 'You need to be', 1: 'registered and logged in', 2: ' to participate.' },
        title: 'Title:',
        titleSub: 'Name your masterpiece.',
        textFieldOptional: 'Text Field: (Optional)',
        textField: 'Text Field:',
        textFieldSub: 'Provide a short description or background information for your submission.',
        email: 'Email:',
        emailSub: 'Provide your email address for prize delivery.',
        name: 'Name:',
        nameSub: 'Provide your author name.',
        yourSubmission: 'Your Submission',
        supportedFileTypes: 'Supported Types: .png',
        selectFile: 'Select File',
        backToDetails: 'Back to Details',
        imageContestSubmission: 'Image Contest Submission',
        textContestSubmission: 'Text Contest Submission',
        submissionSuccess: 'Submission Successful!',
        submitToContest: 'Submit to Contest',
        byX: 'by [0]',
        copiedPromptToClipboard: 'Copied prompt to clipboard.',
        copiedUCToClipboard: 'Copied undesired content to clipboard.',
        saveImage: 'Save Image',
        recreate: 'Recreate',
        description: 'Description',
        errorLoadingImages: 'Error loading images.',
        submissionGallery: 'Submission Gallery',
        pleaseVote: 'Please vote for your favorite entries!',
        mustBeLoggedInToVote: 'You need to be registered and logged in to vote.',
        uploadFailedNoMetadata: 'Upload Failed: There is no metadata in the image.',
        uploadFailed: 'Upload Failed: [0]',
        invalidFile: 'Invalid File',
        uploadFailedDuplicate: 'Upload Failed: Duplicate Entry detected.',
        fillMandatoryFields: 'Please fill all mandatory fields.',
        theWinners: 'The Winners',
        firstPlace: 'First Place',
        secondPlace: 'Second Place',
        thirdPlace: 'Third Place',
        fourthPlace: '4th Place',
        fifthPlace: '5th Place',
        sixthPlace: '6th Place',
        seventhPlace: '7th Place',
        eighthPlace: '8th Place',
        ninthPlace: '9th Place',
        tenthPlace: '10th Place',
        nthPlace: '[0]th Place',
        developersChoice: 'Developer’s Choice Prize',
        comedyChoicePrize: 'Comedy Choice Prize',
        submissionTitle: 'Submission Title',
        vote: 'VOTE',
        remove: 'Remove',
        approve: 'Approve',
        unapprove: 'Unapprove',
        submissionCloses: 'Contest Submission period closes: June 24th, 2024 at 10:00 PM PT.',
        days: 'days',
        day: 'day',
        hours: 'hours',
        hour: 'hour',
        minutes: 'minutes',
        minute: 'minute',
        seconds: 'seconds',
        second: 'second',
        countdownSeparator: ', ',
        votingClosed: 'Submissions are now closed. Voting will open shortly.',
        againstRules:
            'Unfortunately, a few submissions were excluded for not adhering to the theme or following the rules.',
        imageContest2024Theme: 'Good Things Come in Threes!',
        textContest2024Theme: 'Three Years of Imagined Worlds: Memories',
        votingCloses: 'Contest Voting closes:',
        votingClosesTime: 'June 28th, 2024 at 11:59 PM PT',
        displayedEntries: 'Displayed Entries:',
        seeResultsNow: 'See the Results now!',
        congratulationsPrizesDistributedShortly:
            'Congratulations to all of our winners! The prizes will be distributed shortly. Thank you from the NovelAI team!',
    },
    links: {
        title: 'Links - NovelAI',
        novelai: 'NovelAI',
        blog: 'Blog',
        tutorialsAndGuides: 'Tutorials & Guides',
        discord: 'Discord',
        twitter: 'Twitter/X',
        reddit: 'Reddit',
        youtube: 'YouTube',
        aetherroom: 'AetherRoom',
        anlatan: 'Anlatan',
    },
    ban: {
        full_banned: {
            0: 'Your account has been banned due to suspicious payment activity.',
            1: 'Please see our ToS (https://novelai.net/terms).',
            2: 'Your account access has been fully restricted.',
        },
        banned: {
            0: 'Your account has been banned due to suspicious payment activity.',
            1: 'Please see our ToS (https://novelai.net/terms).',
            2: 'You may no longer generate Text or Images, nor can you purchase new Subscriptions or Anlas in the future.',
            3: 'Download any of your existing Stories under User Settings > Account > Download All Stories.',
        },
    },
    newsletter: {
        novelaiNewsletter: 'NovelAI Newsletter',
        stayInformed: 'Stay Informed.',
        subscribeToOurNewsletter: 'Subscribe to our newsletter.',
        toSubscribeLater: 'To subscribe later or manage your email preferences, visit [0] Settings > Account',
        enterYourEmail: 'Enter your email',
        youreSignedUp: 'You’re signed up to our newsletter.',
        youllRecieve: 'You’ll receive emails from us when we release new updates and other news.',
    },
    imageTools: {
        tools: {
            'bg-removal': 'Remove BG',
            declutter: 'Declutter',
            'declutter-keep-bubbles': 'Declutter (Keep Bubbles)',
            lineart: 'Line Art',
            sketch: 'Sketch',
            colorize: 'Colorize',
            emotion: 'Emotion',
            upscale: 'Upscale',
        },
        toolTooltip: {
            'bg-removal': 'Remove backgrounds from images, leaving only the characters. ',
            declutter: 'Removes clutter like text, speech bubbles or other things drawn on top of the image.',
            'declutter-keep-bubbles': 'ダミー',
            lineart: 'Draw an outline of your image.',
            sketch: 'Revert your image to a sketch stage.',
            colorize:
                'Turn your sketch or line art picture into something colorful. You can also guide your colorization with a prompt and even make slight modifications to the image.',
            emotion: 'Change the expression of any given character.',
            upscale: 'ダミー',
        },
        prompt: 'Prompt (Optional)',
        defry: 'Defry',
        transform: 'Transform',
        masked: 'Masked',
        generated: 'Generated',
        blend: 'Blend',
        keepBubbles: 'Keep Text Bubbles',
        tooLarge: 'The selected image is too large. The maximum resolution is 1536 * 2048 pixels.',
        tooLargeForUpscale:
            'The selected image is too large to upscale. The maximum resolution is 1024 * 1024 pixels.',
        emotionStrength: 'Emotion Strength',
        emotionStrengths: {
            0: 'Normal',
            1: 'Slightly Weak',
            2: 'Weak',
            3: 'Even Weaker',
            4: 'Very Weak',
            5: 'Weakest',
        },
        emotion: 'Emotion',
        animeOnly: 'Anime Only. Start with a neutral emotion image.',
    },
    giftkey: {
        purchaseError:
            'There was an issue receiving your gift key. Please check your email for a confirmation, and contact our support should the gift key not arrive within the next hour.',
        giftKeyPurchased: {
            0: 'Ta-da! Here’s your Key!',
            1: 'Ta-da! Here’s your Keys!',
            2: 'You can check the status in your Account Settings or your email.',
        },
        copyKey: 'Copy Key',
        keyCopied: 'Gift Key copied to clipboard.',
        purchaseAGiftKey: 'Purchase a New Gift Key',
        purchasesDisabled: {
            0: 'Checkout has been disable due to ongoing maintenance, please stay tuned for further updates.',
            1: 'Gift Keys already purchased might take longer than expected to arrive.',
        },
        asiaPurchaseNotice:
            'Note: Purchasing from Asia might currently result in additional delay in gift key arrival (up to 4 hours).',
        purchaseKey: 'Purchase Key',
    },
    screenshot: {
        screenshotDesigner: 'Screenshot Designer',
        showTitle: 'Show Title',
        showDate: 'Show Date',
        showPenName: 'Show Pen Name',
        showNaiLogo: 'Show NAI Logo',
        showColorHighlighting: 'Show Color Highlighting',
        showColorLegend: 'Show Color Legend',
        showModel: 'Show AI Model',
        showBackground: 'Show Background',
        showComment: 'Show Comment',
        showDialogueHighlight: 'Highlight Speech',
        showConfetti: 'Show Confetti',
        fontSize: 'Font Size',
        theme: 'Theme:',
        widthPx: 'Width (px):',
        writtenAlongside: 'Written alongside',
        toClipboard: 'To Clipboard',
        downloadJpg: 'Download JPG',
        downloadPng: 'Download PNG',
    },
    remoteStorageError: {
        storyTooLarge: 'Story Too Large for Remote Storage',
        storyTooLargeDesc:
            'The story "[0]" failed to save because it\'s too large to be saved remotely. The story will be set to local storage.',
    },
    updateNotes: {
        changeLog: 'Change Log',
        clickAnEntryForMoreInfo: 'Click an entry for more info.',
        reloadThePage: 'Reload the page to get the most recent version',
    },
    updateNotifier: {
        thereAreUpdates: 'There are updates available!',
        description:
            'You’re not currently experiencing the best NovelAI has to offer! Please refresh to make sure you have the latest updates.',
        reloadPage: 'Reload Page',
        seeUpdates: 'See Updates',
        couldntSaveStories: "Couldn't save stories. Please check your stories and reload manually",
        failedToReload: 'Failed to reload page.',
    },
    license: {
        lead: 'Please read and agree with the following:',
        agree: 'I agree with the above terms',
    },
    remoteStorageModal: {
        remoteStorage: 'Remote Storage',
        text1: {
            0: 'Your account is set to store stories ',
            1: 'only on your local device', // This text is bolded
            2: '. Local storage is not meant to be persistent, and a loss of your stories can occur for a number of reasons and at any time.',
        },
        text2: {
            0: 'We recommend storing your stories on our servers.', // This text is bolded
            1: ' They are locally encrypted and inaccessible to anyone but you. This setting can also be changed for each story individually.',
        },
        remoteStorageHint: `Make sure to export and manually back up your stories if you store them locally.\nYou can export all stories in the Account Settings.\nSwitching to remote storage does not move existing stories to our servers automatically, this can be changed in each stories settings individually.`,
        switchToRemoteStorage: 'Switch to Remote Storage ',
        keepLocalStorage: 'Keep Local Storage ',
    },
    tokenizer: {
        intro: {
            0: 'Before your text is sent to the AI, it gets turned into numbers in a process called tokenization. These tokens are how the AI reads and interprets text.',
            1: 'The average token is around 4 characters long, but many common words are their own token.',
            2: ' The tokenizer for your currently selected model is the [1].',
        },
        selectATokenizerType: 'Select a Tokenizer Type',
        textInput: 'Text Input',
        idInput: 'ID Input',
        placeholder: 'Type something here and the tokenized version will be shown below.',
        formattingError: 'Formatting Error',
        text: 'Text',
        tokenIds: 'Token IDs',
        tokensX: 'Tokens: [0]',
        charactersX: 'Characters: [0]',
        tokenOmittedFromOutput: '(token omitted from output)',
    },
    tokenProbabilities: {
        title: 'Token Probabilities',
        before: 'Before',
        after: 'After',
        totalNotShown: 'Total % Not Shown',
        description: {
            0: 'Click on the below tokens to select them, and the probabilities of the tokens that could have been generated will show in the table.',
            1: 'The columns are:',
            2: ' - These are the top 10 alternative tokens that the AI had to consider',
            3: ' - These are the % probabilities before generation settings, biasing, banning, and after modules are applied',
            4: ' - These are the % probabilities <em>after</em> generation settings, biasing, banning, and modules are applied',
            5: 'Tokens are color coded based on their probability: ',
        },
        low: 'Low',
        medium: 'Medium',
        high: 'High',
    },
    stories: {
        llama3LicenseLead:
            'In order to use Llama 3 Erato, you must first read and agree with the Llama 3 license:',
        failedLoadingTheEncoderX: 'Failed loading the encoder: [0]',
        duplicateStories: {
            importedCopyOfDuplicateStoryX: 'Imported copy of duplicate story: [0]',
            deletedDuplicateStoryX: 'Deleted duplicate story: [0]',
            finishedFixingDuplicateStories: 'Finished fixing duplicate stories.',
            duplicateStoriesFound: {
                0: "This story has multiple remote copies. This shouldn't happen, but somehow did.",
                1: 'Clicking the fix button will attempt to separate them into different stories.',
                2: 'Clicking the download button will download copies of [0] versions. This is recommended in case something goes wrong with fixing the stories.',
            },
            both: 'both', // Used in duplicateStoriesFound[2]
            allX: 'all [0]', // Used in duplicateStoriesFound[2]
        },
        noEditorAvailable: 'No editor available.',
        storyStart: {
            letsStartWriting: "Let's start writing!",
            storytellerSub: 'Write alongside the AI to flesh out your writing and ideas.',
            storytellerPreview: {
                0: 'Celestial Curves',
                1: 'You are a wizened wizard of old. Comfortably seated in your most glorious of thrones, your eyes are focused on the curvature of the perfect object before you. This is your favorite past-time and your true purpose - to ponder. With a glazed stare, you watch and ponder what you see in the orb: ',
                2: "scenes of various natural settings from around the world, but also scenes of war and destruction. There are otherworldly scenes such as glowing orbs and strange beings and even representations of things that don't quite seem possible. The orb seems to change the more time you spend staring into it. As you ponder the many wondrous sights, you grow older and wearier each day. But there are always new wonders to be seen and marveled over. As you look deeper into the orb, you see new places in the distance and begin to wonder if perhaps this is truly another universe hidden beyond the stars. The orb itself seems to grow brighter as you ponder, and then dims slightly when you turn away to take a break. Each time you gaze at it, you feel the need to return to your throne and continue contemplating. Eventually the orb begins to glow bright again, and you must return to contemplate once more.",
            },
            textAdventureSub: 'Begin an AI-generated quest using words and actions.',
            textAdventurePreview: {
                0: 'Army of what?!',
                1: 'There are Corgies lined up as far as your eyes can see. Small Corgies, large Corgies, broad Corgies, skinny Corgies there even are Corgies riding upon other Corgies! They are all standing at attention, ready to do your bidding.',
                2: 'You look around for something to fight with.',
                3: "You have only two options: Walk or Fight. Which will you choose? The choice is clear; you need some way to defend yourself from these monstrous creatures. There's a good chance you'll end up fighting them anyway so it might as well be now.",
            },
        },
        scenarios: {
            chooseAScenario: 'Choose a Scenario',
            loadingVotesFailed: 'Loading votes failed.',
            useTheBrowser:
                'Use the browser below to select a scenario that tickles your fancy, you’ll be able to view the contents before starting.',
            cantThinkOfAnyIdeas: "Can't think of any ideas? ",
            pickOneOfOurs: 'Pick one of ours to get started.',
            failedToLoadDefaultScenarios: 'Failed to load default scenarios.',
            viewAllScenarios: 'View All Scenarios',
            searchForScenarios: 'Search for Scenarios',
            searchPlaceholder: 'Search by title, author, tag, or perspective',
            noScenariosFound: 'No scenarios found.',
        },
        storyFailedToLoad:
            'Story failed to load. This is an internal error, please reload the page and try again.',
        storyPlaceholder: 'Enter your prompt here...',
        inputPlaceholder: 'Write your input here',
        adventureModes: {
            DO: 'DO',
            SAY: 'SAY',
            STORY: 'STORY',
        },
        switchToXMode: 'Switch to [0] mode',
        adventurePlaceholders: {
            default: 'What would you like to do?',
            DO: 'What do you want to do?',
            SAY: 'What do you want to say?',
        },
        toolMenu: 'Tool Menu',
        findInStory: 'Find in Story',
        contextMenu: {
            cut: 'Cut',
            ctrlX: 'Ctrl+X',
            copy: 'Copy',
            ctrlC: 'Ctrl+C',
            paste: 'Paste',
            ctrlV: 'Ctrl+V',
            find: 'Find',
            ctrlF: 'Ctrl+F',
            cutSelection: 'Cut Selection',
            copySelection: 'Copy Selection',
            specialCharacter: 'Special Character',
            insertX: 'Insert [0]',
            specialCharacters: {
                litrpg: 'LitRPG',
                enspace: 'En Space',
                emspace: 'Em Space',
                bullet: 'Bullet',
                ellipsis: 'Ellipsis',
                emdash: 'Em Dash',
                asterism: 'Asterism',
            },
            insertSpecialCharacterSubmenu: 'Insert Special Character... Submenu', // aria-label
            specialCharactersDescriptions: {
                litrpg: 'LitRPG game text',
                enspace: 'quotations/excerpts',
                emspace: 'poetry/lyrics',
            },
            addTo: 'Add to...',
            addToSubmenu: 'Add to... Submenu', // aria-label
            addToX: 'Add to [0]',
            newLoreAsText: 'New Lore Entry as Text',
            newLoreAsKey: 'New Lore Entry as Key',
            generateLore: 'Generate Lore...',
            generateLoreSubmenu: 'Generate Lore... Submenu', // aria-label
            tokenize: 'Tokenize',
            tokenizeSelection: 'Tokenize Selection',
            screenshot: 'Screenshot',
            screenshotSelection: 'Screenshot Selection',
            speakTTS: 'Speak with TTS',
            browserContextMenuHint: 'ctrl+right click to open the browser context menu',
            browserContextMenuHintAlt: 'Hint: Ctrl + Right-Click to open the regular browser context menu.',
            editorContextMenu: 'Editor Context Menu',
            generateXLore: 'Generate [0] Lore',
            downloadSpokenWithTTS: 'Download Spoken with TTS',
            speakWithSubmenu: 'Speak with... Submenu', // aria-label
            speakWithX: 'Speak with [0]',
            downloadSpokenWithX: 'Download Spoken with [0]',
        },
        localStorageUnavailable: 'Local storage is currently unavailable and disabled.',
        remoteStorage: 'Remote Storage',
        remoteStorageChecked: 'Story is currently stored encrypted on the server.',
        remoteStorageUnchecked:
            'Story is currently stored locally. Locally stored stories may be deleted by your browser after a period of non-use.',
        exportStory: 'Export Story',
        toFile: 'To File',
        asScenario: 'As Scenario',
        asPlaintext: 'As Plaintext',
        toClipboard: 'To Clipboard',
        asImage: 'As Image',
        storyCopiedToClipboard: 'Story copied to clipboard.',
    },
    storyStats: {
        viewStoryStats: 'View Story Stats',
        storyStatistics: 'Story Statistics',
        storyStatisticsDesc: 'Get a perspective on your writing.',
        data: 'Data',
        dataBlocks: 'Data Blocks',
        xAbandoned: '[0] abandoned',
        user: 'User',
        ai: 'AI',
        edits: 'Edits',
        xChained: '[0] chained',
        lorebookEntries: 'Lorebook Entries',
        ephemeralEntries: 'Ephemeral Entries',
        writing: 'Writing',
        characters: 'Characters',
        xDeleted: '[0] deleted',
        paragraphs: 'Paragraphs',
        words: 'Words',
        sentences: 'Sentences',
        generateAdditionalStats: 'Generate Additional Stats',
        structure: 'Structure',
        currentStep: 'Current Step',
        furthestStep: 'Furthest Step',
        deadEnds: 'Dead Ends',
        noRetryStreak: 'No Retry Streak',
        longestAbandonedBranch: 'Longest Abandoned Branch',
        mostRetries: 'Most Retries',
        averageRetries: 'Average Retries',
        mostUsedWords: 'Most Used Words',
        experimental: 'Experimental',
        experimentalWarning:
            'WARNING: The following options are experimental and could result in permanent corruption of your story. Creating a backup first is heavily advised.',
        trimStory: {
            buttonText: 'Trim Story',
            title: 'Trim your Story?',
            text: {
                0: 'Are you sure you want to trim the history of "[0]"?',
                1: 'This will delete all story branches.',
                2: 'This cannot be reversed.',
            },
            confirm: 'Trim it!',
        },
        flattenStory: {
            buttonText: 'Flatten Story',
            title: 'Flatten your Story?',
            text: {
                0: 'Are you sure you want to flatten "[0]"?',
                1: 'This will delete its entire history.',
                2: 'This cannot be reversed.',
            },
            confirm: 'Flatten it!',
        },
        resetToPrompt: {
            buttonText: 'Reset to Prompt',
            title: 'Reset your Story to Prompt?',
            text: {
                0: 'Are you sure you want to reset "[0]" to its prompt?',
                1: 'This will delete every input/output up to now.',
                2: 'This cannot be reversed.',
            },
            confirm: 'Reset it!',
        },
        sections: 'Sections',
        noPromptFoundCantReset: "No prompt found, can't reset.",
    },
    lorebook: {
        quickAccessPlaceholder: 'Search for an entry',
        openLorebook: 'Open Lorebook',
        confirmDeletion: 'Press again to confirm deletion',
        embedLorebookInPNG: 'Embed Lorebook in PNG',
        entry: 'Entry',
        entries: 'Entries',
        category: 'Category',
        categories: 'Categories',
        xEntry: '[0] entry',
        xEntries: '[0] entries',
        xCategory: '[0] category',
        xCategories: '[0] categories',
        xSelected: '[0] [1] Selected',
        areYouSureYouWantToDeleteXAndY: 'Are you sure you want to delete [0] and [1]?',
        areYouSureYouWantToDeleteX: 'Are you sure you want to delete [0]?',
        cannotBeReversed: 'This cannot be reversed.',
        deleteThem: 'Delete them!',
        deleteIt: 'Delete it!',
        deleteSelected: 'Delete Selected?',
        exportSelected: 'Export Selected',
        exportSelectedToPng: 'Export Selected to PNG',
        newLorebookEntry: 'New Lorebook Entry',
        newLorebookCategory: 'New Category',
        searchPlaceholder: 'Search Lorebook Entries',
        startMultiselectOperation: 'Start Multiselect Operation',
        openSortSettings: 'Open Sort Settings',
        changeDisplayOrder: 'Change display order',
        selectTheSortOrder: 'Select the sort order',
        generationCategories: {
            general: 'General',
            person: 'Person',
            place: 'Place',
            thing: 'Thing',
            life: 'Life',
            faction: 'Faction',
            role: 'Role',
            concept: 'Concept',
            history: 'History',
        },
        introWelcome: {
            0: 'Welcome to',
            1: 'the Lorebook!',
        },
        introDesc: {
            0: 'The perfect place to flesh out your story’s world, events, locations, characters, and environments. There are lots of settings, but you only need to worry about the Entry tab if you’re just getting started.',
            1: 'Simply place the info about your subject in the Entry Text field, and specify what Activation Keys should be looked for to show the entry to the AI.',
        },
        introGetStarted: 'You can get started by clicking the “+ Entry” button.',
        introCreateAnEntry: 'Create an Entry',
        displayName: 'Display Name',
        enableLorebookCategory: 'Enable Lorebook Category',
        defaultPlacementSettings: 'Default Placement Settings',
        defaultPlacementReset: {
            confirm: 'Reset it!',
            text: {
                0: "Are you sure you want to reset the entry's insertions settings to their defaults?",
                1: 'This cannot be reversed.',
            },
            title: 'Reset Settings?',
            buttonText: 'Reset to Defaults',
        },
        defaultEntrySettings: 'Default Entry Settings',
        defaultEntrySettingsChecked:
            'New Lorebook entries created in this category will have the below settings.',
        defaultEntrySettingsUnchecked:
            'New Lorebook entries created in this category will use the default Lorebook entry settings.',
        updateAllEntries: {
            text: {
                0: 'Are you sure you want to update all the settings of every entry within "[0]"?',
                1: 'This cannot be reversed.',
            },
            title: 'Update All Entries?',
            buttonText: 'Update All Entries',
        },
        lorebookCategoryPhraseBias: 'Lorebook Category Phrase Bias',
        subcontextSettings: 'Subcontext Settings',
        createSubcontext: 'Create Subcontext',
        createSubcontextChecked:
            'Lorebook entries in this category will be grouped into a subcontext, which will then be inserted into context using the below settings.',
        createSubcontextUnchecked:
            'Lorebook entries in this category will be inserted into context as normal.',
        tabNames: {
            '': 'Unknown',
            'tab-category-defaults': 'Defaults',
            'tab-category-subcontext': 'Subcontext',
            'tab-category-bias': 'Phrase Bias',
            'tab-entry': 'Entry',
            'tab-context': 'Context',
            'tab-bias': 'Phrase Bias',
        },
        replaceDockedTab: 'Replace docked tab',
        dockActiveTab: 'Dock active tab to side',
        docked: '(docked)',
        undock: 'Undock',
        searchRange: 'Search Range',
        searchRangeTooltip:
            'The number of characters of the story that will be searched for keys. Maximum of 50000.',
        keyRelativeInsertion: 'Key-Relative Insertion',
        keyRelativeInsertionTooltip:
            'Causes the Lorebook entry to be inserted relative to the last of its keys to be found within the context. \nPositive Insertion positions will insert the entry after the key. Negative insertion positions will insert the entry before the key. \nA key is not guaranteed to be found within the context, in these cases the entry will not be included. \nEntries with this setting enabled should typically have a lower Insertion Order than the story context (default 0) and a sufficient amount of reserved tokens. \n Large numbers of relatively inserted lorebook entries can slow down context creation.',
        cascadingActivation: 'Cascading Activation',
        cascadingActivationTooltip:
            'When enabled the Lorebook entry will search for keys in non-story context entries. \nSearch range is not taken into account for non-story context entries. Text from the entry that caused the activation is not guaranteed to end up within the context.',
        alwaysOn: 'Always On',
        alwaysOnTooltip:
            'Causes the Lorebook entry to always activate regardless of whether a key was detected.',
        hiddenEntry: 'Hidden Entry',
        unnamedEntry: 'Unnamed Entry',
        hiddenTag: 'hidden',
        alwaysOnTag: 'always on',
        entryHasNoKeys: 'Entry has no keys.',
        deleteLorebookEntryX: 'Delete Lorebook Entry "[0]"',
        duplicateEntry: 'Duplicate Entry',
        toggleSelectionOfEntryX: 'Toggle selection of entry "[0]"',
        unnamedCategory: 'Unnamed Category',
        addEntryToCategory: 'Add Entry to Category',
        minimizeCategory: 'Minimize Category',
        expandCategory: 'Expand Category',
        deleteCategory: {
            confirm: 'Delete it!',
            text: {
                0: 'Are you sure you want to delete the category "[0]"?',
                1: 'This cannot be reversed.',
            },
            title: 'Delete Category?',
        },
        deleteContainingEntries: 'Delete Containing Entries',
        deleteContainingEntriesChecked: 'Deleting the category will also delete all entries within it.',
        deleteContainingEntriesUnchecked: 'Deleting the category will move all entries out of the category.',
        toggleSelectionOfCategoryX: 'Toggle selection of category "[0]"',
        lorebookEntryPhraseBias: 'Lorebook Entry Phrase Bias',
        hiddenEntryDesc: {
            0: 'This lorebook entry is hidden.',
            1: 'You can reveal it by clicking the button below.',
        },
        revealEntry: 'Reveal Entry',
        placementSettings: 'Placement Settings',
        noCategory: 'No Category',
        activationKeys: 'Activation Keys',
        activationKeysTooltip:
            'A Lorebook entry is activated and its text placed in context whenever one of its keys is found in the recent story. Keys are case-insensitive. \nKeys that begin and end with "/" are evaluated as regex. These regex keys are case-sensitive and support the following flags: i, s, m, and u.',
        activationKeysSub: 'Activates the entry when found within the recent story.',
        activationKeyPlaceholder: 'Type a key here and hit enter to save it',
        noKeysSet: 'No Keys set.',
        keys: 'Keys',
        pressEnterToSaveEdit: 'Press Enter to save edit',
        selectAKeyToEditIt: 'Select a key to edit it',
        selectALorebookCategory: 'Select a Lorebook Category',
        contextOverloaded: 'Context Overloaded: [0] characters were excluded.',
        entryText: 'Entry Text',
        entryTextSub: 'The following text will be referenced when the Keys are activated.',
        generator: 'Generator',
        generatorTooltip:
            'Let the AI fill in the blanks for you! Choose the type of entry from the list, enter what you want to generate, and hit generate. You can also have the AI add to text written in the Lore entry just like in the Story.',
        changeSettings: 'Change Settings',
        generationType: 'Generation Type',
        generationTypeTooltip:
            'Influences the generator towards generating a specific type of entry. A custom type can be set by typing with the dropdown open and hitting enter.',
        selectAGenerationType: 'Select a Generation Type',
        inputText: 'Input Text',
        inputTextTooltip:
            'This is where you put what you want to be generated. Proper nouns like "Geometry Incorporated" or short descriptions like "an enthusiastic merchant" work best.\n\nYou can add tags in parenthesis to further describe the entry, e.g. "Stalagmal (prison, space)"',
        addContext: 'Add Context (advanced)',
        addContextTooltip:
            "Include Memory, Author's Note, the most recent story text (~2500 characters), or other Lorebook entries in context so that information can be used in generating entries.",
        enterLorebookKeysToSearch: 'Enter Lorebook Keys to Search',
        lorebookKeySearchTooltip:
            'The text here will be searched for Lorebook activation keys, and the matching Lore placed in context.',
        generationHistory: 'Generation History',
        generationHistoryDesc: 'Shows the last 50 outputs. Cleared on page refresh.',
        historyEmpty: 'History empty',
        entryTextPlaceholder: 'Type information about the entry here.',
    },
    contextSettings: {
        prefix: 'Prefix',
        prefixTooltip: 'Text prepended to the entry after trimming and before inserting it into context.',
        suffix: 'Suffix',
        suffixTooltip: 'Text appended to the entry after trimming and before inserting it into context.',
        tokenBudget: 'Token Budget',
        tokenBudgetTooltip:
            'The maximum amount of tokens of the context the entry may use. \nDecimal numbers between 0 and 1 (inclusive) will be interpreted as a percentage of the maximum context size (max tokens - output length).',
        reservedTokens: 'Reserved Tokens',
        reservedTokensTooltip:
            'The number of tokens of the context the entry may reserve for itself. All reservations are made before any entries are placed in context. It will not reserve more tokens than the entry actually contains. \nDecimal numbers between 0 and 1 (inclusive) will be interpreted as a percentage of the maximum context size (max tokens - output length).',
        insertionOrder: 'Insertion Order',
        insertionOrderTooltip:
            'Entries are ordered by their insertion order before context is built. Entries with higher insertion order will reserve tokens and be inserted into the context first. \nIf two entries share the same insertion order there is no guarantee which will be inserted first.',
        insertionPosition: 'Insertion Position',
        insertionPositionTooltip:
            'The location the entry will be inserted into the context. 0 is the very top of the context, 1 is one unit down, 2 is two units down etc. \nNegative numbers will count from the bottom of the context starting with -1 at the very bottom, making -2 one unit up, -3 two units up etc.',
        trimDirection: 'Trim Direction',
        trimDirectionTooltip:
            "The direction the entry will be trimmed from when the entire entry will not fit in the context. \nIf set to 'Do Not Trim' the entry will only be included if the entirety of its text will fit within the context.",
        trimDirectionLabels: {
            top: 'Top',
            bottom: 'Bottom',
            doNotTrim: 'Do Not Trim',
        },
        maximumTrimType: 'Maximum Trim Type',
        maximumTrimTypeTooltip:
            'The extent to which the entry is allowed to be trimmed in the order newline > sentence > token.',
        maximumTrimTypeLabels: {
            newline: 'Newline',
            sentence: 'Sentence',
            token: 'Token',
        },
        insertionType: 'Insertion Type',
        insertionTypeTooltip:
            'Determines what units will be used to separate the context when inserting entries.',
        insertionTypeLabels: {
            newline: 'Newline',
            sentence: 'Sentence',
            token: 'Token',
        },
    },
    phraseBias: {
        phraseBias: 'Phrase Bias',
        phraseBiasDesc: 'Weigh the AI’s chance of generating certain words or phrases.',
        biasTooltip:
            'Set a bias on words or phrases to increase or decrease their chance of being generated. Surround with {curly braces} to input exact text. Surround with [square brackets] to input token ids (tokenizer specific)',
        selectAPhraseGroup: 'Select a phrase group',
        groupXBiasX: 'Group: [0], Bias: [1]',
        biasX: 'Bias [0]',
        empty: 'empty',
        noGroupSelected: 'No Group Selected',
        addPhraseGroup: 'Add Phrase Group',
        deletePhraseGroup: 'Delete Phrase Group',
        instruction: 'Type in the area below, then press enter to save.',
        placeholder: 'Enter the text you want to bias',
        addPhrase: 'Add Phrase',
        less: 'LESS',
        more: 'MORE',
        zeroWarning: 'A bias of zero will have no effect.',
        highBiasWarning:
            'A bias greater than [0] will have a very strong effect. Recommended values are between -[0] and [0].',
        lowBiasdWarning:
            'A bias less than -[0] will have a very strong effect. Recommended values are between -[0] and [0].',
        phrases: 'Phrases',
        clickToEdit: 'Click a phrase to edit it.',
        enableTooltip: 'Enable or disable this phrase group.',
        whenEntryInactive: 'When Entry Inactive',
        whenAllEntriesInactive: 'When All Entries Inactive',
        whenEntryInactiveTooltip:
            'When enabled, this group will be applied when the entry is inactive instead of when active.',
        whenAllEntriesInactiveTooltip:
            'When enabled. this group will only be applied when none of the entries in this category are active.',
        ensureCompletion: 'Ensure Completion After Start',
        ensureCompletionTooltip:
            'When enabled, if the first token of a phrase is generated, \
                                    subsequent tokens will always generate.',
        unbiasWhenGenerated: 'Unbias When Generated',
        unbiasWhenGeneratedTooltip:
            'When enabled, after a phrase is generated its bias will be removed for the rest of the generation. \nThis will not prevent it from being generated, only remove the bias placed on it.',
        bias: 'Bias',
    },
    bannedTokens: {
        bannedtokens: 'Banned Tokens',
        bannedTokensDesc: 'Prevents certain token sequences from being generated.',
        bannedTokensTooltip:
            'Ban sequences of tokens from being generated. Input regularly to automatically ban variants. Surround with {curly braces} to input exact text. Surround with [square brackets] to input token ids (tokenizer specific)',
        banBracketGeneration: 'Ban Bracket Generation',
        banBracketGenerationChecked: "Tokens containing brackets won't be generated",
        banBracketGenerationUnchecked: 'Tokens containing brackets can be generated',
        selectABanGroup: 'Select a ban group',
        groupX: 'Group: [0]',
        inputPlaceholder: 'Enter the text you want to ban',
        addBannedSequence: 'Add Banned Sequence',
        sequences: 'Sequences',
        clickToEdit: 'Click a sequence to edit it.',
        enableTooltip: 'Enable or disable this ban group.',
    },
    larry: {
        wantSomeHelp: 'Want some help with that?',
        yes: 'Yes',
        no: 'No',
    },
    infobar: {
        story: {
            title: 'Story',
            saveStoryModuleToAccount: 'Save Story-Module to Account',
            selectAnAiModule: 'Select an AI Module',
            selectAnAiModel: 'Select an AI Model',
            storyMode: 'Story Mode',
            taModuleRecommended: 'Using the [0] module is recommended with the Text Adventure ui.',
            changeDefault: 'Change Default',
            aiModel: 'AI Model',
            aiModule: 'AI Module',
            aiModulesSub: 'Changes the style of the AI generated text.',
            allModules: 'All Modules',
            instructHelp: 'How does Instruct work? Click here to find out!',
            instructHelpContent: {
                0: 'Instruct Module How-To',
                1: "This is an experimental instruct module we're testing so just about everything about it is subject to change. Additional tools and features will be added in the future.",
                2: 'Instructions in their raw form are formatted like this:',
                3: '{ Write a description of the sights and sounds of public transit. }',
                4: 'In editor V2 stories however, to make things a bit easier, you just need to type "{" at the start of a line; this will mark the paragraph as an instruction. No need to close it, all the brackets will be handled for you!',
                5: 'Instructions without the module?',
                6: 'You can still do instructions without the Instruct module selected!',
                7: 'When you use a command, generation will swap to the Instruct module momentarily (for about 1000 characters), letting you use the occasional instruction without having to switch modules.',
            },
            configPreset: 'Config Preset',
            configPresetSub: 'Changes the settings of the AI model.',
            editPreset: 'Edit Preset',
            memorySub: 'The AI will better remember info placed here.',
            authorsNoteSub: 'Info placed here will strongly influence AI output.',
            lorebookQuickAccess: 'Lorebook Quick Access',
            storyOptions: 'Story Options',
            deleteStory: 'Delete Story',
            deleteStoryHint: '(cannot be undone)',
        },
        advanced: {
            title: 'Advanced',
            duplicateAndStart: 'Duplicate and Start as Scenario',
            duplicateAndStartSub: 'Imports current story as a scenario with placeholders',
            minOutputLength: 'Min Output Length',
            minOutputLengthTooltip:
                'The minimum generated output length in tokens, which are 4-5 characters long on average.\nOnly has an effect if at least one Stop Sequence is set.',
            stopSequences: 'Stop Sequences',
            stopSequencesTooltip:
                'Cuts generation short upon reaching a specified token sequence. Note: if under minimum output length ([0]), generation will not be interrupted. Surround with [square brackets] to input token ids (tokenizer specific)',
            stopSequenceInputPlaceholder: 'Type here and hit enter to add a stop sequence',
        },
        generationSettings: {
            title: 'Generation Settings',
            cantDeleteNonUserPresetX: "Can't delete non-user preset [0]",
            presetAreadyImported: 'Preset "[0]" for AI Model "[1]" was already imported" as "[2]"',
            importedPreset: 'Imported Preset "[0]" for AI Model "[1]"',
            importedGenerationSettingsAsPreset: 'Imported Generation Settings as Preset',
            cfgWarning: {
                0: 'This preset uses CFG, which is no longer supported.',
                1: 'Performance may be affected. Another preset is recommended.',
            },
            configPresetSub: 'Choose from a selection of generation settings.',
            presetInputPlaceholder: 'Type name here and press enter',
            createNewPreset: 'Create New Preset',
            settingsSavedToStory: 'Settings saved to story.',
            saveToNewPresetQ: 'Save to new Preset?',
            updateActivePreset: 'Update active Preset?',
            otherOptions: 'Other Options',
            resetChanges: 'Reset Changes',
            saveToNewPreset: 'Save to New Preset',
            generationOptions: 'Generation Options',
            advancedOptions: 'Advanced Options',
            randomness: 'Randomness',
            randomnessTooltip: 'The higher the value, the more random the output!',
            outputLength: 'Output Length',
            outputLengthTooltip: 'Increase the length of the generated responses',
            repetitionPenalty: 'Repetition Penalty',
            repetitionPenaltyTooltip: 'Higher values make the output less repetitive.',
            topK: 'Top-K',
            topKTooltip: 'Keeps this many tokens, and deletes the rest.',
            xOff: '[0] (off)',
            off: '(off)',
            nucleus: 'Nucleus',
            nucleusTooltip:
                'Adds the largest token probabilities until the sum reaches this value, then deletes the leftover tokens. Higher settings preserve more tokens.',
            tailFree: 'Tail-Free',
            tailFreeTooltip:
                'Trims low-probability tokens using a complex calculation. Higher settings preserve more tokens.',
            topA: 'Top-A',
            topATooltip: 'Deletes tokens with probability smaller than (top probability)^2 * A.',
            typical: 'Typical',
            typicalTooltip:
                'Deletes low-probability tokens and occasionally high-probability tokens, leaving the middle. Higher settings preserve more tokens.',
            phraseRepetitionPenalty: 'Phrase Repetition Penalty',
            phraseRepetitionPenaltyTooltip:
                "Phrase Repetition Penalty penalizes token sequences, reducing the chance of generations repeating earlier text. Can be used alongside other repetition penalty settings, but isn't affected by them.",
            selectAPhraseRepetitionPenaltyStrength: 'Select a Phrase Repetition Penalty Strength',
            praseRepetitionPenaltyOptions: {
                off: 'Off',
                very_light: 'Very Light',
                light: 'Light',
                medium: 'Medium',
                aggressive: 'Aggressive',
                very_aggressive: 'Very Aggressive',
            },
            useDefaultWhitelist: 'Use Default Whitelist',
            useDefaultWhitelistChecked: 'A default set of tokens will be ignored by repetition penalty.',
            useDefaultWhitelistUnchecked: 'Repetition penalty will apply to all tokens.',
            range: 'Range',
            rangeTooltip:
                'How many tokens, starting from the last generated one, will be considered repeated if they appear in the next output.',
            dynamicRange: 'Dynamic Range',
            dynamicRangeChecked: 'The repetition penalty range is dynamic, only applying to "Story" text.',
            dynamicRangeUnchecked: 'The repetition penalty range is a fixed number of tokens.',
            slope: 'Slope',
            slopeTooltip:
                "Affects the ramping of the penalty's harshness, starting from the final token. \nHigher values penalize the final tokens more harshly, but are softer on the earlier tokens. Lower values cause a smoother reduction of probability across all tokens.",
            presence: 'Presence',
            presenceTooltip:
                'Applies a static penalty to the generation of tokens that appear within the Repetition Penalty Range.',
            frequency: 'Frequency',
            frequencyTooltip:
                'Applies a penalty to the generation of tokens based on the number of occurrences of that token within the Repetition Penalty Range.',
            cfgScale: 'CFG Scale',
            cfgScaleDescription:
                'Enabling this setting will double the time it takes to generate a response.',
            showOpposingPrompt: 'Show Opposing Prompt',
            hideOpposingPrompt: 'Hide Opposing Prompt',
            opposingPromptHelp: {
                0: 'Reading the [0] before setting an opposing prompt is recommended.',
                1: 'docs page   ', // Link that appears in [0] of the above string
            },
            opposingPrompt: 'Opposing Prompt',
            opposingPromptPlaceholder:
                "Opposing prompt: Add text here that would make the AI generate things you don't want in your outputs.",
            topG: 'Top-G',
            topGTooltip:
                'Functions similarly to Top-K, but acts on groups of tokens with equal probabilities.',
            mirostatTau: 'Mirostat Tau',
            mirostatTauTooltip:
                'Mirostat aims to keep the text at a fixed complexity set by tau. It may not play well with other restrictive sampling settings.',
            mirostatLR: 'Mirostat LR',
            mirostatLRTooltip:
                'Mirostat aims to keep the text at a fixed complexity set by tau. It may not play well with other restrictive sampling settings.',
            linear: 'Linear',
            linearTooltip: 'High values make the output less random.',
            quad: 'Quad',
            quadTooltip: 'High values make the low probabilities much lower.',
            conf: 'Conf',
            confTooltip: 'High values make random outputs less random.',
            minP: 'Min-P',
            minPTooltip: 'Deletes tokens with probability smaller than (top probability) * P.',
            experimentationNote:
                "Experimentation with these settings is encouraged, but be warned that their effects aren't always obvious.",
            experimentationNoteLinkText: 'This guide explains these settings.',
            sampling: 'Sampling',
            changeSamplers: 'Change Samplers',
            xHidden: '[0] hidden',
            alternateRepetitionPenalty: 'Alternate Repetition Penalty',
            alternateRepetitionPenaltyWarning:
                'These options will strongly impact generation and are not recommended to set to high values.',
            disabled: '(disabled)',
            orderNames: {
                temperature: 'Temperature (Randomness)',
                top_k: 'Top-K Sampling',
                top_p: 'Nucleus Sampling',
                tfs: 'Tail Free Sampling',
                top_a: 'Top-A Sampling',
                typical_p: 'Typical Sampling',
                cfg: 'CFG',
                top_g: 'Top-G Sampling',
                mirostat: 'Mirostat',
                math1: 'Unified',
                min_p: 'Min-P',
            },
            samplers: 'Samplers',
            samplersDesc: {
                0: 'You can reorder samplers here.',
                1: 'Disabled samplers will be hidden from the sidebar.',
            },
        },
    },
    contextViewer: {
        context: 'Context',
        contextDesc: 'Get a full view of what’s sent to the AI.',
        lastContext: 'Last Context',
        currentContext: 'Current Context',
        editContextSettings: 'Edit Context Settings',
        editEphemeralContext: 'Edit Ephemeral Context',
        advancedContextSettings: 'Advanced Context Settings',
        applyPresetConfig: 'Apply Preset Config (Legacy)',
        resetToDefaults: 'Reset to Defaults',
        memoryContextSettings: 'Memory Context Settings',
        authorsNoteContextSettings: "Author's Note Context Settings",
        defaultLorebookSettings: 'Default Lorebook Settings',
        defaultLorebookSettingsTooltip: 'Newly created Lorebook entries will use these settings.',
        storyContextSettings: 'Story Context Settings',
        buildingContext: 'Building Context...',
        maxTokens: 'max tokens',
        outputLength: 'output length',
        aiModule: 'AI Module',
        continueToSentenceEndAllowance: 'continue to sentence end allowance',
        structuredOuputDidNotMatchTextOutput: 'Structured output did not match text output.',
        xTokensFilledOutOfAMaximumOfY: '[0] tokens filled out of a maximum of [1]',
        usingPrefixOverride: 'Using prefix override',
        previousStage: 'Previous Stage',
        nextStage: 'Next Stage',
        stageInfo: 'Stage [0]/[1], Reserved: [2], Remaining: [3]',
        justX: 'Just: [0]',
        nextX: 'Next: [0]',
        fromTheTop: 'from the top',
        fromTheBottom: 'from the bottom',
        belowTheLastFoundKey: 'below the last found key',
        aboveTheLastFoundKey: 'above the last found key',
        shuntedXCharacters: '(shunted [0] characters to prevent insertion inside another entry)',
        insertedX: 'Inserted [0] [1] [2] [3] [4]', // identifier, distance, units, direction, shunt
        errorCreatingStructuredOutput:
            'Error creating structured output [0] at location [1]. Allowed range 0-[2]',
        removedLoreActivatingComments: 'Removed lore-activating comments.',
        addedNewlineToEndOfContext: 'Added newline to end of context.',
        croppedFinalToken: 'Cropped final token.',
        removedEndOfText: 'Removed <|endoftext|> from context. Do not use this token on this model.',
        hidden: '(hidden)',
        cHidden: '(Hidden)',
        examine: '(Examine)',
        showNonActivatedEntries: 'Show non-activated entries',
        subcontextX: 'Subcontext: [0]',
        stage: 'Stage',
        order: 'Order',
        identifier: 'Identifier',
        identifierTooltip:
            'The identifier for the context entry. Lorebook entries will use their display name.',
        inclusion: 'Inclusion',
        inclusionTooltip:
            'Whether or not the entry was included in the context or not. A partially included entry is one that was trimmed.',
        reason: 'Reason',
        reasonTooltip: 'The reason the entry was included or excluded from the context.',
        key: 'Key',
        keyTooltip:
            'If the entry was included because it was a Lorebook entry activated by a key the key will be shown here.',
        reserved: 'Reserved',
        reservedTooltip: 'The number of tokens that were reserved by the entry.',
        tokens: 'Tokens',
        tokensTooltip:
            'The number of tokens that were used by the entry.\nThe total of this column may not be the same as the length of the resulting context.',
        trimType: 'Trim Type',
        trimTypeTooltip:
            'How the entry was trimmed to be able to fit it into the context.\nThe priority used when trimming is no trim > newline > sentence > token.',
        includedPhraseBiasGroups: 'Included Phrase Bias Groups',
        numberOfIncludedPhraseBiasGroups: 'Number of Included Phrase Bias Groups',
        identifiers: {
            memory: 'Memory',
            authorsNote: 'A/N',
            story: 'Story',
            unknown: 'Unknown Default Context',
        },
        states: {
            included: 'included',
            'partially included': 'partially included',
            'not included': 'not included',
        },
        reasons: {
            default: 'default',
            'no space': 'no space',
            disabled: 'disabled',
            'no key': 'no key',
            'key activated': 'key activated',
            'key in:': 'key in:',
            'activation forced': 'activation forced',
            'no text': 'no text',
            'no key in context': 'no key in context',
            'ephemeral active': 'ephemeral active',
            'ephemeral inactive': 'ephemeral inactive',
            'no active entries': 'no active entries',
        },
        trimMethods: {
            'no trim': 'no trim',
            token: 'token',
            newline: 'newline',
            sentence: 'sentence',
        },
    },
    ephemeralContext: {
        ephemeralContext: 'Ephemeral Context',
        desc: {
            0: "Ephemeral context entries let you insert context based on the current 'step' of the story. They can be entered in the format {step+delay~duration,position:text} with all but 'text' being numbers. Only the position and text are required.",
            1: "Example: \nThe ephemeral context entry {+3~10,-2:Example} would place the text 'Example' 1 newline from the bottom of the context for 10 steps after a delay of 3 steps.",
        },
        currentStoryStepX: 'Current Story Step: [0]',
        addEphemeralContextEntry: 'Add Ephemeral Context Entry',
    },
    moduleBrowser: {
        searchPlaceholder: 'Search for Modules',
        imported: 'Imported',
        importedSub: 'Modules imported from a file or scenario.',
        specials: 'Specials',
        specialsSub: 'Special modules with extra functionality',
        general: 'General',
        generalSub: 'General modules fitting many situations.',
        style: 'Style',
        styleSub: 'Emulate your favorite writers.',
        theme: 'Theme',
        themeSub: 'Drive your writing in a specific thematic direction.',
        inspiration: 'Inspiration',
        inspirationSub: 'Take inspiration from established works.',
        noModulesFound: 'No modules meet the current search conditions.',
    },
    modules: {
        vanilla: {
            label: 'No Module',
            description: 'The default NovelAI experience.',
        },
        special_openings: {
            label: 'Special: Openings',
            description: '',
        },
        special_instruct: {
            label: 'General: Instruct (Experimental)',
            description: 'Use Instruct commands to give the AI a specific direction.',
        },
        general_crossgenre: {
            label: 'General: Cross-Genre',
            description: 'A general module for many genres.',
        },
        theme_textadventure: {
            label: 'Special: Text Adventure',
            description: "It's up to you whether to change your course of action. Do you proceed?",
        },
        special_proseaugmenter: {
            label: 'General: Prose Augmenter',
            description:
                'A general module for verbose prose. If you find the AI struggling with short sentences \
                and other writing quality pitfalls, Prose Augmenter can help.',
        },
        style_algernonblackwood: {
            label: 'Style: Algernon Blackwood',
            description: 'The unquestioned master of weird atmosphere, unusual fiction and ghost stories.',
        },
        style_arthurconandoyle: {
            label: 'Style: Arthur Conan Doyle',
            description:
                "A master detective seeks the truth in every case, solving crimes with logic, wit, and a keen mind. It's elementary, my dear.",
        },
        style_edgarallanpoe: {
            label: 'Style: Edgar Allan Poe',
            description:
                "The darkness in man's soul, the twisted will of a madman. Influenced by the works of one of literature's greatest authors.",
        },
        style_hplovecraft: {
            label: 'Style: H.P. Lovecraft',
            description:
                'In a forgotten corner of the universe, beings stretch out ethereal tendrils. Who knows what unknown horrors lurk in the shadows...?',
        },
        style_shridanlefanu: {
            label: 'Style: Sheridan Le Fanu',
            description:
                "It's a restless and scrupulous passion, curiosity. What whispers slip between the veil of light and shadow?",
        },
        style_janeausten: {
            label: 'Style: Jane Austen',
            description: 'Experience a Regency world of wit, romance, and social politics.',
        },
        style_julesverne: {
            label: 'Style: Jules Verne',
            description:
                'Journey to distant lands to explore strange cultures. Travel to a world of tribal folk, mermaids, and dinosaurs.',
        },
        style_williamshakespeare: {
            label: 'Style: William Shakespeare',
            description:
                'Beguiling language - in script form, tragedy, all-powerful characters, and a world of romantic fantasy and adventure.',
        },
        theme_19thcenturyromance: {
            label: 'Theme: 19th Century Romance',
            description:
                'Take a trip down memory lane to an era when chivalry was king. Witness love affairs bloom, and scandalous trysts be exposed!',
        },
        theme_actionarcheology: {
            label: 'Theme: Action Archeology',
            description:
                'Rumors of great treasures abound throughout history. Follow clues through mystical ruins, avoiding devious traps along the way.',
        },
        theme_ai: {
            label: 'Theme: Artificial Intelligence',
            description:
                'In this world of silicon, the line between machine and human blurs. Dream of electric sheep, or starships off a distant world.',
        },
        theme_ancientchina: {
            label: 'Theme: Ancient China',
            description:
                "Ancient China's turbulent history comes alive, featuring the rise of an Empire, celestial bureaucracy, and heroes. Claim your own destiny, or serve others' whims!",
        },
        theme_ancientgreek: {
            label: 'Theme: Ancient Greece',
            description:
                'Wander through the streets of Athens, drink wine in tavernas, listen to the songs of the rhapsodists while you befriend the gods—or betray them.',
        },
        theme_india: {
            label: 'Theme: Ancient India',
            description:
                'Find your place in ancient Indian society: Ancient Indian mythology, magic, demons, yadavs, rakshas, and more! A vast universe of myth and legend awaits!',
        },
        theme_animalfiction: {
            label: 'Theme: Animal Fiction',
            description:
                'Drawing from fiction written from the perspective of animals or about animals, be a fierce lion, cunning crow, or tall giraffe. Be whatever animal you desire!',
        },
        theme_anthropomorphicanimals: {
            label: 'Theme: Anthropomorphic Animals',
            description:
                "Begone, hands! It's time for paws, tails, wings, and claws! These anthro animals have fur, and a lot of it.",
        },
        theme_childrens: {
            label: "Theme: Children's Fiction",
            description:
                'Evoke your inner child and engage in an adventure that will take you back to earlier days.',
        },
        theme_christmas: {
            label: 'Theme: Christmas',
            description:
                'A joyful thematic wildcard to instantly transport you into the merriest season of the year!',
        },
        theme_comedicfantasy: {
            label: 'Theme: Comedic Fantasy',
            description:
                'Fantasy of a satirical and humorous bent, with witty narrators, bumbling wizards, and heroic but yet inept warriors!',
        },
        theme_contemporary: {
            label: 'Theme: Contemporary',
            description: 'Relatively relatable: portraying modern everyday life.',
        },
        theme_cyberpunk: {
            label: 'Theme: Cyberpunk',
            description:
                'Jack into the Grid, and conduct cybernetic warfare against other sentients. Enhance your body with various cybernetic enhancements and perform espionage in a dystopia.',
        },
        theme_darkfantasy: {
            label: 'Theme: Dark Fantasy',
            description:
                'The gifted few harness strange powers. When fortresses are besieged, this ancient world will be pushed to the brink.',
        },
        theme_dragons: {
            label: 'Theme: Dragons',
            description:
                'Crimson skies are dominated by wings like hurricanes and teeth like daggers. This realm is ruled over by fire breathers of legend.',
        },
        theme_egypt: {
            label: 'Theme: Egypt',
            description:
                'Mummies stir in the desert, seeking freedom from their curse. Explore ancient pyramids, and discover why the pharaohs were revered beyond their time.',
        },
        theme_feudaljapan: {
            label: 'Theme: Feudal Japan',
            description:
                'Go back to Feudal Japan, where samurai epics abound, along with detective stories, and tales of love conveyed through poetry.',
        },
        theme_gaming: {
            label: 'Theme: Gaming',
            description:
                "Experience the crunch! Take part in a gaming company's office life, or play old-school games with your friends.",
        },
        theme_generalfantasy: {
            label: 'Theme: General Fantasy',
            description:
                'Behold a land where men wield magic swords, and elves sing songs of valor. Experience epic battles, and legendary adventures.',
        },
        theme_goldenagescifi: {
            label: 'Theme: Golden Age Scifi',
            description:
                'No computers or long distance communications, just spaceships, robots, lasers, and (mostly) men surviving on their wits against the galaxy.',
        },
        theme_hardsf: {
            label: 'Theme: Hard SF',
            description: 'Simply put, sci-fi that tries to be realistic. A lot of semi-plausible tech here!',
        },
        theme_history: {
            label: 'Theme: History',
            description: 'A pen is stronger than a sword. Rewrite history however you desire.',
        },
        theme_horror: {
            label: 'Theme: Horror',
            description:
                'Experience nightmares like never before. Indulge in the deepest depths of the human mind, and beyond...',
        },
        theme_huntergatherer: {
            label: 'Theme: Hunter Gatherer',
            description:
                'When man discovered fire, a new age dawned upon the world. Enter the paleolithic, and discover the secrets of survival in our primitive past.',
        },
        theme_litrpg: {
            label: 'Theme: LitRPG',
            description:
                'Stat blocks and status conditions, monsters and magic spells; Level up your roleplaying experience!',
        },
        theme_magicacademy: {
            label: 'Theme: Magic Academy',
            description:
                'Learn spells from wizards old and young. One must choose their path wisely—and never stop learning.',
        },
        theme_libraries: {
            label: 'Theme: Magic Library',
            description:
                'They say a book is a gateway to another world. In this magical collection, the adage may be more literal than expected.',
        },
        theme_lightnovels: {
            label: 'Theme: Light Novels',
            description:
                'Get your dose of otaku medicine, with a dash of isekai, fantasy, slice of life and some romance!',
        },
        theme_mars: {
            label: 'Theme: Mars Colonization',
            description:
                'Brave pioneers set foot on another world. What would it take to tame the red planet?',
        },
        theme_medieval: {
            label: 'Theme: Medieval',
            description:
                'A new age dawns as knights rise and empires fall. Take up sword and shield, or cloak and dagger \
                in this archaic setting.',
        },
        theme_militaryscifi: {
            label: 'Theme: Military SciFi',
            description:
                'Though the weapons changed, war remained the same. From futuristic trenches to intergalactic starfighters, \
                experience life at the front lines.',
        },
        theme_music: {
            label: 'Theme: Music',
            description:
                'The world of touring bands, drugs, and groupies! Can you resist temptation or stay focused on your art?',
        },
        theme_mystery: {
            label: 'Theme: Mystery',
            description:
                "Whodunit? Conduct investigations into mysteries that abound. Poke your nose into places where it doesn't belong.",
        },
        theme_nature: {
            label: 'Theme: Nature',
            description:
                'Survive the elements of rugged terrain, climb sheer mountains, and forge a path through the wilderness.',
        },
        theme_naval: {
            label: 'Theme: Naval Age of Discovery',
            description:
                'Sail the seven seas aboard mighty ships, while exploring exotic ports and uncharted waters.',
        },
        theme_noir: {
            label: 'Theme: Noir',
            description: 'Cynical detectives, hard-boiled dialogue, and black and white morality.',
        },
        theme_philosophy: {
            label: 'Theme: Philosophy',
            description: 'Let philosophical and political figures from old and new guide your stories.',
        },
        theme_pirates: {
            label: 'Theme: Pirates',
            description:
                "There be loot to plunder on the high seas! 'Course, ye best be ready when the galleons come calling...",
        },
        theme_poeticfantasy: {
            label: 'Theme: Poetic Fantasy',
            description:
                'Craft beautiful poetry and stories, set in a curious land of magic and wonder, with delicate prose. Fairy dust not included.',
        },
        theme_postapocalyptic: {
            label: 'Theme: Post-Apocalyptic',
            description:
                'The world has ended, but the story continues. Scavenge for supplies and face fearsome mutated beasts.',
        },
        theme_rats: {
            label: 'Theme: Rats',
            description:
                'Preying and stalking through the night, these little squeakers seek food and shelter wherever they find it. Eeek!',
        },
        theme_romanempire: {
            label: 'Theme: Roman Empire',
            description: 'When in Rome, do as the Romans do.',
        },
        theme_sciencefantasy: {
            label: 'Theme: Science Fantasy',
            description:
                'Each step within these storied lands whispers tales of civilizations long forgotten where great technological works were once regarded as witchcraft.',
        },
        theme_spaceopera: {
            label: 'Theme: Space Opera',
            description:
                'Soar between asteroids, explode planets, find your alien love, or maybe even start an intergalactic war. Infinite universe, infinite possibilities.',
        },
        theme_superheroes: {
            label: 'Theme: Superheroes',
            description:
                'When heroes clash with villains, only one can emerge victorious. Stand for justice, or join forces against it.',
        },
        theme_airships: {
            label: 'Theme: Steampunk',
            description:
                'Soar above the clouds and into a world of adventure, clockwork engines and flying machines.',
        },
        theme_travel: {
            label: 'Theme: Travel',
            description:
                'Travel around the world on years-long journeys to the darkest jungles, highest peaks, deepest seas, driest deserts, and see the myriad Wonders of the World!',
        },
        theme_urbanfantasy: {
            label: 'Theme: Urban Fantasy',
            description:
                'Interact with elves, fairies and other fantasy creatures in a modern urban environment where magic exists!',
        },
        theme_valentines: {
            label: 'Theme: Valentines',
            description:
                'This module is sure to make your heart flutter! Keep the sparks flying with this module trained on a collection of romantic literature!',
        },
        theme_vikings: {
            label: 'Theme: Vikings',
            description: 'Raise your swords and shields, screaming on the way to Valhalla!',
        },
        theme_weirdwest: {
            label: 'Theme: Weird West',
            description:
                'Take to the dusty trail and encounter magical natives, werewolf cowboys, and undead creatures of the wild west.',
        },
        theme_westernromance: {
            label: 'Theme: Western Romance',
            description:
                'Blaze the open trails with a big iron on your hip, and maybe a pretty pardner as well.',
        },
        inspiration_crabsnailandmonkey: {
            label: 'Inspiration: Crab, Snail, and Monkey',
            description:
                'High school gets weird in this unique mixture of slice of life, romance, and supernatural mystery.',
        },
        inspiration_mercantilewolfgirlromance: {
            label: 'Inspiration: Mercantile Wolfgirl Romance',
            description: 'A merchant and a wolf girl goddess travelling the lands.',
        },
        inspiration_nervegear: {
            label: 'Inspiration: Nervegear',
            description: "It's all fun and games, until the users of a VR MMORPG find they can't log out.",
        },
        theme_romanceofthreekingdoms: {
            label: 'Inspiration: Romance of the Three Kingdoms',
            description:
                "China's greatest warlords vie for supremacy. Experience the art of war on a grand scale, in one of the most renowned tales ever told.",
        },
        inspiration_thronewars: {
            label: 'Inspiration: Throne Wars',
            description:
                'As the kingdom faces invasion from within and without, one must make difficult choices; to fight with honor, or or rule by fear?',
        },
        inspiration_witchatlevelcap: {
            label: 'Inspiration: Witch at Level Cap',
            description:
                'Having gone to another world and maxed out her level cap, a witch lives a laid back life.',
        },
        unknown: {
            label: 'Unknown Module',
            description: 'Unknown module.',
        },
    },
    models: {
        erato: {
            name: 'Llama 3 Erato',
            description: 'Our largest and most formidable, built with Meta Llama 3 70B.',
        },
        kayra: {
            name: 'Kayra',
            description: 'A fierce contender, now second best, crafted from the ground up.',
        },
        clio: {
            name: 'Clio',
            description: 'Our veteran powerhouse, still fast and reliable.',
        },
        euterpe: {
            name: 'Euterpe',
            description: 'A legacy model - use is no longer recommended.',
        },
        krake: {
            name: 'Krake',
            description: 'Previously powerful, now legacy model - use is no longer recommended.',
        },
        sigurd: {
            name: 'Sigurd',
            description: 'A legacy model - use is no longer recommended.',
        },
        genji: {
            name: 'Genji',
            description: 'A legacy Japanese language model - use is no longer recommended.',
        },
        snek: {
            name: 'Snek',
            description: 'A legacy python code model - use is no longer recommended.',
        },
        calliope: {
            name: 'Calliope',
            description: 'A legacy model - use is no longer recommended.',
        },
        cassandra: {
            name: 'Cassandra',
            description: "OccultSage's experimental model.",
        },
        hypebot: {
            name: 'HypeBot',
            description: "You shouldn't see this.",
        },
        infill: {
            name: 'Infill',
            description: "You shouldn't see this.",
        },
        blue: {
            name: 'Blue',
            description: '',
        },
        red: {
            name: 'Red',
            description: '',
        },
        green: {
            name: 'Green',
            description: '',
        },
        purple: {
            name: 'Purple',
            description: '',
        },
        pink: {
            name: 'Pink',
            description: '',
        },
        yellow: {
            name: 'Yellow',
            description: '',
        },
        white: {
            name: 'White',
            description: '',
        },
        black: {
            name: 'Black',
            description: '',
        },
        unknown: {
            name: 'Unknown Model',
            description: 'Unknown model.',
        },
    },
}

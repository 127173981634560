export const genjiUnitrim = {
    '94': -1,
    '95': -1,
    '96': -1,
    '97': -1,
    '98': -1,
    '99': -1,
    '100': -1,
    '101': -1,
    '102': -1,
    '103': -1,
    '104': -1,
    '105': -1,
    '106': -1,
    '107': -1,
    '108': -1,
    '109': -1,
    '110': -1,
    '111': -1,
    '112': -1,
    '113': -1,
    '114': -1,
    '115': -1,
    '116': -1,
    '117': -1,
    '118': -1,
    '119': -1,
    '120': -1,
    '121': -1,
    '122': -1,
    '123': -1,
    '124': 1,
    '125': 1,
    '126': 1,
    '127': 1,
    '128': 1,
    '129': 1,
    '130': 1,
    '131': 1,
    '132': 1,
    '133': 1,
    '134': 1,
    '135': 1,
    '136': 1,
    '137': 1,
    '138': 1,
    '139': 1,
    '140': 1,
    '141': 1,
    '142': 1,
    '143': 1,
    '144': 1,
    '145': 1,
    '146': 1,
    '147': 1,
    '148': 1,
    '149': 1,
    '150': 1,
    '151': 1,
    '152': 1,
    '153': 1,
    '154': 1,
    '155': 1,
    '156': 2,
    '157': 2,
    '158': 2,
    '159': 2,
    '160': 2,
    '161': 2,
    '162': 2,
    '163': 2,
    '164': 2,
    '165': 2,
    '166': 2,
    '167': 2,
    '168': 2,
    '169': 2,
    '170': 2,
    '171': 2,
    '172': 3,
    '173': 3,
    '174': 3,
    '175': 3,
    '176': 3,
    '177': 3,
    '178': 3,
    '179': 3,
    '222': -1,
    '223': -1,
    '224': -1,
    '225': -1,
    '226': -1,
    '227': -1,
    '228': -1,
    '229': -1,
    '230': -1,
    '231': -1,
    '232': -1,
    '233': -1,
    '234': -1,
    '235': -1,
    '236': -1,
    '237': -1,
    '238': -1,
    '239': -1,
    '240': -1,
    '241': -1,
    '242': -1,
    '243': -1,
    '244': -1,
    '245': -1,
    '246': -1,
    '247': -1,
    '248': -1,
    '249': -1,
    '250': -1,
    '251': -1,
    '252': -1,
    '253': -1,
    '254': -1,
    '255': -1,
    '447': 1,
    '564': 1,
    '1116': -1,
    '1209': 1,
    '1543': 1,
    '1587': 1,
    '1792': 1,
    '1896': 1,
    '2115': 1,
    '2155': 1,
    '2216': 1,
    '2343': 2,
    '2477': 1,
    '2515': 1,
    '2590': 1,
    '2698': 1,
    '2783': 1,
    '2803': 1,
    '2874': 1,
    '2955': 1,
    '3062': 1,
    '3156': 1,
    '3343': 1,
    '4060': 1,
    '4204': -2,
    '4322': 1,
    '5008': 1,
    '5099': 1,
    '5208': 1,
    '5271': 1,
    '5525': 2,
    '5624': 1,
    '5808': 1,
    '6112': 1,
    '6141': 1,
    '6184': 1,
    '6367': 1,
    '6394': 1,
    '6408': -2,
    '6552': 1,
    '6627': -1,
    '6671': -1,
    '6904': -1,
    '6967': -1,
    '7098': 1,
    '7105': 1,
    '7134': -2,
    '7180': 1,
    '7377': 1,
    '7961': 1,
    '8008': -2,
    '8029': 1,
    '8238': -2,
    '8346': 1,
    '8370': 1,
    '8456': -1,
    '8572': -1,
    '8582': 2,
    '8809': 1,
    '8908': -1,
    '8955': -2,
    '9140': -1,
    '9244': -1,
    '9270': -1,
    '9364': 1,
    '9454': 1,
    '9882': 1,
    '10144': 1,
    '10253': -2,
    '10263': 2,
    '10269': 1,
    '10310': 1,
    '10545': 2,
    '11019': 1,
    '11143': -1,
    '11480': 1,
    '11504': 1,
    '11548': 1,
    '11606': 1,
    '11737': 1,
    '11805': -2,
    '11884': 1,
    '11958': -1,
    '11967': 1,
    '11976': 1,
    '12045': 1,
    '12065': 1,
    '12358': 1,
    '12374': 1,
    '12466': 1,
    '12520': 2,
    '12614': 1,
    '12712': 1,
    '12859': 1,
    '13150': 1,
    '13198': 1,
    '13296': 1,
    '13305': 1,
    '13328': 2,
    '13773': -1,
    '13783': 1,
    '13945': -2,
    '14360': 1,
    '14519': 1,
    '14524': 1,
    '14970': -2,
    '15038': 1,
    '15139': 1,
    '15239': 1,
    '15311': -1,
    '15474': 2,
    '15535': 1,
    '15562': 1,
    '15566': 1,
    '15685': 1,
    '15790': 1,
    '15926': 1,
    '16080': 1,
    '16103': 1,
    '16141': 1,
    '16148': 1,
    '16268': 2,
    '16648': 1,
    '16743': 1,
    '16745': -1,
    '16782': 1,
    '16822': 1,
    '16864': -1,
    '16943': 1,
    '17244': -1,
    '17294': -1,
    '17312': 1,
    '17358': 1,
    '17360': -1,
    '17433': 1,
    '17550': 1,
    '17629': 1,
    '17683': 2,
    '17720': 1,
    '17739': 1,
    '17773': 1,
    '17804': 1,
    '17900': 1,
    '17992': 1,
    '18004': 1,
    '18074': 1,
    '18115': 1,
    '18356': 1,
    '18386': -1,
    '18433': -2,
    '18796': 1,
    '18872': 1,
    '18923': 1,
    '18945': 1,
    '19021': 1,
    '19049': 2,
    '19252': -1,
    '19324': 1,
    '19331': 1,
    '19469': 1,
    '19526': 1,
    '19567': 1,
    '19721': -2,
    '19820': 1,
    '19890': 1,
    '19899': 1,
    '19965': 1,
    '20015': 1,
    '20046': 1,
    '20142': -1,
    '20168': 1,
    '20174': 2,
    '20290': 1,
    '20336': 1,
    '20452': -1,
    '20491': 1,
    '20609': -1,
    '20696': -1,
    '20724': 1,
    '20998': 1,
    '21074': -1,
    '21253': 2,
    '21807': 1,
    '21876': 1,
    '21962': 1,
    '22133': -1,
    '22270': 1,
    '22360': 1,
    '22522': 1,
    '22528': 1,
    '22640': 1,
    '22686': 1,
    '22755': 1,
    '22757': -1,
    '22880': 1,
    '22887': 1,
    '23187': 1,
    '23282': 1,
    '23294': 1,
    '23295': 1,
    '23305': 1,
    '23329': -2,
    '23471': 1,
    '23596': -2,
    '23620': -1,
    '23626': 1,
    '23686': 1,
    '23745': 1,
    '23764': 1,
    '23778': 1,
    '23821': 2,
    '23877': 1,
    '24231': 1,
    '24378': 1,
    '24439': 1,
    '24477': -1,
    '24583': 1,
    '24861': 1,
    '24898': 1,
    '24966': 1,
    '24973': 1,
    '25001': 1,
    '25081': -2,
    '25193': 1,
    '25370': 1,
    '25402': -1,
    '25443': 1,
    '25618': 1,
    '26193': 1,
    '26292': 1,
    '26344': 1,
    '26486': 1,
    '26534': -2,
    '26586': -1,
    '26712': 1,
    '26791': -2,
    '26922': -1,
    '27006': 1,
    '27013': 1,
    '27032': 2,
    '27050': 1,
    '27293': 1,
    '27332': 2,
    '27435': -2,
    '27584': 1,
    '27596': 1,
    '27670': 1,
    '27694': 1,
    '27764': 1,
    '27924': 1,
    '27950': 1,
    '28012': 1,
    '28053': 2,
    '28156': 1,
    '28225': 1,
    '28249': -1,
    '28268': 1,
    '28360': 1,
    '28361': -1,
    '28427': 2,
    '28538': -1,
    '28544': 1,
    '28666': 1,
    '28839': 1,
    '28938': 1,
    '28957': -1,
    '29085': 1,
    '29307': -1,
    '29372': 1,
    '29465': -1,
    '29513': -1,
    '29589': 1,
    '29705': 1,
    '29740': 1,
    '29773': 1,
    '29785': 1,
    '29826': 1,
    '30139': -1,
    '30210': 1,
    '30213': 1,
    '30266': 1,
    '30298': 1,
    '30325': 1,
    '30439': 1,
    '30526': 1,
    '30585': 1,
    '30598': -1,
    '30716': 1,
    '30856': 1,
    '30898': 1,
    '30899': 1,
    '30905': 1,
    '31008': 1,
    '31079': -1,
    '31200': -1,
    '31204': -2,
    '31479': 1,
    '31539': -2,
    '31619': 2,
    '31626': 1,
    '31666': 1,
    '31724': 1,
    '31753': -1,
    '31776': -2,
    '31885': -2,
    '31886': 1,
    '31965': 1,
    '32003': 1,
    '32288': 1,
    '32301': -1,
    '32368': 1,
    '32371': -1,
    '32391': 1,
    '32427': -1,
    '32432': 1,
    '32437': 1,
    '32506': 1,
    '32518': 1,
    '32573': 1,
    '32589': 1,
    '32849': 1,
    '32963': -1,
    '33170': -1,
    '33176': 1,
    '33232': 1,
    '33240': -1,
    '33286': 1,
    '33317': -1,
    '33426': 2,
    '33434': -2,
    '33447': 1,
    '33454': 1,
    '33477': 1,
    '33566': 1,
    '33699': 1,
    '33768': 1,
    '33920': 1,
    '33951': 1,
    '34247': 1,
    '34257': -1,
    '34277': -1,
    '34341': 1,
    '34402': 1,
    '34460': 1,
    '34504': 2,
    '34580': -2,
    '34650': 1,
    '34719': 1,
    '34754': 1,
    '34835': 1,
    '34932': 1,
    '34997': 1,
    '34999': 1,
    '35041': 1,
    '35050': 2,
    '35069': -2,
    '35152': -1,
    '35209': -1,
    '35266': 1,
    '35579': 1,
    '35676': 1,
    '35705': 1,
    '35707': 1,
    '35843': 1,
    '35919': 1,
    '35975': 1,
    '36084': -1,
    '36174': 1,
    '36181': 1,
    '36278': 1,
    '36284': -1,
    '36307': -1,
    '36365': 1,
    '36406': 1,
    '36469': 1,
    '36596': -2,
    '36685': 1,
    '36765': -1,
    '36860': -1,
    '36911': -1,
    '36929': 1,
    '36938': 1,
    '36940': 1,
    '36950': -1,
    '36965': 1,
    '37239': 1,
    '37345': 1,
    '37380': 1,
    '37389': 1,
    '37444': 1,
    '37574': 1,
    '37585': -1,
    '37605': 1,
    '37772': 1,
    '37787': 1,
    '37863': 1,
    '37931': 1,
    '37934': -1,
    '37957': 1,
    '38043': 1,
    '38184': 1,
    '38447': 1,
    '38461': 1,
    '38535': -1,
    '38539': -1,
    '38567': -1,
    '38577': 1,
    '38894': 1,
    '39010': 1,
    '39105': -1,
    '39142': 1,
    '39187': 1,
    '39285': -1,
    '39317': -1,
    '39333': -2,
    '39355': 1,
    '39374': -1,
    '39426': 1,
    '39433': -1,
    '39460': 1,
    '39611': 1,
    '39693': 1,
    '39714': 1,
    '39749': 1,
    '39752': 1,
    '39755': 1,
    '39756': 1,
    '39757': 1,
    '39811': 1,
    '39819': 1,
    '39820': 1,
    '40052': 1,
    '40242': 1,
    '40265': 1,
    '40278': 1,
    '40367': 1,
    '40480': -1,
    '40653': -1,
    '40954': 1,
    '40994': -2,
    '41178': -1,
    '41189': -1,
    '41215': 1,
    '41265': -1,
    '41297': 1,
    '41312': -1,
    '41340': 1,
    '41365': -2,
    '41380': 1,
    '41383': 1,
    '41538': 1,
    '41577': -1,
    '41585': 1,
    '41678': -2,
    '41753': 1,
    '41840': 1,
    '41945': -2,
    '42000': 1,
    '42019': 1,
    '42062': 2,
    '42066': 1,
    '42089': 1,
    '42090': 1,
    '42164': 1,
    '42202': 1,
    '42244': -1,
    '42314': 1,
    '42424': 1,
    '42500': -1,
    '42527': 1,
    '42586': 1,
    '42624': -1,
    '42707': -2,
    '42757': 1,
    '43050': 1,
    '43073': -1,
    '43074': 1,
    '43102': 1,
    '43297': 1,
    '43298': 1,
    '43322': 1,
    '43327': 1,
    '43380': 1,
    '43402': -1,
    '43495': -1,
    '43510': -1,
    '43518': -2,
    '43636': 1,
    '43668': -1,
    '43718': 1,
    '43769': -2,
    '43773': -1,
    '43884': -1,
    '43889': 1,
    '43897': 1,
    '43903': 1,
    '44054': 1,
    '44098': -1,
    '44165': 1,
    '44233': 1,
    '44293': 1,
    '44320': 1,
    '44392': 1,
    '44394': -1,
    '44448': 1,
    '44555': 1,
    '44603': -1,
    '44620': -1,
    '44784': -1,
    '44789': -1,
    '45079': -1,
    '45082': 1,
    '45101': 1,
    '45148': 1,
    '45199': 1,
    '45250': 1,
    '45379': 1,
    '45392': 1,
    '45422': 1,
    '45433': -2,
    '45452': 1,
    '45458': -1,
    '45495': 1,
    '45539': -2,
    '45544': 1,
    '45571': 1,
    '45617': 1,
    '45634': -1,
    '45706': 1,
    '45728': 1,
    '45739': 1,
    '45784': 1,
    '45865': -2,
    '45906': 1,
    '45907': 1,
    '45911': 1,
    '46021': 1,
    '46114': -1,
    '46237': 1,
    '46246': -1,
    '46256': 1,
    '46349': 1,
    '46479': 1,
    '46487': -1,
    '46506': -1,
    '46600': 1,
    '46695': 1,
    '46763': 1,
    '46788': -2,
    '46852': 1,
    '46915': -1,
    '46964': -1,
    '46968': 1,
    '47035': -1,
    '47047': -1,
    '47078': 1,
    '47118': -1,
    '47138': -1,
    '47195': 1,
    '47198': 1,
    '47215': 1,
    '47298': 1,
    '47333': 1,
    '47400': 1,
    '47490': 2,
    '47529': -1,
    '47571': 1,
    '47614': 1,
    '47625': -1,
    '47637': 1,
    '47648': 1,
    '47654': 1,
    '47703': -2,
    '47728': 2,
    '47742': -1,
    '47797': 1,
    '47850': -1,
    '47863': -1,
    '47936': 1,
    '47947': 1,
    '47969': -1,
    '47991': 1,
    '48013': -1,
    '48032': -1,
    '48071': 1,
    '48080': 1,
    '48205': 1,
    '48375': 1,
    '48404': 1,
    '48416': 1,
    '48448': 1,
    '48455': -1,
    '48515': -1,
    '48620': -1,
    '48752': -1,
    '48826': -1,
    '48908': 1,
    '48924': -1,
    '48953': -2,
    '48958': 1,
    '49024': -1,
    '49035': 1,
    '49064': -1,
    '49149': 1,
    '49189': 1,
    '49210': -1,
    '49343': 1,
    '49368': -1,
    '49379': 1,
    '49409': 1,
    '49426': 1,
    '49516': 1,
    '49532': 1,
    '49694': 1,
    '49715': -2,
    '49763': -1,
    '49843': 1,
    '49929': 1,
    '49979': 1,
    '50004': 1,
    '50009': 1,
    '50047': -1,
    '50122': -1,
    '50159': -2,
}

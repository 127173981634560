import { Language } from '../../hooks/useLocalization'
import { Dark } from '../../styles/themes/dark'
import { Theme } from '../../styles/themes/theme'
import { getBrowserLanguage } from '../../util/browser'
import { TTSv2Voices } from '../../util/tts'
import { DefaultLoreGenModel, DefaultModel, TextGenerationModel } from '../request/model'

export enum TTSType {
    Off, // deprecated
    Local,
    Streamed,
}

export enum TTSModel {
    v1 = 'v1',
    v2 = 'v2',
}

export const DEFAULT_TTS_MODEL = TTSModel.v2

export enum MaxContextTokens {
    _1024 = 1024,
    _2048 = 2048,
    _4096 = 4096,
    noLimit = -1,
}

export class UserSettings {
    settingsVersion?: number = 9
    siteTheme?: Theme
    fontScale?: number
    outputFontScale?: number
    lineSpacing?: number
    paragraphSpacing?: number
    paragraphIndent?: number
    lineWidth?: number
    editorHighlighting?: boolean
    editorSpellcheck?: boolean
    showInputBox?: boolean
    force1024Tokens?: boolean
    remoteDefault?: boolean
    trimTrailingSpaces?: boolean
    model?: number | null // Deprecated in favor of defaultModel
    seenWelcomeScreen?: boolean
    buttonScale?: number
    gestureControl?: boolean
    trimResponsesDefault?: boolean
    trimResponsesAdventureDefault?: boolean
    lastUpdateViewed?: number
    contextViewerColors?: boolean
    skipUpdateNotes?: boolean
    streamResponses?: boolean
    streamDelay?: number
    editorLoreKeys?: boolean
    contextMenuSwap?: boolean
    continueGenerationToSentenceEnd?: boolean
    prependPreamble?: boolean
    alwaysOverwriteConflicts?: boolean
    useTTS?: boolean | null // Deprecated in favor of ttsType
    ttsType?: TTSType
    ttsModel?: TTSModel
    ttsSeed?: string
    sid?: number
    ttsVolume?: number
    ttsRate?: number
    ttsRateStreamed?: number
    ttsPitch?: number
    ttsV2Seed?: string
    ttsV2CommentSeed?: string
    speakOutputs?: boolean
    tutorialSeen?: boolean
    showStoryTitle?: boolean
    sortShelvesOnTop?: boolean
    sortFavoritesOnTop?: boolean
    sortLocalOnTop?: boolean
    libraryCompactView?: boolean
    penName?: string
    defaultBias?: boolean
    speakInputs?: boolean
    defaultPreset?: string
    defaultModule?: string
    useDefaultPresetForScenario?: boolean
    useDefaultModuleForScenario?: boolean
    enableLogprobs?: boolean
    forceModelUpdate?: number
    defaultModel?: TextGenerationModel
    loreGenModel?: TextGenerationModel
    loreGenPreset?: string
    legacyLoreGen?: boolean
    april2022?: boolean
    storageDecision?: number
    commentEnabled?: number
    commentChance?: number
    commentAvatar?: number
    commentStreamDelay?: number
    speakComments?: boolean
    bidirectionalInline?: boolean
    showTips?: boolean
    commentAutoClear?: boolean
    savedTtsSeeds?: Array<{
        name: string
        seed: string
        model: TTSModel
        id: string
    }>
    useEditorV2?: boolean
    editorToolbox?: boolean
    editorUnloadSectionsRange?: number
    stableLicenseAgree?: boolean
    hideImageStartupModal?: boolean
    imageUpdateStatus?: number
    imageModelUpdate?: number
    subscriptionPurchaseAttempt?: number
    identiconV1?: boolean
    editorLogprobs?: boolean
    editorHighlightDialogue?: number
    minibar?: boolean
    showDebug?: boolean
    logprobsCount?: number
    uiLanguage?: Language
    maxContextTokens?: MaxContextTokens
    preventModuleOverride?: boolean
    customImageModels?: Array<{
        name: string
        url: string
    }>
    confetti?: boolean
    confettiPopupShown?: boolean
    confettiOnStories?: boolean
    confettiBatsPopupShown?: boolean
    newsletterModalShown?: boolean
    ucDetached?: boolean
    unsubSurveyShown?: boolean
    preventPreamble?: boolean
    llama3LicenseAgree?: boolean
    keyboardDisplacesContent?: boolean
}

export function getUserSetting<S extends keyof UserSettings>(
    settings: UserSettings,
    setting: S
): Exclude<UserSettings[S], undefined> {
    return (
        typeof settings[setting] === 'undefined' ? UserSettingsDefaults[setting] : settings[setting]
    ) as Exclude<UserSettings[S], undefined>
}
export const UserSettingsDefaults: Record<keyof UserSettings, unknown> = {
    settingsVersion: 9,
    siteTheme: Dark,
    fontScale: 16,
    outputFontScale: 18,
    lineSpacing: 1.8,
    paragraphSpacing: 0.5,
    paragraphIndent: 10,
    lineWidth: 100,
    editorHighlighting: true,
    editorSpellcheck: true,
    showInputBox: false,
    force1024Tokens: false,
    remoteDefault: true,
    trimTrailingSpaces: true,
    model: null,
    seenWelcomeScreen: false,
    buttonScale: 1,
    gestureControl: true,
    trimResponsesDefault: true,
    trimResponsesAdventureDefault: true,
    lastUpdateViewed: 0,
    contextViewerColors: true,
    skipUpdateNotes: false,
    streamResponses: true,
    streamDelay: 0,
    editorLoreKeys: false,
    contextMenuSwap: false,
    continueGenerationToSentenceEnd: true,
    prependPreamble: true,
    alwaysOverwriteConflicts: false,
    useTTS: null,
    ttsType: TTSType.Streamed,
    ttsModel: DEFAULT_TTS_MODEL,
    ttsSeed: '',
    sid: 0,
    ttsVolume: 1,
    ttsRate: 1,
    ttsRateStreamed: 1,
    ttsPitch: 1,
    ttsV2Seed: TTSv2Voices[1].seed,
    ttsV2CommentSeed: TTSv2Voices[0].seed,
    speakOutputs: false,
    tutorialSeen: false,
    showStoryTitle: true,
    sortShelvesOnTop: true,
    sortFavoritesOnTop: false,
    sortLocalOnTop: false,
    libraryCompactView: false,
    penName: '',
    defaultBias: true,
    speakInputs: false,
    defaultPreset: '',
    defaultModule: '',
    useDefaultPresetForScenario: false,
    useDefaultModuleForScenario: false,
    enableLogprobs: false,
    forceModelUpdate: 1,
    defaultModel: DefaultModel,
    loreGenModel: DefaultLoreGenModel,
    loreGenPreset: '',
    legacyLoreGen: false,
    april2022: false,
    storageDecision: 0,
    commentEnabled: 1,
    commentChance: 0.05,
    commentAvatar: 0,
    commentStreamDelay: 0,
    speakComments: false,
    bidirectionalInline: false,
    showTips: true,
    commentAutoClear: true,
    savedTtsSeeds: new Array<{
        name: string
        seed: string
        model: TTSModel
        id: string
    }>(),
    useEditorV2: true,
    editorToolbox: true,
    editorUnloadSectionsRange: 1000,
    stableLicenseAgree: false,
    hideImageStartupModal: false,
    imageUpdateStatus: 0,
    imageModelUpdate: 0,
    subscriptionPurchaseAttempt: 0,
    identiconV1: false,
    editorLogprobs: false,
    editorHighlightDialogue: 0,
    minibar: false,
    showDebug: false,
    logprobsCount: 10,
    uiLanguage: getBrowserLanguage(),
    maxContextTokens: MaxContextTokens.noLimit,
    preventModuleOverride: false,
    customImageModels: new Array<{
        name: string
        url: string
    }>(),
    confetti: false,
    confettiPopupShown: true,
    confettiOnStories: false,
    confettiBatsPopupShown: false,
    newsletterModalShown: false,
    ucDetached: true,
    unsubSurveyShown: false,
    preventPreamble: false,
    llama3LicenseAgree: false,
    keyboardDisplacesContent: true,
}
